import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import LoadingButton from "@mui/lab/LoadingButton";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
//import Link from "@mui/material/Link";
//import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { ReactComponent as Logo } from "../logo_raywhite.svg";

import { login } from "../actions/auth";

const theme = createTheme();

const SignIn = (props) => {
	const [loading, setLoading] = useState(false);

	const { isLoggedIn } = useSelector((state) => state.auth);
	const { message } = useSelector((state) => state.message);

	const dispatch = useDispatch();

	const handleLogin = (e) => {
		e.preventDefault();

		setLoading(true);

		const data = new FormData(e.currentTarget);

		dispatch(login(data.get("email"), data.get("password")))
			.then(() => {
				window.location.reload();
				props.history.push("/");
			})
			.catch(() => {
				setLoading(false);
			});
	};

	if (isLoggedIn) {
		return <Redirect to="/" />;
	}

	return (
		<ThemeProvider theme={theme}>
			<Container component="main" maxWidth="xs">
				<CssBaseline />
				<Box
					sx={{
						marginTop: 8,
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					{message && (
						<Alert severity="error">
							<AlertTitle>Error</AlertTitle>
							{message}
						</Alert>
					)}

					<Avatar sx={{ m: 1 }} variant="square">
						<Logo />
					</Avatar>
					<Typography component="h1" variant="h5">
						Sign in
					</Typography>

					<Box
						component="form"
						onSubmit={handleLogin}
						noValidate
						sx={{ mt: 1 }}
					>
						<TextField
							margin="normal"
							required
							fullWidth
							id="email"
							label="Email Address"
							name="email"
							autoComplete="email"
							autoFocus
						/>
						<TextField
							margin="normal"
							required
							fullWidth
							name="password"
							label="Password"
							type="password"
							id="password"
							autoComplete="current-password"
						/>

						<LoadingButton
							type="submit"
							disabled={loading}
							loading={loading}
							loadingPosition="start"
							startIcon={<LockOpenIcon />}
							variant="contained"
							sx={{ mt: 3, mb: 2 }}
							fullWidth
						>
							Sign In
						</LoadingButton>

						{/* <Grid container>
							<Grid item xs>
								<Link href="#" variant="body2">
									Forgot password?
								</Link>
							</Grid>
							<Grid item>
								<Link href="#" variant="body2">
									{"Don't have an account? Sign Up"}
								</Link>
							</Grid>
						</Grid> */}
					</Box>
				</Box>
			</Container>
		</ThemeProvider>
	);
};

export default SignIn;
