import {
	CREATE_USER,
	RETRIEVE_USER,
	DELETE_USER,
	UPDATE_PROFILE,
} from "../actions/types";

const initialState = {};

const userReducer = function (user = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case CREATE_USER:
			return payload;

		case RETRIEVE_USER:
			return payload;

		case DELETE_USER:
			return payload;

		case UPDATE_PROFILE:
			return payload;

		default:
			return user;
	}
};

export default userReducer;
