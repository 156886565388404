import React, { Component } from "react";
import { connect } from "react-redux";
import { setModule, setDateFrom, setDateTo } from "../../actions/common";
import { showLoading, hideLoading } from "../../actions/loading";

import ReportDataService from "../../services/reports.service";
import CardDataService from "../../services/card.service";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
//import TextField from "@mui/material/TextField";

import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { format } from "date-fns";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

// import { parseDate } from "../../helpers/date";
import NumberFormat from "react-number-format";
// import { AllowCompany } from "../../helpers/common";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";

class standardBalanceSheetConsolidateReport extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);
		let monthFrom = format(new Date(), "M");
		//let monthFrom = 1;
		let monthTo = format(new Date(), "M");
		//let monthTo = 3;
		let yearFrom = format(new Date(), "yyyy");
		let yearTo = format(new Date(), "yyyy");

		this.state = {
			searchMonthFrom: monthFrom,
			searchMonthTo: monthTo,
			searchYearFrom: yearFrom,
			searchYearTo: yearTo,
			searchComp: "",
			//searchComp: "8,34",
			searchInclude0Balance: "0",
			searchYTD: "1",
			rows: [],
			parents: [],
			error: false,
			message: false,

			companies_ready: false,
			companies: [],
			xlsUrl:
				"https://fasreport.raywhite.co.id/standardbalancesheetconsolidate",
			months: ReportDataService.getMonths(2),
			years: ReportDataService.getYears(),

			accountNames: [],
			classIds: [],
			classNames: [],
			companyNames: [],
			currents: [],
			monthNames: [],
			totalAM: [],
			totalCC: [],
			totalCCMC: [],
			totalCMC: [],
			classAccount: [],
			totalCM: [],
			totalLEMC: [],
			totalLEM: [],
		};
	}

	componentDidMount() {
		this._isMounted = true;
		if (this._isMounted) {
			this.props.setModule("BS Consolidate");

			this.getRows();
			this.loadSelect();
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
		this.getRows();
	}

	getRows = () => {
		this.props.showLoading();

		var params = {
			id_company: 0,
			companies: this.state.searchComp,
			month_from: this.state.searchMonthFrom,
			month_to: this.state.searchMonthTo,
			year_from: this.state.searchYearFrom,
			year_to: this.state.searchYearTo,
			include0balance: this.state.searchInclude0Balance.toString(),
			ytd: this.state.searchYTD.toString(),
		};

		ReportDataService.getStandardBalanceSheetConsolidate(params)
			.then((response) => {
				this.setState({
					accountNames: response.data.account_names,
					classIds: response.data.class_ids,
					classNames: response.data.class_names,
					companyNames: response.data.company_names,
					currents: response.data.currents,
					monthNames: response.data.month_names,
					rows: response.data.rows,
					totalAM: response.data.total_am,
					totalCC: response.data.total_cc,
					totalCM: response.data.total_cm,
					totalCCMC: response.data.total_ccmc,
					totalCMC: response.data.total_cmc,
					totalLEMC: response.data.total_lemc,
					totalLEM: response.data.total_lem,
					classAccount: response.data.class_account,
					message: false,
				});
				this.props.hideLoading();
			})
			.catch((e) => {
				console.log(e);
				this.setState({
					error: true,
					//message: e.response.data.error,
				});
				this.props.hideLoading();
			});
	};

	loadSelect() {
		CardDataService.getCompanies().then((response) => {
			this.setState({
				companies_ready: true,
				companies: response.data.Row,
			});
		});
	}

	onChangeSearchMonthFrom(e) {
		this.setState({ searchMonthFrom: e.target.value });
	}
	onChangeSearchMonthTo(e) {
		this.setState({ searchMonthTo: e.target.value });
	}
	onChangeSearchYearFrom(e) {
		this.setState({ searchYearFrom: e.target.value });
	}
	onChangeSearchYearTo(e) {
		this.setState({ searchYearTo: e.target.value });
	}
	onChangeSearchInclude0Balance(e) {
		let checked = "0";
		if (e.target.checked) {
			checked = "1";
		}
		this.setState({ searchInclude0Balance: checked });
	}
	onChangeSearchYTD(e) {
		let checked = "0";
		if (e.target.checked) {
			checked = "1";
		}
		this.setState({ searchYTD: checked });
	}

	searchData = (e) => {
		e.preventDefault();
		this.getRows();
	};

	handleClose = (e) => {
		this.setState({
			error: false,
		});
	};

	CheckedCompanies(id) {
		const cs = this.state.searchComp.split(",");
		return cs.includes(id.toString());
	}

	onChangeCompanies(e) {
		let cs = this.state.searchComp.split(",");
		let val = e.target.value.toString();

		if (e.target.checked) {
			if (!cs.includes(val)) {
				cs.push(val);
			}
		} else {
			let i = cs.indexOf(val);
			if (i !== -1) {
				cs.splice(i, 1);
			}
		}

		this.setState({ searchComp: cs.join(",").replace(/^,/, "") });
	}

	rowClass(
		accountNames,
		classIds,
		classNames,
		companyNames,
		currents,
		monthNames,
		rows,
		totalAM,
		totalCC,
		totalCCMC,
		totalCMC,
		classAccount,
		totalCM,
		totalLEMC,
		totalLEM
	) {
		let s = [];
		let i = 0;

		let colspan = (companyNames.length + 1) * monthNames.length + 1;

		for (i = 0; i < classNames.length; i++) {
			s.push(
				<>
					<Table sx={{}} size="small">
						<TableHead>
							<TableRow>{this.rowHeader(monthNames, companyNames)}</TableRow>
							<TableRow>{this.rowHeader2(monthNames, companyNames)}</TableRow>
						</TableHead>
						<TableBody>
							<TableRow>
								<TableCell className="tblBold" colSpan={colspan}>
									{classNames[i].name}
								</TableCell>
							</TableRow>

							{this.rowCell(
								classNames[i].id,
								classNames[i].name,
								monthNames,
								companyNames,
								rows,
								classAccount,
								totalAM,
								totalCCMC,
								totalCC,
								totalCMC,
								totalCM,
								totalLEMC,
								totalLEM
							)}
						</TableBody>
					</Table>

					<Grid container spacing={2}>
						<Grid item xs={12}>
							<hr></hr>
						</Grid>
					</Grid>
				</>
			);
		}

		return s;
	}

	rowCell = (
		classId,
		className,
		monthNames,
		companyNames,
		rows,
		classAccount,
		totalAM,
		totalCCMC,
		totalCC,
		totalCMC,
		totalCM,
		totalLEMC,
		totalLEM
	) => {
		let i = 0;
		let i2 = 0;
		let i3 = 0;
		let s = [];
		let colspan = (companyNames.length + 1) * monthNames.length + 1;

		for (i = 0; i < classAccount.length; i++) {
			if (classAccount[i].id_class === classId) {
				for (i2 = 0; i2 < classAccount[i].current.length; i2++) {
					if (classId !== 3) {
						s.push(
							<TableRow>
								<TableCell className="tblBold" colSpan={colspan}>
									{classAccount[i].current[i2].name} {className}
								</TableCell>
							</TableRow>
						);
					}

					if (classAccount[i].current[i2].account !== null) {
						for (
							i3 = 0;
							i3 < classAccount[i].current[i2].account.length;
							i3++
						) {
							s.push(
								<TableRow>
									<TableCell>
										{classAccount[i].current[i2].account[i3].id} -{" "}
										{classAccount[i].current[i2].account[i3].name}
									</TableCell>

									{this.rowColumb(
										rows,
										classId,
										monthNames,
										companyNames,
										totalAM,
										classAccount[i].current[i2].name,
										classAccount[i].current[i2].account[i3].id
									)}
								</TableRow>
							);
						}
					}

					if (classId === 3 && classAccount[i].current[i2].name === "Current") {
					} else {
						s.push(
							<TableRow>
								<TableCell>
									<b>
										{classId !== 3
											? "Total " +
											  classAccount[i].current[i2].name +
											  " " +
											  className
											: "Total " + className}
									</b>
								</TableCell>
								{this.rowSubTotalCurrent(
									classId,
									classAccount[i].current[i2].name,
									monthNames,
									companyNames,
									totalCCMC,
									totalCC
								)}
							</TableRow>
						);
					}

					s.push(
						<TableRow>
							<TableCell colSpan={colspan}>&nbsp;</TableCell>
						</TableRow>
					);
				}

				s.push(
					<TableRow>
						<TableCell>
							<Typography variant="h6" gutterBottom component="div">
								{classId !== 3
									? "Total " + className
									: "Total Liability And " + className}
							</Typography>
						</TableCell>
						{this.rowSubTotal(
							classId,
							monthNames,
							companyNames,
							totalCMC,
							totalCM,
							totalLEMC,
							totalLEM
						)}
					</TableRow>
				);
			}
		}

		return s;
	};

	rowSubTotal = (
		classId,
		monthNames,
		companyNames,
		totalCMC,
		totalCM,
		totalLEMC,
		totalLEM
	) => {
		let i = 0;
		let i2 = 0;
		let val = 0;

		let s = [];

		for (i = 0; i < monthNames.length; i++) {
			for (i2 = 0; i2 < companyNames.length; i2++) {
				if (classId === 3) {
					val = totalLEMC[monthNames[i]][companyNames[i2]];
				} else {
					val = totalCMC[classId][monthNames[i]][companyNames[i2]];
				}

				s.push(
					<TableCell className="tblBold" align="right">
						<Typography variant="h6" gutterBottom component="div">
							<NumberFormat
								value={val}
								displayType={"text"}
								thousandSeparator="."
								decimalSeparator=","
								decimalScale={2}
								fixedDecimalScale={true}
								prefix={""}
							/>
						</Typography>
					</TableCell>
				);
			}

			if (classId === 3) {
				val = totalLEM[monthNames[i]];
			} else {
				val = totalCM[classId][monthNames[i]];
			}

			s.push(
				<TableCell className="tblBold" align="right">
					<Typography variant="h6" gutterBottom component="div">
						<NumberFormat
							value={val}
							displayType={"text"}
							thousandSeparator="."
							decimalSeparator=","
							decimalScale={2}
							fixedDecimalScale={true}
							prefix={""}
						/>
					</Typography>
				</TableCell>
			);
		}

		return s;
	};

	rowSubTotalCurrent = (
		classId,
		classAccountCurrentName,
		monthNames,
		companyNames,
		totalCCMC,
		totalCC
	) => {
		let i = 0;
		let i2 = 0;

		let s = [];
		let exist = false;

		if (totalCCMC[classId][classAccountCurrentName] !== undefined) {
			exist = true;
		}

		for (i = 0; i < monthNames.length; i++) {
			for (i2 = 0; i2 < companyNames.length; i2++) {
				if (exist) {
					s.push(
						<TableCell className="tblBold" align="right">
							<NumberFormat
								value={
									totalCCMC[classId][classAccountCurrentName][monthNames[i]][
										companyNames[i2]
									]
								}
								displayType={"text"}
								thousandSeparator="."
								decimalSeparator=","
								decimalScale={2}
								fixedDecimalScale={true}
								prefix={""}
							/>
						</TableCell>
					);
				} else {
					s.push(
						<TableCell className="tblBold" align="right">
							<NumberFormat
								value={0}
								displayType={"text"}
								thousandSeparator="."
								decimalSeparator=","
								decimalScale={2}
								fixedDecimalScale={true}
								prefix={""}
							/>
						</TableCell>
					);
				}
			}

			if (exist) {
				s.push(
					<TableCell className="tblBold" align="right">
						<NumberFormat
							value={totalCC[classId][classAccountCurrentName][monthNames[i]]}
							displayType={"text"}
							thousandSeparator="."
							decimalSeparator=","
							decimalScale={2}
							fixedDecimalScale={true}
							prefix={""}
						/>
					</TableCell>
				);
			} else {
				<TableCell className="tblBold" align="right">
					<NumberFormat
						value={0}
						displayType={"text"}
						thousandSeparator="."
						decimalSeparator=","
						decimalScale={2}
						fixedDecimalScale={true}
						prefix={""}
					/>
				</TableCell>;
			}
		}

		return s;
	};

	rowColumb = (
		rows,
		classId,
		monthNames,
		companyNames,
		totalAM,
		classAccountCurrentName,
		classAccountCurrentAccountId
	) => {
		let i = 0;
		let i2 = 0;
		let i3 = 0;
		let i4 = 0;
		let i5 = 0;
		let i6 = 0;
		let i7 = 0;
		let s = [];
		let show = false;

		for (i = 0; i < monthNames.length; i++) {
			for (i2 = 0; i2 < companyNames.length; i2++) {
				show = false;

				for (i3 = 0; i3 < rows.length; i3++) {
					if (rows[i3].id_class === classId) {
						for (i4 = 0; i4 < rows[i3].month.length; i4++) {
							if (rows[i3].month[i4].name === monthNames[i]) {
								for (i5 = 0; i5 < rows[i3].month[i4].company.length; i5++) {
									if (
										rows[i3].month[i4].company[i5].name === companyNames[i2]
									) {
										for (
											i6 = 0;
											i6 < rows[i3].month[i4].company[i5].type.length;
											i6++
										) {
											if (
												rows[i3].month[i4].company[i5].type[i6].value ===
												classAccountCurrentName
											) {
												for (
													i7 = 0;
													i7 <
													rows[i3].month[i4].company[i5].type[i6].row.length;
													i7++
												) {
													if (
														rows[i3].month[i4].company[i5].type[i6].row[i7]
															.id === classAccountCurrentAccountId
													) {
														show = true;
														s.push(
															<TableCell align="right">
																<NumberFormat
																	value={
																		rows[i3].month[i4].company[i5].type[i6].row[
																			i7
																		].data
																	}
																	displayType={"text"}
																	thousandSeparator="."
																	decimalSeparator=","
																	decimalScale={2}
																	fixedDecimalScale={true}
																	prefix={""}
																/>
															</TableCell>
														);
														break;
													}
												}
												break;
											}
										}

										break;
									}
								}
								break;
							}
						}
						break;
					}
				}

				if (!show) {
					s.push(
						<TableCell align="right">
							<NumberFormat
								value={0}
								displayType={"text"}
								thousandSeparator="."
								decimalSeparator=","
								decimalScale={2}
								fixedDecimalScale={true}
								prefix={""}
							/>
						</TableCell>
					);
				}
			}

			s.push(
				<TableCell className="tblBold" align="right">
					<NumberFormat
						value={totalAM[classAccountCurrentAccountId][monthNames[i]]}
						displayType={"text"}
						thousandSeparator="."
						decimalSeparator=","
						decimalScale={2}
						fixedDecimalScale={true}
						prefix={""}
					/>
				</TableCell>
			);
		}

		return s;
	};

	rowHeader = (monthNames, companyNames) => {
		let i = 0;
		let s = [];
		let colspan = companyNames.length;

		s.push(<TableCell align="center">Account</TableCell>);

		for (i = 0; i < monthNames.length; i++) {
			s.push(
				<TableCell colSpan={colspan} align="center">
					{monthNames[i]}
				</TableCell>
			);
			s.push(<TableCell align="center">Total</TableCell>);
		}

		return s;
	};

	rowHeader2 = (monthNames, companyNames) => {
		let i = 0;
		let i2 = 0;
		let s = [];

		s.push(<TableCell align="center">&nbsp;</TableCell>);

		for (i = 0; i < monthNames.length; i++) {
			for (i2 = 0; i2 < companyNames.length; i2++) {
				s.push(<TableCell align="center">{companyNames[i2]}</TableCell>);
			}
			s.push(<TableCell align="center">Total</TableCell>);
		}

		return s;
	};

	render() {
		return (
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
						<Box component="form" onSubmit={(e) => this.searchData(e)}>
							<Typography variant="h5">Search Form</Typography>

							<Grid container spacing={3}>
								<Grid item xs={12} sm={6}>
									<Grid container spacing={1}>
										<Grid item xs={8}>
											<FormControl
												fullWidth={true}
												variant="standard"
												sx={{ minWidth: 100 }}
											>
												<InputLabel id="fromLabel">Month From</InputLabel>
												<Select
													labelId="fromLabel"
													label="From"
													value={this.state.searchMonthFrom}
													onChange={(e) => this.onChangeSearchMonthFrom(e)}
												>
													<MenuItem value="0">
														<em>None</em>
													</MenuItem>
													{this.state.months.map((option, index) => (
														<MenuItem key={index} value={option.id}>
															{option.name}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</Grid>
										<Grid item xs={4}>
											<FormControl
												fullWidth={true}
												variant="standard"
												sx={{ minWidth: 100 }}
											>
												<InputLabel id="yearLabel">Year From</InputLabel>
												<Select
													labelId="yearLabel"
													label="Year"
													value={this.state.searchYearFrom}
													onChange={(e) => this.onChangeSearchYearFrom(e)}
												>
													<MenuItem value="0">
														<em>None</em>
													</MenuItem>
													{this.state.years.map((option, index) => (
														<MenuItem key={index} value={option.id}>
															{option.name}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</Grid>
									</Grid>
								</Grid>

								<Grid item xs={12} sm={6}>
									<Grid container spacing={1}>
										<Grid item xs={8}>
											<FormControl
												fullWidth={true}
												variant="standard"
												sx={{ minWidth: 100 }}
											>
												<InputLabel id="from2Label">Month To</InputLabel>
												<Select
													labelId="from2Label"
													label="To"
													value={this.state.searchMonthTo}
													onChange={(e) => this.onChangeSearchMonthTo(e)}
												>
													<MenuItem value="0">
														<em>None</em>
													</MenuItem>
													{this.state.months.map((option, index) => (
														<MenuItem key={index} value={option.id}>
															{option.name}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</Grid>
										<Grid item xs={4}>
											<FormControl
												fullWidth={true}
												variant="standard"
												sx={{ minWidth: 100 }}
											>
												<InputLabel id="year2Label">Year To</InputLabel>
												<Select
													labelId="year2Label"
													label="Year"
													value={this.state.searchYearTo}
													onChange={(e) => this.onChangeSearchYearTo(e)}
												>
													<MenuItem value="0">
														<em>None</em>
													</MenuItem>
													{this.state.years.map((option, index) => (
														<MenuItem key={index} value={option.id}>
															{option.name}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</Grid>
									</Grid>
								</Grid>

								<Grid item xs={12} sm={2}>
									<FormControlLabel
										control={
											<Checkbox
												checked={
													this.state.searchYTD ===
														"1" ||
													this.state.searchYTD === 1
												}
												onChange={(e) =>
													this.onChangeSearchYTD(e)
												}
											/>
										}
										label="YTD"
									/>
								</Grid>
								<Grid item xs={12} sm={10}>
									<FormControlLabel
										control={
											<Checkbox
												checked={
													this.state.searchInclude0Balance === "1" ||
													this.state.searchInclude0Balance === 1
												}
												onChange={(e) => this.onChangeSearchInclude0Balance(e)}
											/>
										}
										label="Include 0 Balance"
									/>
								</Grid>
								{this.state.companies_ready &&
									this.state.companies.length > 1 && (
										<Grid item xs={12} sm={12}>
											<Grid item xs={12} sm={6}>
												<div className="MuiFormLabel-root MuiFormLabel-colorPrimary css-u4tvz2-MuiFormLabel-root">
													Consolidate :
												</div>
											</Grid>
											<Grid container spacing={0}>
												{this.state.companies.map((option, index) => (
													<Grid item xs={12} sm={6}>
														<FormControlLabel
															control={
																<Checkbox
																	checked={this.CheckedCompanies(option.id)}
																	onChange={(e) => this.onChangeCompanies(e)}
																	style={{ padding: 3 }}
																/>
															}
															value={option.id}
															label={
																<Box component="div" fontSize={14}>
																	{option.name}
																</Box>
															}
														/>
													</Grid>
												))}
											</Grid>
										</Grid>
									)}

								<Grid item xs={6} sm={6}>
									<Button
										type="submit"
										variant="contained"
										startIcon={<SearchIcon />}
									>
										Search
									</Button>
								</Grid>
								<Grid item xs={6} sm={6} container justifyContent="flex-end">
									<Link
										href={
											this.state.xlsUrl +
											"?id_company=0" +
											"&companies=" +
											this.state.searchComp +
											"&month_from=" +
											this.state.searchMonthFrom +
											"&month_to=" +
											this.state.searchMonthTo +
											"&year_from=" +
											this.state.searchYearFrom +
											"&year_to=" +
											this.state.searchYearTo +
											"&include0balance=" +
											this.state.searchInclude0Balance +
											"&token=" +
											this.props.auth.user.token
										}
										underline="always"
									>
										Export
									</Link>
								</Grid>
							</Grid>
						</Box>
					</Paper>
				</Grid>

				<Grid item xs={12}>
					<Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
						<Typography variant="h4" gutterBottom component="div">
							Standard Balance Sheet (Consolidate)
						</Typography>

						<TableContainer component={Paper}>
							{this.rowClass(
								this.state.accountNames,
								this.state.classIds,
								this.state.classNames,
								this.state.companyNames,
								this.state.currents,
								this.state.monthNames,
								this.state.rows,
								this.state.totalAM,
								this.state.totalCC,
								this.state.totalCCMC,
								this.state.totalCMC,
								this.state.classAccount,
								this.state.totalCM,
								this.state.totalLEMC,
								this.state.totalLEM
							)}
						</TableContainer>

						<Dialog
							open={this.state.error}
							onClose={this.handleClose}
							aria-labelledby="error-dialog-title"
							aria-describedby="error-dialog-description"
						>
							<DialogTitle id="error-dialog-title">Failed</DialogTitle>
							<DialogContent>
								<DialogContentText id="error-dialog-description">
									{this.state.message}
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={this.handleClose} autoFocus>
									Close
								</Button>
							</DialogActions>
						</Dialog>
					</Paper>
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
		common: state.common,
		loading: state.loading,
	};
};

export default connect(mapStateToProps, {
	setModule,
	setDateFrom,
	setDateTo,
	showLoading,
	hideLoading,
})(standardBalanceSheetConsolidateReport);
