import {
	CREATE_PAYBILL,
	RETRIEVE_PAYBILL,
	DELETE_PAYBILL,
} from "../actions/types";

const initialState = {};

const payBillReducer = function (payBill = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case CREATE_PAYBILL:
			return payload;

		case RETRIEVE_PAYBILL:
			return payload;

		case DELETE_PAYBILL:
			return payload;

		default:
			return payBill;
	}
};

export default payBillReducer;