import { CREATE_CARD, RETRIEVE_CARD, DELETE_CARD } from "./types";

import CardDataService from "../services/card.service";
import { parseNumber } from "../helpers/common";

export const createCard =
	(
		id,
		id_company,
		id_type,
		designation,
		name,
		code,
		address,
		note,
		phone,
		mobile,
		whatsapp,
		fax,
		email,
		tax_number,
		id_tax,
		active
	) =>
	async (dispatch) => {
		try {
			if (active === "true") {
				active = true;
			}
			if (active === "false") {
				active = false;
			}

			id_type = parseNumber(id_type);

			let formData = {
				id: id,
				id_company: id_company,
				id_type: id_type,
				designation: designation,
				name: name,
				code: code,
				address: address,
				note: note,
				phone: phone,
				mobile: mobile,
				whatsapp: whatsapp,
				fax: fax,
				email: email,
				tax_number: tax_number,
				id_tax: id_tax,
				active: active,
			};

			const res = await CardDataService.create(formData);

			dispatch({
				type: CREATE_CARD,
				payload: res.data,
			});

			return Promise.resolve(res.data);
		} catch (err) {
			return Promise.reject(err);
		}
	};

export const retrieveCard = (params) => async (dispatch) => {
	try {
		const res = await CardDataService.getAll(params);

		dispatch({
			type: RETRIEVE_CARD,
			payload: res.data,
		});
	} catch (err) {
		return Promise.reject(err);
	}
};

export const deleteCard = (id) => async (dispatch) => {
	try {
		const res = await CardDataService.delete(id);

		dispatch({
			type: DELETE_CARD,
			payload: res.data,
		});
	} catch (err) {
		return Promise.reject(err);
	}
};
