import { CREATE_JOB, RETRIEVE_JOB, DELETE_JOB } from "./types";

import JobDataService from "../services/job.service";

export const createJob =
	(
		id,
		id_company,
		code,
		name,
		note,
		contact,
		percent_complete,
		start_date,
		finish_date,
		manager,
		active
	) =>
	async (dispatch) => {
		try {
			if (active === "true") {
				active = true;
			}
			if (active === "false") {
				active = false;
			}

			let formData = {
				id: id,
				id_company: id_company,
				name: name,
				code: code,
				note: note,
				contact: contact,
				percent_complete: percent_complete,
				start_date: start_date,
				finish_date: finish_date,
				manager: manager,
				active: active,
			};

			const res = await JobDataService.create(formData);

			dispatch({
				type: CREATE_JOB,
				payload: res.data,
			});

			return Promise.resolve(res.data);
		} catch (err) {
			return Promise.reject(err);
		}
	};

export const retrieveJob = (params) => async (dispatch) => {
	try {
		const res = await JobDataService.getAll(params);

		dispatch({
			type: RETRIEVE_JOB,
			payload: res.data,
		});
	} catch (err) {
		return Promise.reject(err);
	}
};

export const deleteJob = (id) => async (dispatch) => {
	try {
		const res = await JobDataService.delete(id);

		dispatch({
			type: DELETE_JOB,
			payload: res.data,
		});
	} catch (err) {
		return Promise.reject(err);
	}
};
