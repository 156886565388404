import { CREATE_JOB, RETRIEVE_JOB, DELETE_JOB } from "../actions/types";

const initialState = {};

const jobReducer = function (job = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case CREATE_JOB:
			return payload;

		case RETRIEVE_JOB:
			return payload;

		case DELETE_JOB:
			return payload;

		default:
			return job;
	}
};

export default jobReducer;
