import React, { Component } from "react";
import { connect } from "react-redux";
import { retrievePosts, deletePost } from "../../actions/posts";
import { setCurrentPage, setCurrentPageSize } from "../../actions/common";
import { Link } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

class PostsList extends Component {
	constructor(props) {
		super(props);
		this.onChangeSearchTitle = this.onChangeSearchTitle.bind(this);
		this.onChangeSearchContent = this.onChangeSearchContent.bind(this);
		this.onChangeSearchActive = this.onChangeSearchActive.bind(this);
		this.onChangeOrderBy = this.onChangeOrderBy.bind(this);
		this.onChangeOrder = this.onChangeOrder.bind(this);
		this.searchData = this.searchData.bind(this);
		this.getRows = this.getRows.bind(this);

		this.state = {
			current: null,
			currentIndex: -1,
			searchTitle: "",
			searchContent: "",
			searchActive: "",
			orderBy: "publish_time",
			order: "desc",
			count: 0,
			alert: false,
			delTitle: "",
			loading: false,
			message: false,
		};
	}

	pageSizes = [5, 10, 20, 100];
	actives = [
		{
			label: "-",
			value: "",
		},
		{
			label: "Active",
			value: 1,
		},
		{
			label: "Inactive",
			value: 0,
		},
	];

	sorts = [
		{
			label: "Title",
			value: "title",
		},
		{
			label: "Publish Time",
			value: "publish_time",
		},
	];

	orders = [
		{
			label: "Asc",
			value: "asc",
		},
		{
			label: "Desc",
			value: "desc",
		},
	];

	componentDidMount() {
		this.getRows();
	}

	getRows() {
		var params = {
			title: this.state.searchTitle,
			content: this.state.searchContent,
			active: this.state.searchActive,
			s: this.state.orderBy,
			d: this.state.order,
			page: this.props.common.currentPage,
			size: this.props.common.currentPageSize,
		};

		this.props
			.retrievePosts(params)
			.then(() => {
				const totalPages = this.props.posts.totalPages;
				this.setState({
					count: totalPages,
					current: null,
					currentIndex: -1,
				});
			})
			.catch((e) => {
				if (e.response.status === 403) localStorage.removeItem("user");
			});
	}

	handlePageSizeChange = (event) => {
		this.props.setCurrentPageSize(event.target.value).then(() => {
			this.props.setCurrentPage(1).then(() => {
				this.getRows();
			});
		});
	};

	handlePageChange = (event, value) => {
		this.props.setCurrentPage(value).then(() => {
			this.getRows();
		});
	};

	onChangeSearchTitle(e) {
		this.setState({ searchTitle: e.target.value });
	}
	onChangeSearchContent(e) {
		this.setState({ searchContent: e.target.value });
	}
	onChangeSearchActive(e) {
		this.setState({ searchActive: e.target.value });
	}
	onChangeOrderBy(e) {
		this.setState({ orderBy: e.target.value });
	}
	onChangeOrder(e) {
		this.setState({ order: e.target.value });
	}

	searchData = (e) => {
		e.preventDefault();
		this.props.setCurrentPage(1).then(() => {
			this.getRows();
		});
	};

	confirmDelete = (id, title) => {
		this.setState({
			alert: true,
			delID: id,
			delTitle: title,
		});
	};

	handleClose = (e) => {
		this.setState({
			alert: false,
		});
	};

	deleteRow = (e) => {
		this.setState({ loading: true, message: false }, function () {
			this.props
				.deletePost(this.state.delID)
				.then((response) => {
					this.setState(
						{
							alert: false,
							loading: false,
						},
						function () {
							this.getRows();
						}
					);
				})
				.catch((e) => {
					console.log(e.response.data);
					this.setState(
						{
							alert: false,
							loading: false,
							message: e.response.data,
						},
						function () {
							alert(e.response.data);
						}
					);
				});
		});
	};

	render() {
		const {
			currentIndex,
			searchTitle,
			searchContent,
			searchActive,
			orderBy,
			order,
			count,
			alert,
			delTitle,
			message,
		} = this.state;
		const { rows } = this.props.posts;

		return (
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
						<Box component="form" onSubmit={this.searchData}>
							<Typography variant="h5">Search Form</Typography>

							<Grid container spacing={3}>
								<Grid item xs={12} sm={6}>
									<TextField
										label="Search by title"
										value={searchTitle}
										onChange={this.onChangeSearchTitle}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={6}>
									<TextField
										label="Search by content"
										value={searchContent}
										onChange={this.onChangeSearchContent}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={6}>
									<FormControl
										fullWidth={true}
										variant="standard"
										sx={{ minWidth: 120 }}
									>
										<InputLabel id="activelabel">Status</InputLabel>
										<Select
											labelId="activelabel"
											label="active"
											value={searchActive}
											onChange={this.onChangeSearchActive}
										>
											{this.actives.map((option, index) => (
												<MenuItem key={index} value={option.value}>
													{option.label}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>

								<Grid item xs={12} sm={3}>
									<FormControl
										fullWidth={true}
										variant="standard"
										sx={{ minWidth: 100 }}
									>
										<InputLabel id="orderbylabel">Order by</InputLabel>
										<Select
											labelId="orderbylabel"
											label="Order By"
											value={orderBy}
											onChange={this.onChangeOrderBy}
										>
											{this.sorts.map((option, index) => (
												<MenuItem key={index} value={option.value}>
													{option.label}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>

								<Grid item xs={12} sm={3}>
									<FormControl
										fullWidth={true}
										variant="standard"
										sx={{ minWidth: 100 }}
									>
										<InputLabel id="orderlabel">Sort</InputLabel>
										<Select
											labelId="orderlabel"
											label="Sort"
											value={order}
											onChange={this.onChangeOrder}
										>
											{this.orders.map((option, index) => (
												<MenuItem key={index} value={option.value}>
													{option.label}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>

								<Grid item xs={12} sm={12}>
									<Button
										type="submit"
										variant="contained"
										startIcon={<SearchIcon />}
									>
										Search
									</Button>
								</Grid>
							</Grid>
						</Box>
					</Paper>
				</Grid>

				<Grid item xs={12}>
					<Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
						<Typography variant="h4" gutterBottom component="div">
							Post List
						</Typography>

						{message && (
							<Alert severity="error">
								<AlertTitle>Error</AlertTitle>
								{message}
							</Alert>
						)}

						<Typography variant="subtitle1" gutterBottom component="div">
							showing {this.props.posts.firstItem}- {this.props.posts.lastItem}
							&nbsp; from {this.props.posts.totalItems}
						</Typography>

						<TableContainer component={Paper}>
							<Table sx={{}} aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell width={5}>No.</TableCell>
										<TableCell>Title</TableCell>
										<TableCell align="center">Publish Time</TableCell>
										<TableCell align="center">Status</TableCell>
										<TableCell align="center">Actions</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{rows &&
										rows.map((row, index) => (
											<TableRow
												className={index === currentIndex ? "active" : ""}
												key={index}
												sx={{
													"&:last-child td, &:last-child th": { border: 0 },
												}}
											>
												<TableCell align="right">
													{this.props.posts.firstItem + index}
												</TableCell>
												<TableCell>{row.title}</TableCell>
												<TableCell align="center">{row.publish_time}</TableCell>
												<TableCell align="center">
													{row.active ? "Active" : "-"}
												</TableCell>
												<TableCell align="center">
													<Link
														to={"/editpost/" + row.id}
														className="badge badge-warning"
													>
														<EditIcon />
													</Link>
													<DeleteIcon
														onClick={() =>
															this.confirmDelete(row.id, row.title)
														}
													/>
												</TableCell>
											</TableRow>
										))}
								</TableBody>

								<TableFooter>
									<TableRow>
										<TableCell colSpan={5}>
											<Grid container>
												<Grid item xs={12} sm={3}>
													<FormControl
														fullWidth={true}
														variant="standard"
														sx={{ minWidth: 100 }}
													>
														<InputLabel id="perpagelabel">
															Items per Page
														</InputLabel>
														<Select
															labelId="perpagelabel"
															label="Items per Page"
															value={this.props.common.currentPageSize}
															onChange={this.handlePageSizeChange}
														>
															{this.pageSizes.map((size, index) => (
																<MenuItem key={index} value={size}>
																	{size}
																</MenuItem>
															))}
														</Select>
													</FormControl>
												</Grid>
												<Grid item xs={12} sm={9} justifyContent="flex-end">
													<Grid container justifyContent="flex-end">
														<Stack spacing={2}>
															<Pagination
																count={count}
																page={this.props.common.currentPage}
																onChange={this.handlePageChange}
																color="primary"
																siblingCount={1}
																boundaryCount={1}
															/>
														</Stack>
													</Grid>
												</Grid>
											</Grid>
										</TableCell>
									</TableRow>
								</TableFooter>
							</Table>
						</TableContainer>

						<Dialog
							open={alert}
							onClose={this.handleClose}
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description"
						>
							<DialogTitle id="alert-dialog-title">Delete Alert</DialogTitle>
							<DialogContent>
								<DialogContentText id="alert-dialog-description">
									Yakin akan menghapus "{delTitle}" ?
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={this.handleClose} autoFocus>
									Cancel
								</Button>
								<Button onClick={this.deleteRow}>Yes</Button>
							</DialogActions>
						</Dialog>
					</Paper>
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		posts: state.posts,
		common: state.common,
	};
};

export default connect(mapStateToProps, {
	retrievePosts,
	deletePost,
	setCurrentPage,
	setCurrentPageSize,
})(PostsList);
