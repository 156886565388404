import {
	CREATE_USER,
	RETRIEVE_USER,
	DELETE_USER,
	UPDATE_PROFILE,
} from "./types";

import UserDataService from "../services/users.service";
import { parseNumber } from "../helpers/common";

export const createUser =
	(id, email, name, password, active, id_company, comp, usergroup) =>
	async (dispatch) => {
		try {
			if (active === "true") {
				active = true;
			}
			if (active === "false") {
				active = false;
			}

			id_company = parseNumber(id_company);

			let formData = {
				id: id,
				email: email,
				name: name,
				password: password,
				active: active,
				id_company: id_company,
				companies: comp,
				usergroup: usergroup,
			};

			const res = await UserDataService.create(formData);

			dispatch({
				type: CREATE_USER,
				payload: res.data,
			});

			return Promise.resolve(res.data);
		} catch (err) {
			return Promise.reject(err);
		}
	};

export const retrieveUser = (params) => async (dispatch) => {
	try {
		const res = await UserDataService.getAll(params);

		dispatch({
			type: RETRIEVE_USER,
			payload: res.data,
		});
	} catch (err) {
		return Promise.reject(err);
	}
};

export const deleteUser = (id) => async (dispatch) => {
	try {
		const res = await UserDataService.delete(id);

		dispatch({
			type: DELETE_USER,
			payload: res.data,
		});
	} catch (err) {
		return Promise.reject(err);
	}
};

export const updateProfile =
	(id, email, name, old_password, password) => async (dispatch) => {
		try {
			let formData = {
				id: id,
				email: email,
				name: name,
				old_password: old_password,
				password: password,
			};

			const res = await UserDataService.updateProfile(formData);

			dispatch({
				type: UPDATE_PROFILE,
				payload: res.data,
			});

			return Promise.resolve(res.data);
		} catch (err) {
			return Promise.reject(err);
		}
	};
