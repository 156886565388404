import React, { Component } from "react";
import { connect } from "react-redux";
import { createPost } from "../../actions/posts";
import Form from "react-validation/build/form";
import PostDataService from "../../services/post.service";
import { Link } from "react-router-dom";
//import ReactQuill from 'react-quill';
//import 'react-quill/dist/quill.snow.css';

import "jodit";
import "jodit/build/jodit.min.css";
import JoditEditor from "jodit-react";
import TextField from "@mui/material/TextField";
import { format } from "date-fns";

import SaveIcon from "@mui/icons-material/Save";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
//import MenuItem from '@mui/material/MenuItem';
//import Select from '@mui/material/Select';
//import InputLabel from '@mui/material/InputLabel';
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

class AddPost extends Component {
	constructor(props) {
		super(props);
		this.onChangeTitle = this.onChangeTitle.bind(this);
		this.onChangeIntro = this.onChangeIntro.bind(this);
		this.onChangePublishTime = this.onChangePublishTime.bind(this);
		this.onChangeActive = this.onChangeActive.bind(this);
		this.savePost = this.savePost.bind(this);
		this.selectFile = this.selectFile.bind(this);
		this.newData = this.newData.bind(this);
		this.loadCurrentRow = this.loadCurrentRow.bind(this);

		this.state = {
			id: null,
			title: "",
			intro: "",
			active: 1,
			publish_time: format(new Date(), "yyyy-MM-dd"),
			loading: false,
			alert: false,
			message: false,
			image: false,
			image_thumb: false,
			text: "",
			token: "",
		};
	}

	pageSizes = [5, 10, 20, 100];
	actives = [
		{
			label: "-",
			value: "",
		},
		{
			label: "Active",
			value: 1,
		},
		{
			label: "Inactive",
			value: 0,
		},
	];

	sorts = [
		{
			label: "Title",
			value: "title",
		},
		{
			label: "Publish Time",
			value: "publish_time",
		},
	];

	orders = [
		{
			label: "Asc",
			value: "asc",
		},
		{
			label: "Desc",
			value: "desc",
		},
	];

	componentDidMount() {
		if (typeof this.props.match.params.id !== "undefined") {
			this.loadCurrentRow();
		}
	}

	componentDidUpdate() {
		if (typeof this.props.match.params.id === "undefined") {
			if (this.state.id !== null) {
				this.newData();
			}
		}
	}

	loadCurrentRow() {
		this.setState({ loading: true }, function () {
			PostDataService.get(this.props.match.params.id)
				.then((response) => {
					this.setState(
						{
							id: response.data.id,
							title: response.data.title,
							intro: response.data.intro,
							active: response.data.active,
							publish_time: response.data.publish_time,
							image_thumb: response.data.image_thumb,
							text: response.data.content_text,
							loading: false,
						},
						function () {
							//console.log(typeof(this.state.publish_time));
						}
					);
				})
				.catch((e) => {
					this.setState({ message: e.response.data });
				});
		});
	}

	newData() {
		this.setState({
			id: null,
			title: "",
			intro: "",
			active: 1,
			publish_time: format(new Date(), "yyyy-MM-dd"),
			loading: false,
			message: false,
			image: false,
			image_thumb: false,
			text: "",
		});
	}

	jodit;
	setRef = (jodit) => (this.jodit = jodit);
	user = JSON.parse(localStorage.getItem("user"));
	token =
		this.user && this.user.accessToken ? "Bearer " + this.user.accessToken : "";
	config = {
		readonly: false,
		uploader: {
			url: "https://vwxyz.raywhite.co.id/posts/upload",
			headers: {
				Authorization: this.token,
			},
		},
	};

	updateContent = (value) => {
		this.setState({ text: value });
	};

	onChangeTitle(e) {
		this.setState({ title: e.target.value });
	}

	onChangeIntro(e) {
		this.setState({ intro: e.target.value });
	}

	onChangePublishTime(e) {
		this.setState({ publish_time: e.target.value });
	}

	onChangeActive(e) {
		this.setState({ active: Number(e.target.value) });
	}

	selectFile(e) {
		this.setState({ image: e.target.files[0] });
	}

	alertClose = () => {
		this.setState({ alert: false });
	};

	savePost(e) {
		e.preventDefault();

		this.setState({
			loading: true,
			message: false,
		});

		const { id, title, intro, active, publish_time, image, text } = this.state;

		this.props
			.createPost(id, title, intro, active, publish_time, image, text)
			.then((data) => {
				this.props.history.push("/posts");
			})
			.catch((e) => {
				this.setState({
					loading: false,
					message: e.response.data,
					alert: e.response.data,
				});
			});
	}

	render() {
		return (
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
						<Box component="form" onSubmit={this.savePost}>
							<Typography variant="h5">Post Form</Typography>

							<Grid container spacing={3}>
								<Grid item xs={12} sm={12}>
									<Dialog
										open={this.state.alert}
										onClose={this.alertClose}
										aria-labelledby="alert-dialog-title"
										aria-describedby="alert-dialog-description"
									>
										<DialogTitle id="alert-dialog-title">Failed</DialogTitle>
										<DialogContent>
											<DialogContentText id="alert-dialog-description">
												{this.state.message}
											</DialogContentText>
										</DialogContent>
										<DialogActions>
											<Button onClick={this.alertClose} autoFocus>
												Close
											</Button>
										</DialogActions>
									</Dialog>

									{this.state.image_thumb && (
										<img alt={this.state.title} src={this.state.image_thumb} />
									)}
								</Grid>

								<Grid item xs={12} sm={6}>
									<input
										type="file"
										id="image"
										name="image"
										onChange={this.selectFile}
									/>
								</Grid>

								<Grid item xs={12} sm={6}>
									<TextField
										required
										label="Title"
										value={this.state.title}
										onChange={this.onChangeTitle}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={6}>
									<TextField
										label="Intro"
										fullWidth
										multiline
										rows={2}
										value={this.state.intro}
										onChange={this.onChangeIntro}
									/>
								</Grid>

								<Grid item xs={12} sm={6}>
									<FormControl>
										<FormLabel>Active</FormLabel>
										<RadioGroup row>
											<FormControlLabel
												control={<Radio />}
												label="Active"
												value="1"
												checked={this.state.active === 1}
												onChange={this.onChangeActive}
											/>

											<FormControlLabel
												control={<Radio />}
												label="Inactive"
												value="0"
												checked={this.state.active === 0}
												onChange={this.onChangeActive}
											/>
										</RadioGroup>
									</FormControl>
								</Grid>

								<Grid item xs={12} sm={6}>
									<TextField
										id="publish_time"
										label="Publish Time"
										type="date"
										value={this.state.publish_time}
										onChange={this.onChangePublishTime}
										className="MuiInput-input"
										InputLabelProps={{
											shrink: true,
										}}
									/>
								</Grid>

								<Grid item xs={12} sm={12}>
									<JoditEditor
										editorRef={this.setRef}
										value={this.state.text}
										config={this.config}
										onBlur={(newContent) => this.updateContent(newContent)}
										onChange={(newContent) => {}}
									/>
								</Grid>

								<Grid item xs={12} sm={12}>
									<Link classes="button" to={"/posts/"}>
										Cancel
									</Link>

									<Button
										type="submit"
										variant="contained"
										disabled={this.state.loading}
										startIcon={<SaveIcon />}
									>
										{this.state.loading && (
											<span>
												<span className="spinner-border spinner-border-sm"></span>
												&nbsp;
											</span>
										)}
										<span> {this.state.loading ? "Please Wait" : "Save"}</span>
									</Button>
								</Grid>
							</Grid>
						</Box>
					</Paper>
				</Grid>

				<div className="col-md-12">
					<Form onSubmit={this.savePost}>
						<div>
							<div className="form-group"></div>
						</div>
					</Form>
				</div>
			</Grid>
		);
	}
}

export default connect(null, { createPost })(AddPost);
