import axios from "axios";

const user = JSON.parse(localStorage.getItem("user"));
const token = (user && user.accessToken) ? "Bearer " + user.accessToken : "";

const httpm = axios.create({
    baseURL: "https://vwxyz.raywhite.co.id",
    headers: {
        "Content-type": "multipart/form-data;application/json",
        "Authorization": token
    }
});

export default httpm;