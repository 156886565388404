import { CREATE_TAX, RETRIEVE_TAX, DELETE_TAX } from "../actions/types";

const initialState = {};

const taxReducer = function (tax = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case CREATE_TAX:
			return payload;

		case RETRIEVE_TAX:
			return payload;

		case DELETE_TAX:
			return payload;

		default:
			return tax;
	}
};

export default taxReducer;
