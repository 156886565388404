import {
	CREATE_PURCHASE_ORDER,
	RETRIEVE_PURCHASE_ORDER,
	DELETE_PURCHASE_ORDER,
} from "../actions/types";

const initialState = {};

const purchaseOrderReducer = function (pruchaseorder = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case CREATE_PURCHASE_ORDER:
			return payload;

		case RETRIEVE_PURCHASE_ORDER:
			return payload;

		case DELETE_PURCHASE_ORDER:
			return payload;

		default:
			return pruchaseorder;
	}
};

export default purchaseOrderReducer;
