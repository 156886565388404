import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import loading from "./loading";
import tutorials from "./tutorials";
import posts from "./posts";
import common from "./common";
import card from "./card";
import account from "./account";
import company from "./company";
import job from "./job";
import tax from "./tax";
import user from "./user";
import journal from "./journal";
import transfer_money from "./transfer_money";
import spendMoney from "./spendMoney";
import paymentMethod from "./paymentMethod";
import receiveMoney from "./receiveMoney";
import order from "./order";
import invoice from "./invoice";
import receivePayment from "./receivePayment";
import purchaseOrder from "./purchaseOrder";
import bill from "./bill";
import payBill from "./payBill";

export default combineReducers({
	auth,
	message,
	loading,
	tutorials,
	posts,
	common,
	card,
	account,
	company,
	job,
	tax,
	user,
	journal,
	transfer_money,
	spendMoney,
	paymentMethod,
	receiveMoney,
	order,
	invoice,
	receivePayment,
	purchaseOrder,
	bill,
	payBill
});
