import {
	CURRENT_PAGE,
	CURRENT_PAGESIZE,
	CURRENT_ORDER,
	CURRENT_ORDERBY,
	CURRENT_MODULE,
	CURRENT_DATEFROM,
	CURRENT_DATETO,
	CURRENT_MONTH,
	CURRENT_YEAR,
	CURRENT_LOOK,
	CURRENT_PERIOD,
	CURRENT_ACCOUNT,
} from "./types";

export const setCurrentPage = (value) => (dispatch) => {
	dispatch({
		type: CURRENT_PAGE,
		payload: value,
	});
	return Promise.resolve();
};

export const setCurrentPageSize = (value) => (dispatch) => {
	dispatch({
		type: CURRENT_PAGESIZE,
		payload: value,
	});
	return Promise.resolve();
};

export const setCurrentOrder = (value) => (dispatch) => {
	dispatch({
		type: CURRENT_ORDER,
		payload: value,
	});
	return Promise.resolve();
};

export const setCurrentOrderBy = (value) => (dispatch) => {
	dispatch({
		type: CURRENT_ORDERBY,
		payload: value,
	});
	return Promise.resolve();
};

export const setModule = (value) => (dispatch) => {
	dispatch({
		type: CURRENT_MODULE,
		payload: value,
	});
	return Promise.resolve();
};

export const setDateFrom = (value) => (dispatch) => {
	dispatch({
		type: CURRENT_DATEFROM,
		payload: value,
	});
	return Promise.resolve();
};

export const setDateTo = (value) => (dispatch) => {
	dispatch({
		type: CURRENT_DATETO,
		payload: value,
	});
	return Promise.resolve();
};

export const setMonth = (value) => (dispatch) => {
	dispatch({
		type: CURRENT_MONTH,
		payload: value,
	});
	return Promise.resolve();
};
export const setYear = (value) => (dispatch) => {
	dispatch({
		type: CURRENT_YEAR,
		payload: value,
	});
	return Promise.resolve();
};
export const setLook = (value) => (dispatch) => {
	dispatch({
		type: CURRENT_LOOK,
		payload: value,
	});
	return Promise.resolve();
};
export const setPeriod = (value) => (dispatch) => {
	dispatch({
		type: CURRENT_PERIOD,
		payload: value,
	});
	return Promise.resolve();
};
export const setAccounts = (value) => (dispatch) => {
	dispatch({
		type: CURRENT_ACCOUNT,
		payload: value,
	});
	return Promise.resolve();
};
