import React, { Component } from "react";
import { connect } from "react-redux";
import { createTax } from "../../actions/tax";
import { setModule } from "../../actions/common";
import { showLoading, hideLoading } from "../../actions/loading";
import TaxDataService from "../../services/tax.service";
import CardDataService from "../../services/card.service";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import SaveIcon from "@mui/icons-material/Save";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

class AddTax extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			id: null,
			id_company: this.props.auth.idCompany,
			id_type: 0,
			id_account_collected: 0,
			id_account_paid: 0,
			tax_code: "",
			name: "",
			rate: 0,
			note: "",

			error: false,
			message: false,

			companies_ready: false,
			types_ready: false,
			accounts_ready: false,

			companies: [],
			types: [],
			accounts: [],
		};
	}

	componentDidMount() {
		this._isMounted = true;
		if (this._isMounted) {
			this.props.setModule("Tax");
			if (typeof this.props.match.params.id !== "undefined") {
				this.loadCurrentRow();
				this.loadSelect();
			} else {
				this.loadSelect();
			}
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	componentDidUpdate() {
		if (typeof this.props.match.params.id === "undefined") {
			if (this.state.id !== null) {
				this.newData();
			}
		}
	}

	loadSelect() {
		CardDataService.getCompanies().then((response) => {
			this.setState({
				companies_ready: true,
				companies: response.data.Row,
			});
		});
		TaxDataService.getType().then((response) => {
			this.setState({
				types_ready: true,
				types: response.data.Row,
			});
		});
		TaxDataService.getAccount().then((response) => {
			this.setState({
				accounts_ready: true,
				accounts: response.data.Row,
			});
		});
	}

	loadCurrentRow() {
		this.props.showLoading();

		TaxDataService.get(this.props.match.params.id)
			.then((response) => {
				this.setState(
					{
						id: response.data.Row.id,
						id_company: response.data.Row.id_company,
						id_type: response.data.Row.id_type,
						id_account_collected: response.data.Row.id_account_collected,
						id_account_paid: response.data.Row.id_account_paid,
						tax_code: response.data.Row.tax_code,
						name: response.data.Row.name,
						rate: response.data.Row.rate,
						note: response.data.Row.note,
					},
					function () {
						this.props.hideLoading();
					}
				);
			})
			.catch((e) => {
				console.log(e.response.data.error);
				this.setState({ message: e.response.data.error });
			});
	}

	newData() {
		this.setState({
			id: null,
			id_company: this.props.auth.idCompany,
			id_type: 0,
			id_account_collected: 0,
			id_account_paid: 0,
			tax_code: "",
			name: "",
			rate: 0,
			note: "",

			message: false,
		});
	}

	onChangeIdCompany(e) {
		this.setState({ id_company: e.target.value });
	}
	onChangeIdType(e) {
		this.setState({ id_type: e.target.value });
	}
	onChangeIdAccountCollected(e) {
		this.setState({ id_account_collected: e.target.value });
	}
	onChangeIdAccountPaid(e) {
		this.setState({ id_account_paid: e.target.value });
	}

	onChangeTaxCode(e) {
		this.setState({ tax_code: e.target.value });
	}
	onChangeName(e) {
		this.setState({ name: e.target.value });
	}
	onChangeNote(e) {
		this.setState({ note: e.target.value });
	}
	onChangeRate(e) {
		this.setState({ rate: e.target.value });
	}

	alertClose = () => {
		this.setState({ error: false });
	};

	saveTax(e) {
		e.preventDefault();
		this.props.showLoading();

		this.setState({
			message: false,
		});

		this.props
			.createTax(
				this.state.id,
				this.state.id_company,
				this.state.id_type,
				this.state.id_account_collected,
				this.state.id_account_paid,
				this.state.tax_code,
				this.state.name,
				parseFloat(this.state.rate),
				this.state.note
			)
			.then((data) => {
				this.props.history.push("/tax/form");
			})
			.catch((e) => {
				this.setState({
					message: e.response.data.error,
					error: true,
				});
				this.props.hideLoading();
			});
	}

	render() {
		return (
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
						<Box component="form" onSubmit={(e) => this.saveTax(e)}>
							<Typography variant="h5">Tax Form</Typography>

							<Grid container spacing={3}>
								<Grid item xs={12} sm={12}>
									<Dialog
										open={this.state.error}
										onClose={this.alertClose}
										aria-labelledby="error-dialog-title"
										aria-describedby="error-dialog-description"
									>
										<DialogTitle id="error-dialog-title">Failed</DialogTitle>
										<DialogContent>
											<DialogContentText id="error-dialog-description">
												{this.state.message}
											</DialogContentText>
										</DialogContent>
										<DialogActions>
											<Button onClick={this.alertClose} autoFocus>
												Close
											</Button>
										</DialogActions>
									</Dialog>
								</Grid>

								{this.props.auth.user.usergroup === "administrator" && (
									<Grid item xs={12} sm={12}>
										{this.state.companies_ready && (
											<FormControl
												fullWidth={true}
												variant="standard"
												sx={{ minWidth: 100 }}
											>
												<InputLabel id="idCompany">Company</InputLabel>
												<Select
													labelId="idCompany"
													label="Company"
													value={this.state.id_company}
													onChange={(e) => this.onChangeIdCompany(e)}
												>
													{this.state.companies.map((option, index) => (
														<MenuItem key={index} value={option.id}>
															{option.name}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										)}
									</Grid>
								)}

								<Grid item xs={12} sm={6}>
									<TextField
										label="Code"
										value={this.state.tax_code}
										onChange={(e) => this.onChangeTaxCode(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={6}>
									<TextField
										required
										label="Name"
										value={this.state.name}
										onChange={(e) => this.onChangeName(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={6}>
									{this.state.types_ready && (
										<FormControl
											fullWidth={true}
											variant="standard"
											sx={{ minWidth: 100 }}
										>
											<InputLabel id="idType">Type</InputLabel>
											<Select
												labelId="idType"
												label="Type"
												value={this.state.id_type}
												onChange={(e) => this.onChangeIdType(e)}
											>
												<MenuItem value="0">
													<em>None</em>
												</MenuItem>
												{this.state.types.map((option, index) => (
													<MenuItem key={index} value={option.id}>
														{option.name}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									)}
								</Grid>

								<Grid item xs={12} sm={6}>
									{this.state.accounts_ready && (
										<FormControl
											fullWidth={true}
											variant="standard"
											sx={{ minWidth: 100 }}
										>
											<InputLabel id="idAccountCollected">
												Collected Account
											</InputLabel>
											<Select
												labelId="idAccountCollected"
												label="Collected Account"
												value={this.state.id_account_collected}
												onChange={(e) => this.onChangeIdAccountCollected(e)}
											>
												<MenuItem value="0">
													<em>None</em>
												</MenuItem>
												{this.state.accounts.map((option, index) => (
													<MenuItem key={index} value={option.id}>
														{option.name}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									)}
								</Grid>

								<Grid item xs={12} sm={6}>
									{this.state.accounts_ready && (
										<FormControl
											fullWidth={true}
											variant="standard"
											sx={{ minWidth: 100 }}
										>
											<InputLabel id="idAccountPaid">Paid Account</InputLabel>
											<Select
												labelId="idAccountPaid"
												label="Paid Account"
												value={this.state.id_account_paid}
												onChange={(e) => this.onChangeIdAccountPaid(e)}
											>
												<MenuItem value="0">
													<em>None</em>
												</MenuItem>
												{this.state.accounts.map((option, index) => (
													<MenuItem key={index} value={option.id}>
														{option.name}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									)}
								</Grid>

								<Grid item xs={12} sm={3}>
									<TextField
										label="Rate"
										value={this.state.rate}
										onChange={(e) => this.onChangeRate(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>
								<Grid item xs={12} sm={3}>
									<TextField
										label="Note"
										value={this.state.note}
										onChange={(e) => this.onChangeNote(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={12}>
									<Button
										variant="contained"
										component={Link}
										to="/tax/form"
										startIcon={<ArrowBackIcon />}
										sx={{ mr: 1 }}
									>
										Cancel
									</Button>
									<Button
										type="submit"
										variant="contained"
										disabled={this.props.loading.loading}
										startIcon={<SaveIcon />}
									>
										{this.props.loading.loading && (
											<span>
												<span className="spinner-border spinner-border-sm"></span>
												&nbsp;
											</span>
										)}
										<span>
											{" "}
											{this.props.loading.loading ? "Please Wait" : "Save"}
										</span>
									</Button>
								</Grid>
							</Grid>
						</Box>
					</Paper>
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
		loading: state.loading,
	};
};

export default connect(mapStateToProps, {
	createTax,
	showLoading,
	hideLoading,
	setModule,
})(AddTax);
