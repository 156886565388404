import { SHOW_LOADING, HIDE_LOADING } from "../actions/types";

const initialState = { loading: false };

const a = function (state = initialState, action) {
	const { type } = action;

	switch (type) {
		case SHOW_LOADING:
			return { loading: true };

		case HIDE_LOADING:
			return { loading: false };

		default:
			return state;
	}
};

export default a;
