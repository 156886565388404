import {
	CREATE_PAYMENT_METHOD,
	RETRIEVE_PAYMENT_METHOD,
	DELETE_PAYMENT_METHOD,
} from "../actions/types";

const initialState = {};

const paymentMethodReducer = function (paymentMethod = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case CREATE_PAYMENT_METHOD:
			return payload;

		case RETRIEVE_PAYMENT_METHOD:
			return payload;

		case DELETE_PAYMENT_METHOD:
			return payload;

		default:
			return paymentMethod;
	}
};

export default paymentMethodReducer;
