import {
	CREATE_RECEIVE_PAYMENT,
	RETRIEVE_RECEIVE_PAYMENT,
	DELETE_RECEIVE_PAYMENT,
} from "../actions/types";

const initialState = {};

const receivePaymentReducer = function (receivePayment = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case CREATE_RECEIVE_PAYMENT:
			return payload;

		case RETRIEVE_RECEIVE_PAYMENT:
			return payload;

		case DELETE_RECEIVE_PAYMENT:
			return payload;

		default:
			return receivePayment;
	}
};

export default receivePaymentReducer;
