import {
	CREATE_JOURNAL,
	RETRIEVE_JOURNAL,
	DELETE_JOURNAL,
} from "../actions/types";

const initialState = {};

const journalReducer = function (journal = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case CREATE_JOURNAL:
			return payload;

		case RETRIEVE_JOURNAL:
			return payload;

		case DELETE_JOURNAL:
			return payload;

		default:
			return journal;
	}
};

export default journalReducer;
