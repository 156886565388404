import {
	CREATE,
	RETRIEVE,
	DELETE
} from "./types";
	
import PostDataService from "../services/post.service";

export const createPost = (id,title,intro,active,publish_time,image,text) => async (dispatch) => {
	try {

		let formData = new FormData();

		formData.append("id", id);
		formData.append("title", title);
		formData.append("intro", intro);
		formData.append("publish_time", publish_time);
		formData.append("active", active);
		formData.append("image", image);
		formData.append("text", text);

		const res = await PostDataService.create(formData);

		dispatch({
		type: CREATE,
		payload: res.data,
		});

		return Promise.resolve(res.data);
	} catch (err) {
		return Promise.reject(err);
	}
};

export const retrievePosts = (params) => async (dispatch) => {
	try {
		const res = await PostDataService.getAll(params);

		dispatch({
			type: RETRIEVE,
			payload: res.data,
		});
	} catch (err) {
		return Promise.reject(err);
	}
};

export const deletePost = (id) => async (dispatch) => {
	try {
		const res = await PostDataService.delete(id);

		dispatch({
			type: DELETE,
			payload: res.data,
		});
	} catch (err) {
		return Promise.reject(err);
	}
};

