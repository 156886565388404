import { CREATE_CARD, RETRIEVE_CARD, DELETE_CARD } from "../actions/types";

const initialState = {};

const cardReducer = function (card = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case CREATE_CARD:
			return payload;

		case RETRIEVE_CARD:
			return payload;

		case DELETE_CARD:
			return payload;

		default:
			return card;
	}
};

export default cardReducer;
