import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Router, Switch, Route } from "react-router-dom";

import { Link } from "react-router-dom";
//import Login from "./components/Login";
import Register from "./components/Register";
import Home from "./components/Home";
import Profile from "./components/Profile";
import BoardModerator from "./components/BoardModerator";
import BoardAdmin from "./components/BoardAdmin";
import Signin from "./components/Signin";

import AddTutorial from "./components/tutorial/AddTutorial";
import Tutorial from "./components/tutorial/Tutorial";
import TutorialsList from "./components/tutorial/TutorialsList";

import AddTutorial2 from "./components/tutorial2/add";
//import Tutorial2 from "./components/tutorial2/update";
import TutorialsList2 from "./components/tutorial2/list";

import AddPost from "./components/post/add";
import PostsList from "./components/post/list";

import AddCard from "./components/card/add";
import CardList from "./components/card/list";
import AddAccount from "./components/account/add";
import AccountList from "./components/account/list";
import AddCompany from "./components/company/add";
import ClosePeriod from "./components/company/closePeriod";
import CompanyList from "./components/company/list";
import AddJob from "./components/job/add";
import JobList from "./components/job/list";
import AddPaymentMethod from "./components/payment_method/add";
import PaymentMethodList from "./components/payment_method/list";
import AddTax from "./components/tax/add";
import TaxList from "./components/tax/list";
import AddUser from "./components/user/add";
import UserList from "./components/user/list";
import UpdateProfile from "./components/user/profile";
import UpdateLinkedAccounts from "./components/account/linkedAccounts";

import AddJournal from "./components/journal/add";
import JournalList from "./components/journal/list";
import AddTransferMoney from "./components/transfer_money/add";
import TransferMoneyList from "./components/transfer_money/list";
import AddSpendMoney from "./components/spend_money/add";
import SpendMoneyList from "./components/spend_money/list";
import BankRegisterList from "./components/spend_money/bankRegister";
import SalesRegisterList from "./components/spend_money/salesRegister";
import PurchasesRegisterList from "./components/spend_money/purchasesRegister";
import AddReceiveMoney from "./components/receive_money/add";
import ReceiveMoneyList from "./components/receive_money/list";
import AddOrder from "./components/order/add";
import OrderList from "./components/order/list";
import AddInvoice from "./components/invoice/add";
import InvoiceList from "./components/invoice/list";
import AddReceivePayment from "./components/receive_payment/add";
import ReceivePaymentList from "./components/receive_payment/list";

import AddPurchaseOrder from "./components/purchase_order/add";
import PurchaseOrderList from "./components/purchase_order/list";
import AddBill from "./components/bill/add";
import BillList from "./components/bill/list";
import AddPayBill from "./components/pay_bill/add";
import PayBillList from "./components/pay_bill/list";

import ToDoList from "./components/todolist/list";
import TransactionJournalReport from "./components/reports/transactionJournal";
import FindTransactionsReport from "./components/reports/findTransactions";
import generalLedgerDetailReport from "./components/reports/generalLedgerDetail";
import GLDetailConsolidateReport from "./components/reports/GLDetailConsolidate";
import standardBalanceSheetReport from "./components/reports/standardBalanceSheet";
import standardBalanceSheetConsolidateReport from "./components/reports/standardBalanceSheetConsolidate";
import profitLossYTDReport from "./components/reports/profitLossYTD";
import profitLossYTDConsolidateReport from "./components/reports/profitLossYTDConsolidate";
import jobProfitLossReport from "./components/reports/jobProfitLoss";
import TrialBalanceReport from "./components/reports/trialBalance";
import StatementOfCashFlowReport from "./components/reports/statementOfCashFlow";
import CashFlowAnalysisReport from "./components/reports/cashFlowAnalysis";

import { logout } from "./actions/auth";
import { clearMessage } from "./actions/message";

import { history } from "./helpers/history";

import AuthVerify from "./common/AuthVerify";
import EventBus from "./common/EventBus";

import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
//import Badge from "@mui/material/Badge";
import Container from "@mui/material/Container";
import Linkm from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
//import NotificationsIcon from "@mui/icons-material/Notifications";

import CircularProgress from "@mui/material/CircularProgress";
import Fab from "@mui/material/Fab";

import MenuItem from "@mui/material/MenuItem";
import MenuList from "./components/MenuList";
import Menu from "@mui/material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
function Copyright(props) {
	return (
		<Typography
			variant="body2"
			color="text.secondary"
			align="center"
			{...props}
		>
			{"Copyright © "}
			<Linkm color="inherit" target="_blank" href="https://www.raywhite.co.id/">
				PT. Ray Propertindo
			</Linkm>{" "}
			{new Date().getFullYear()}
			{"."}
		</Typography>
	);
}

const drawerWidth = 250;

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(["width", "margin"], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	"& .MuiDrawer-paper": {
		position: "relative",
		whiteSpace: "nowrap",
		width: drawerWidth,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		boxSizing: "border-box",
		...(!open && {
			overflowX: "hidden",
			transition: theme.transitions.create("width", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			width: theme.spacing(7),
			[theme.breakpoints.up("sm")]: {
				width: theme.spacing(9),
			},
		}),
	},
}));

const mdTheme = createTheme();

function DashboardContent() {
	const [open, setOpen] = React.useState(true);
	const toggleDrawer = () => {
		setOpen(!open);
	};

	const { user: currentUser } = useSelector((state) => state.auth);
	const { loading } = useSelector((state) => state.loading);
	const { module } = useSelector((state) => state.common);
	const dispatch = useDispatch();

	useEffect(() => {
		history.listen((location) => {
			dispatch(clearMessage()); // clear message when changing location
		});
	}, [dispatch]);

	const logOut = useCallback(() => {
		dispatch(logout());
	}, [dispatch]);

	useEffect(() => {
		document.title = "FAST - " + module;

		EventBus.on("logout", () => {
			logOut();
		});

		return () => {
			EventBus.remove("logout");
		};
	}, [currentUser, logOut, module]);

	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleMenu = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<ThemeProvider theme={mdTheme}>
			{currentUser ? (
				<Box sx={{ display: "flex" }}>
					{loading && (
						<Fab
							sx={{
								position: "fixed",
								top: "50%",
								right: "50%",
							}}
							color="default"
						>
							<CircularProgress />
						</Fab>
					)}
					<CssBaseline />
					<AppBar position="absolute" open={open}>
						<Toolbar
							variant="dense"
							sx={{
								pr: "24px", // keep right padding when drawer closed
							}}
						>
							<IconButton
								edge="start"
								color="inherit"
								aria-label="open drawer"
								onClick={toggleDrawer}
								sx={{
									marginRight: "36px",
									...(open && { display: "none" }),
								}}
							>
								<MenuIcon />
							</IconButton>
							<Typography
								component="div"
								variant="subtitle1"
								color="inherit"
								noWrap
								sx={{ flexGrow: 1 }}
							>
								{module}
							</Typography>

							<Typography component="div" variant="subtitle1" color="inherit">
								{currentUser.company}
							</Typography>

							<div>
								<IconButton
									size="large"
									aria-label="account of current user"
									aria-controls="menu-appbar"
									aria-haspopup="true"
									onClick={handleMenu}
									color="inherit"
								>
									<AccountCircle />
								</IconButton>
								<Menu
									id="menu-appbar"
									anchorEl={anchorEl}
									anchorOrigin={{
										vertical: "top",
										horizontal: "right",
									}}
									keepMounted
									transformOrigin={{
										vertical: "top",
										horizontal: "right",
									}}
									open={Boolean(anchorEl)}
									onClose={handleClose}
								>
									<Router history={history}>
										<MenuItem
											dense={true}
											component={Link}
											to="/transfermoney/main"
										>
											Transfer Money
										</MenuItem>
										<MenuItem dense={true} component={Link} to="/journal/main">
											Journal
										</MenuItem>
										<MenuItem
											dense={true}
											component={Link}
											to="/spendmoney/main"
										>
											Spend Money
										</MenuItem>
										<MenuItem
											dense={true}
											component={Link}
											to="/receivemoney/main"
										>
											Receive Money
										</MenuItem>
										<MenuItem dense={true} component={Link} to="/order/main">
											Order
										</MenuItem>
										<MenuItem dense={true} component={Link} to="/invoice/main">
											Invoice
										</MenuItem>

										<MenuItem
											dense={true}
											component={Link}
											to="/purchaseorder/main"
										>
											Purchases Order
										</MenuItem>
										<MenuItem dense={true} component={Link} to="/bill/main">
											Bill
										</MenuItem>

										<Divider />

										<MenuItem dense={true} component={Link} to="/profile">
											Profile
										</MenuItem>

										<MenuItem dense={true} onClick={logOut}>
											Logout
										</MenuItem>
									</Router>
								</Menu>
							</div>

							{/* <IconButton color="inherit">
								<Badge badgeContent={4} color="secondary">
									<NotificationsIcon />
								</Badge>
							</IconButton> */}
						</Toolbar>
					</AppBar>
					<Drawer variant="permanent" open={open}>
						<Toolbar
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "flex-end",
								px: [1],
							}}
						>
							<IconButton onClick={toggleDrawer}>
								<ChevronLeftIcon />
							</IconButton>
						</Toolbar>
						<Divider />

						<List
							dense={true}
							component="nav"
							sx={{
								flexGrow: 1,
								height: "80vh",
								overflow: "hide",
							}}
						>
							<Router history={history}>
								<MenuList
									module={module}
									logOut={logOut}
									usergroup={currentUser.usergroup}
								/>
							</Router>
						</List>

						<Divider />
					</Drawer>
					<Box
						component="main"
						sx={{
							backgroundColor: (theme) =>
								theme.palette.mode === "light"
									? theme.palette.grey[100]
									: theme.palette.grey[900],
							flexGrow: 1,
							height: "100vh",
							overflow: "auto",
						}}
					>
						<Toolbar />
						<Container maxWidth="lg" sx={{ mt: 1, mb: 4 }}>
							<Router history={history}>
								<Switch>
									<Route exact path={["/", "/home"]} component={Home} />
									<Route exact path="/login" component={Signin} />
									<Route exact path="/register" component={Register} />
									<Route exact path="/profilelama" component={Profile} />

									<Route path="/mod" component={BoardModerator} />
									<Route path="/admin" component={BoardAdmin} />
									<Route exact path="/tutorials" component={TutorialsList} />
									<Route exact path="/addtutorials" component={AddTutorial} />
									<Route path="/tutorials/:id" component={Tutorial} />

									<Route exact path="/tutorials2" component={TutorialsList2} />
									<Route exact path="/addtutorials2" component={AddTutorial2} />
									<Route path="/edittutorials2/:id" component={AddTutorial2} />

									<Route exact path="/posts" component={PostsList} />
									<Route exact path="/addposts" component={AddPost} />
									<Route path="/editpost/:id" component={AddPost} />

									<Route exact path="/card/:source" component={CardList} />
									<Route exact path="/addcard" component={AddCard} />
									<Route path="/editcard/:id" component={AddCard} />

									<Route
										exact
										path="/account/:source"
										component={AccountList}
									/>
									<Route exact path="/addaccount" component={AddAccount} />
									<Route path="/editaccount/:id" component={AddAccount} />

									<Route
										path="/linkedaccounts"
										component={UpdateLinkedAccounts}
									/>

									<Route
										exact
										path="/company/:source"
										component={CompanyList}
									/>
									<Route exact path="/addcompany" component={AddCompany} />
									<Route path="/editcompany/:id" component={AddCompany} />
									<Route exact path="/closeperiod" component={ClosePeriod} />

									<Route exact path="/job/:source" component={JobList} />
									<Route exact path="/addjob" component={AddJob} />
									<Route path="/editjob/:id" component={AddJob} />

									<Route exact path="/tax/:source" component={TaxList} />
									<Route exact path="/addtax" component={AddTax} />
									<Route path="/edittax/:id" component={AddTax} />

									<Route
										exact
										path="/paymentmethod/:source"
										component={PaymentMethodList}
									/>
									<Route
										exact
										path="/addpaymentmethod"
										component={AddPaymentMethod}
									/>
									<Route
										path="/editpaymentmethod/:id"
										component={AddPaymentMethod}
									/>

									<Route exact path="/user/:source" component={UserList} />
									<Route exact path="/adduser" component={AddUser} />
									<Route path="/edituser/:id" component={AddUser} />
									<Route path="/profile" component={UpdateProfile} />

									<Route
										exact
										path="/journal/:source"
										component={JournalList}
									/>
									<Route exact path="/addjournal" component={AddJournal} />
									<Route
										exact
										path="/editjournal/:id/:id_recurring"
										component={AddJournal}
									/>
									<Route path="/editjournal/:id" component={AddJournal} />

									<Route
										exact
										path="/transfermoney/:source"
										component={TransferMoneyList}
									/>
									<Route
										exact
										path="/addtransfermoney"
										component={AddTransferMoney}
									/>
									<Route
										exact
										path="/edittransfermoney/:id/:id_recurring"
										component={AddTransferMoney}
									/>
									<Route
										path="/edittransfermoney/:id"
										component={AddTransferMoney}
									/>

									<Route
										exact
										path="/spendmoney/:source"
										component={SpendMoneyList}
									/>
									<Route
										exact
										path="/addspendmoney"
										component={AddSpendMoney}
									/>
									<Route
										exact
										path="/editspendmoney/:id/:id_recurring"
										component={AddSpendMoney}
									/>
									<Route path="/editspendmoney/:id" component={AddSpendMoney} />

									<Route
										exact
										path="/bankregister/:source"
										component={BankRegisterList}
									/>

									<Route
										exact
										path="/salesregister/:source"
										component={SalesRegisterList}
									/>

									<Route
										exact
										path="/purchasesregister/:source"
										component={PurchasesRegisterList}
									/>

									<Route
										exact
										path="/receivemoney/:source"
										component={ReceiveMoneyList}
									/>
									<Route
										exact
										path="/addreceivemoney"
										component={AddReceiveMoney}
									/>
									<Route
										exact
										path="/editreceivemoney/:id/:id_recurring"
										component={AddReceiveMoney}
									/>
									<Route
										exact
										path="/editreceivemoney/:id"
										component={AddReceiveMoney}
									/>

									<Route exact path="/order/:source" component={OrderList} />
									<Route exact path="/addorder" component={AddOrder} />
									<Route
										exact
										path="/editorder/:id/:id_recurring"
										component={AddOrder}
									/>
									<Route exact path="/editorder/:id" component={AddOrder} />

									<Route
										exact
										path="/invoice/:source"
										component={InvoiceList}
									/>
									<Route exact path="/addinvoice" component={AddInvoice} />
									<Route
										exact
										path="/editinvoice/:id/:id_sales/:id_recurring"
										component={AddInvoice}
									/>
									<Route
										exact
										path="/editinvoice/:id/:id_sales"
										component={AddInvoice}
									/>
									<Route exact path="/editinvoice/:id" component={AddInvoice} />

									<Route
										exact
										path="/receivepayment/:source"
										component={ReceivePaymentList}
									/>
									<Route
										exact
										path="/addreceivepayment"
										component={AddReceivePayment}
									/>
									<Route
										path="/editreceivepayment/:id/:id_source"
										component={AddReceivePayment}
									/>
									<Route
										path="/editreceivepayment/:id"
										component={AddReceivePayment}
									/>

									<Route
										exact
										path="/purchaseorder/:source"
										component={PurchaseOrderList}
									/>
									<Route
										exact
										path="/addpurchaseorder"
										component={AddPurchaseOrder}
									/>
									<Route
										exact
										path="/editpurchaseorder/:id/:id_recurring"
										component={AddPurchaseOrder}
									/>
									<Route
										exact
										path="/editpurchaseorder/:id"
										component={AddPurchaseOrder}
									/>

									<Route exact path="/bill/:source" component={BillList} />
									<Route exact path="/addbill" component={AddBill} />
									<Route
										exact
										path="/editbill/:id/:id_sales/:id_recurring"
										component={AddBill}
									/>
									<Route
										exact
										path="/editbill/:id/:id_sales"
										component={AddBill}
									/>
									<Route exact path="/editbill/:id" component={AddBill} />

									<Route
										exact
										path="/paybill/:source"
										component={PayBillList}
									/>
									<Route exact path="/addpaybill" component={AddPayBill} />
									<Route
										path="/editpaybill/:id/:id_source"
										component={AddPayBill}
									/>
									<Route path="/editpaybill/:id" component={AddPayBill} />

									<Route path="/todolist" component={ToDoList} />
									<Route
										path="/transactionjournal"
										component={TransactionJournalReport}
									/>
									<Route
										path="/findtransactions"
										component={FindTransactionsReport}
									/>
									<Route
										path="/generalledgerdetail"
										component={generalLedgerDetailReport}
									/>
									<Route
										path="/gldetailconsolidate"
										component={GLDetailConsolidateReport}
									/>

									<Route
										path="/standardbalancesheet"
										component={standardBalanceSheetReport}
									/>
									<Route
										path="/standardbalancesheetconsolidate"
										component={standardBalanceSheetConsolidateReport}
									/>
									<Route
										path="/profitlossytd"
										component={profitLossYTDReport}
									/>
									<Route
										path="/profitlossytdconsolidate"
										component={profitLossYTDConsolidateReport}
									/>
									<Route
										path="/jobprofitloss"
										component={jobProfitLossReport}
									/>
									<Route path="/trialbalance" component={TrialBalanceReport} />
									<Route
										path="/statementofcashflow"
										component={StatementOfCashFlowReport}
									/>
									<Route
										path="/cashflowanalysis"
										component={CashFlowAnalysisReport}
									/>
								</Switch>
								<AuthVerify logOut={logOut} />
							</Router>
							<Copyright sx={{ pt: 4 }} />
						</Container>
					</Box>
				</Box>
			) : (
				<Signin />
			)}
		</ThemeProvider>
	);
}

export default function Dashboard() {
	return <DashboardContent />;
}
