import { CREATE_PAYBILL, RETRIEVE_PAYBILL, DELETE_PAYBILL } from "./types";

import PayBillDataService from "../services/payBill.service";
import { parseNumber } from "../helpers/common";

export const createPayBill =
	(id, id_company, id_source, id_card, code, date, note, amount, from) =>
	async (dispatch) => {
		try {
			amount = parseFloat(amount);
			code = code.toString();
			id_card = parseNumber(id_card);

			let formData = {
				id: id,
				id_company: id_company,
				id_source: id_source,
				id_card: id_card,
				code: code,
				date: date,
				note: note,
				amount: amount,
				from: from,
			};

			const res = await PayBillDataService.create(formData);

			dispatch({
				type: CREATE_PAYBILL,
				payload: res.data,
			});

			return Promise.resolve(res.data);
		} catch (err) {
			return Promise.reject(err);
		}
	};

export const retrievePayBill = (params) => async (dispatch) => {
	try {
		const res = await PayBillDataService.getAll(params);

		dispatch({
			type: RETRIEVE_PAYBILL,
			payload: res.data,
		});
	} catch (err) {
		return Promise.reject(err);
	}
};

export const deletePayBill = (id) => async (dispatch) => {
	try {
		const res = await PayBillDataService.delete(id);

		dispatch({
			type: DELETE_PAYBILL,
			payload: res.data,
		});
	} catch (err) {
		return Promise.reject(err);
	}
};
