import React, { Component } from "react";
import { connect } from "react-redux";
import { updateProfile } from "../../actions/user";
import { setModule } from "../../actions/common";
import { showLoading, hideLoading } from "../../actions/loading";
import UserDataService from "../../services/users.service";
import TextField from "@mui/material/TextField";
import SaveIcon from "@mui/icons-material/Save";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Alert from "@mui/material/Alert";

class UpdateProfile extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			id: this.props.auth.user.userid,
			email: "",
			name: "",
			old_password: "",
			password: "",

			error: false,
			message: false,
			success: false,
		};
	}

	componentDidMount() {
		this._isMounted = true;
		if (this._isMounted) {
			this.props.setModule("My Profile");
			this.loadCurrentRow();
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	componentDidUpdate() {}

	loadCurrentRow() {
		this.props.showLoading();

		UserDataService.get(this.state.id)
			.then((response) => {
				this.setState(
					{
						id: response.data.Row.id,
						email: response.data.Row.email,
						name: response.data.Row.name,
					},
					function () {
						this.props.hideLoading();
					}
				);
			})
			.catch((e) => {
				console.log(e.response.data.error);
				this.setState({ message: e.response.data.error });
			});
	}

	onChangeEmail(e) {
		this.setState({ email: e.target.value });
	}
	onChangeName(e) {
		this.setState({ name: e.target.value });
	}
	onChangeOldPassword(e) {
		this.setState({ old_password: e.target.value });
	}
	onChangePassword(e) {
		this.setState({ password: e.target.value });
	}

	alertClose = () => {
		this.setState({ error: false });
	};

	saveProfile(e) {
		e.preventDefault();
		this.props.showLoading();

		this.setState({
			message: false,
			success: false,
		});

		this.props
			.updateProfile(
				this.state.id,
				this.state.email,
				this.state.name,
				this.state.old_password,
				this.state.password
			)
			.then((data) => {
				this.setState({
					success: data.message,
				});
				this.props.hideLoading();
			})
			.catch((e) => {
				this.setState({
					message: e.response.data.error,
					error: true,
				});
				this.props.hideLoading();
			});
	}

	render() {
		return (
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
						<Box component="form" onSubmit={(e) => this.saveProfile(e)}>
							<Typography variant="h5">User Form</Typography>

							<Grid container spacing={3}>
								{this.state.success && (
									<Grid item xs={12} sm={12}>
										<Alert severity="success">{this.state.success}</Alert>
									</Grid>
								)}

								<Grid item xs={12} sm={12}>
									<Dialog
										open={this.state.error}
										onClose={this.alertClose}
										aria-labelledby="error-dialog-title"
										aria-describedby="error-dialog-description"
									>
										<DialogTitle id="error-dialog-title">Failed</DialogTitle>
										<DialogContent>
											<DialogContentText id="error-dialog-description">
												{this.state.message}
											</DialogContentText>
										</DialogContent>
										<DialogActions>
											<Button onClick={this.alertClose} autoFocus>
												Close
											</Button>
										</DialogActions>
									</Dialog>
								</Grid>

								<Grid item xs={12} sm={6}>
									<TextField
										label="Email"
										value={this.state.email}
										onChange={(e) => this.onChangeEmail(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={6}>
									<TextField
										required
										label="Name"
										value={this.state.name}
										onChange={(e) => this.onChangeName(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={6}>
									<TextField
										label="Old Password"
										helperText="leave it blank if you don't want to change old password"
										value={this.state.old_password}
										onChange={(e) => this.onChangeOldPassword(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={6}>
									<TextField
										label="Password"
										helperText=""
										value={this.state.password}
										onChange={(e) => this.onChangePassword(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={12}>
									<Button
										type="submit"
										variant="contained"
										disabled={this.props.loading.loading}
										startIcon={<SaveIcon />}
									>
										{this.props.loading.loading && (
											<span>
												<span className="spinner-border spinner-border-sm"></span>
												&nbsp;
											</span>
										)}
										<span>
											{" "}
											{this.props.loading.loading ? "Please Wait" : "Save"}
										</span>
									</Button>
								</Grid>
							</Grid>
						</Box>
					</Paper>
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
		loading: state.loading,
	};
};

export default connect(mapStateToProps, {
	updateProfile,
	showLoading,
	hideLoading,
	setModule,
})(UpdateProfile);
