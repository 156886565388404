import React, { useState, useEffect } from "react";
import TutorialDataService from "../../services/TutorialService";
import Form from "react-validation/build/form";
import utils from "../../common/utils";

const Tutorial = props => {

    const initialTutorialState = {
        id: null,
        title: "",
        description: "",
        published: false
    };

    const params = utils.parseParams(props.location.search);
    

    const [currentTutorial, setCurrentTutorial] = useState(initialTutorialState);
    const [message, setMessage] = useState("");

    const getTutorial = id => {
        TutorialDataService.get(id)
        .then(response => {
            setCurrentTutorial(response.data);
        })
        .catch(e => {
            if (e.response.data === "forbidden") props.history.push("/login");
        });
    };

    useEffect(() => {

        getTutorial(props.match.params.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.match.params.id]);

    const handleInputChange = event => {
        const { name, value } = event.target;
        setCurrentTutorial({ ...currentTutorial, [name]: value });
    };

    const updatePublished = status => {
        var data = {
            id: currentTutorial.id,
            title: currentTutorial.title,
            description: currentTutorial.description,
            published: status
        };

        TutorialDataService.update(currentTutorial.id, data)
        .then(response => {
            setCurrentTutorial({ ...currentTutorial, published: status });
            //console.log(response.data);
        })
        .catch(e => {
            console.log(e);
        });
    };

    const updateTutorial = (e) => {
        e.preventDefault();
        TutorialDataService.update(currentTutorial.id, currentTutorial)
        .then(response => {
            //console.log(response.data);
            setMessage("The tutorial was updated successfully!");
            console.log(params);
            //props.history.push("/tutorials");
        })
        .catch(e => {
            console.log(e);
        });
    };

    const deleteTutorial = () => {
        TutorialDataService.remove(currentTutorial.id)
        .then(response => {
            //console.log(response.data);
            props.history.push("/tutorials");
        })
        .catch(e => {
            console.log(e);
        });
    };

    return (
    <div>
    {currentTutorial ? (

        <><Form onSubmit={updateTutorial}>
                    <div className="edit-form">
                        <h4>Tutorial</h4>

                        <div className="form-group">
                            <label htmlFor="title">Title</label>
                            <input
                                type="text"
                                className="form-control"
                                id="title"
                                name="title"
                                value={currentTutorial.title}
                                onChange={handleInputChange} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="description">Description</label>
                            <input
                                type="text"
                                className="form-control"
                                id="description"
                                name="description"
                                value={currentTutorial.description}
                                onChange={handleInputChange} />
                        </div>

                        <div className="form-group">
                            <label>
                                <strong>Status:</strong>
                            </label>
                            {currentTutorial.published ? "Published" : "Pending"}
                        </div>

                        <button
                            type="submit"
                            className="badge badge-success"
                            onClick={updateTutorial}
                        >
                            Update
                        </button>
                        <p>{message}</p>
                    </div>
                </Form>


                {currentTutorial.published ? (
                    <button
                        className="badge badge-primary mr-2"
                        onClick={() => updatePublished(false)}
                    >
                        UnPublish
                    </button>
                ) : (
                    <button
                        className="badge badge-primary mr-2"
                        onClick={() => updatePublished(true)}
                    >
                        Publish
                    </button>
                )}
                
                <button 
                    className="badge badge-danger mr-2" 
                    onClick={deleteTutorial}
                >
                    Delete
                </button>
                    
            </>

        ) : 
        (
            <div>
                <br />
                <p>Please click on a Tutorial...</p>
            </div>
        )}
    </div>
    );
};

export default Tutorial;