import React, { useState } from "react";
import TutorialDataService from "../../services/TutorialService";
import Form from "react-validation/build/form";

const AddTutorial = (props) => {

    const initialTutorialState = {
        id: null,
        title: "",
        description: "",
        published: false
    };

    const [tutorial, setTutorial] = useState(initialTutorialState);
    const [submitted, setSubmitted] = useState(false);

    const handleInputChange = event => {
        const { name, value } = event.target;
        setTutorial({ ...tutorial, [name]: value });
    };

    const saveTutorial = (e) => {
        e.preventDefault();
        var data = {
            title: tutorial.title,
            description: tutorial.description
        };

        TutorialDataService.create(data).then(response => {
            setTutorial({
                id: response.data.id,
                title: response.data.title,
                description: response.data.description,
                published: response.data.published
            });

            setSubmitted(true);
            props.history.push("/tutorials");
        })
        .catch(e => {
            if (e.response.data === "forbidden") props.history.push("/login");
        });
    };

    const newTutorial = () => {
        setTutorial(initialTutorialState);
        setSubmitted(false);
    };

    return (
        <Form onSubmit={saveTutorial}>
            <div className="submit-form">
                {submitted ? (
                    <div>
                        <h4>You submitted successfully!</h4>
                        <button className="btn btn-success" onClick={newTutorial}>Add </button>
                    </div>
                ) : 
                (
                    <div>
                        <div className="form-group">
                            <label htmlFor="title">Title</label>
                            <input
                            type="text"
                            className="form-control"
                            id="title"
                            required
                            value={tutorial.title}
                            onChange={handleInputChange}
                            name="title"
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="description">Description</label>
                            <input
                            type="text"
                            className="form-control"
                            id="description"
                            required
                            value={tutorial.description}
                            onChange={handleInputChange}
                            name="description"
                            />
                        </div>

                        <button onClick={saveTutorial} className="btn btn-success">Submit</button>
                    </div>
                )}
            </div>
        </Form>
    );
};

export default AddTutorial;