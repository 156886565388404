import React, { Component } from "react";
import { connect } from "react-redux";
import { createTutorial } from "../../actions/tutorials";
import Form from "react-validation/build/form";
import TutorialDataService from "../../services/tutorial.service";
import { Link } from "react-router-dom";


class AddTutorial2 extends Component {
    constructor(props) {
        super(props);
        this.onChangeTitle = this.onChangeTitle.bind(this);
        this.onChangeDescription = this.onChangeDescription.bind(this);
        this.saveTutorial = this.saveTutorial.bind(this);
        this.selectFile = this.selectFile.bind(this);
        this.newData = this.newData.bind(this);
        this.loadCurrentRow = this.loadCurrentRow.bind(this);
        this.onChangeText = this.onChangeText.bind(this);

        this.state = {
            id: null,
            title: "",
            description: "",
            loading: false,
            message: false,
            image: false,
            image_thumb: false,
            text: "",
            textSource: ""
        };
    }

    modules = {
        toolbar: [
            [{ font: [] }, { size: [] }],
            [{ align: [] }, 'direction'],
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ color: [] }, { background: [] }],
            [{ script: 'super' }, { script: 'sub' }],
            ['blockquote', 'code-block'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image', 'video'],
            ['clean']
        ],
    }

    formats = [
        'font', 'size',
        'align', 'direction',
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'color', 'background',
        'script', 'sub',
        'blockquote', 'code-block',
        'list', 'bullet', 'indent',
        'link', 'image', 'video',
        'clean'
    ]

    componentDidMount() {

        if (typeof (this.props.match.params.id) !== 'undefined') {
            this.loadCurrentRow();
        }
    }

    componentDidUpdate() {

        if (typeof (this.props.match.params.id) === 'undefined') {
            if (this.state.id !== null) {
                this.newData();
            }
        }
    }

    loadCurrentRow() {
        this.setState({ loading: true }, function () {
            TutorialDataService.get(this.props.match.params.id).then((response) => {
                this.setState({
                    id: response.data.id,
                    title: response.data.title,
                    description: response.data.description,
                    image_thumb: response.data.image_thumb,
                    text: response.data.content_html,
                    textSource: response.data.content_html,
                    loading: false
                });
            })
                .catch((e) => {
                    this.setState({ message: e.response.data });
                });
        });
    }

    newData() {

        this.setState({
            id: null,
            title: "",
            description: "",
            loading: false,
            message: false,
            image: false,
            image_thumb: false,
            text: ""
        });
    }

    onChangeText(e) {
        this.setState({
            text: e
        });
    }

    onChangeTitle(e) {
        this.setState({
            title: e.target.value,
        });
    }

    onChangeDescription(e) {
        this.setState({
            description: e.target.value,
        });
    }

    selectFile(e) {
        this.setState({
            image: e.target.files[0]
        });
    }



    saveTutorial(e) {

        e.preventDefault();

        this.setState({
            loading: true,
            message: false
        });

        const { id, title, description, image, text } = this.state;

        this.props.createTutorial(id, title, description, image, text).then((data) => {

            this.props.history.push("/tutorials2");
            /*this.setState({
                id: data.id,
                title: data.title,
                description: data.description,
                published: data.published,
                loading: false,
                submitted: true,
            });*/
            //console.log(data);
        })
            .catch((e) => {
                this.setState({
                    loading: false,
                    message: e.response.data
                });
            });
    }

    render() {
        return (
            <div className="col-md-12">
                <Form onSubmit={this.saveTutorial}>

                    <div>
                        {this.state.message && (
                            <div className="form-group">
                                <div className="alert alert-danger" role="alert">
                                    {this.state.message}
                                </div>
                            </div>
                        )}

                        {this.state.image_thumb && (


                            <img alt={this.state.title} src={this.state.image_thumb} />
                        )}



                        <div className="form-group">
                            <label htmlFor="image">Title</label>
                            <input type="file" id="image" name="image" onChange={this.selectFile} />
                        </div>

                        <div className="form-group">
                            <label htmlFor="title">Title</label>
                            <input
                                type="text"
                                className="form-control"
                                id="title"
                                required
                                value={this.state.title}
                                onChange={this.onChangeTitle}
                                name="title"
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="description">Description</label>
                            <input
                                type="text"
                                className="form-control"
                                id="description"
                                required
                                value={this.state.description}
                                onChange={this.onChangeDescription}
                                name="description"
                            />
                        </div>



                        <div className="form-group">

                            <Link
                                to={"/tutorials2/"}
                                className="btn btn-primary btn-block"
                            >
                                Cancel
                            </Link>

                            <button type="submit" className="btn btn-primary btn-block" disabled={this.state.loading}>
                                {this.state.loading && (
                                    <span>
                                        <span className="spinner-border spinner-border-sm"></span>
                                        &nbsp;
                                    </span>
                                )}
                                <span> {this.state.loading ? ("Please Wait") : ("Save")}</span>
                            </button>
                        </div>

                    </div>

                </Form>
            </div>
        );
    }
}

export default connect(null, { createTutorial })(AddTutorial2);