import {
	CURRENT_PAGE_TUTORIALS,
	CURRENT_PAGESIZE_TUTORIALS,
	CURRENT_PAGE,
	CURRENT_PAGESIZE,
	CURRENT_ORDER,
	CURRENT_ORDERBY,
	CURRENT_MODULE,
	CURRENT_DATEFROM,
	CURRENT_DATETO,
	CURRENT_MONTH,
	CURRENT_YEAR,
	CURRENT_LOOK,
	CURRENT_PERIOD,
	CURRENT_ACCOUNT,
} from "../actions/types";

const initialState = {
	currentPage: 1,
	currentPageSize: 20,
	currentOrder: "asc",
	currentOrderBy: "",
	module: "dashboard",
	dateFrom: "",
	dateTo: "",
	account: 0,
};

const a = function (state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case CURRENT_PAGE_TUTORIALS:
			return {
				...state,
				currentPage: payload,
			};
		case CURRENT_PAGESIZE_TUTORIALS:
			return {
				...state,
				currentPageSize: payload,
			};
		case CURRENT_PAGE:
			return {
				...state,
				currentPage: payload,
			};
		case CURRENT_PAGESIZE:
			return {
				...state,
				currentPageSize: payload,
			};
		case CURRENT_ORDER:
			return {
				...state,
				currentOrder: payload,
			};
		case CURRENT_ORDERBY:
			return {
				...state,
				currentOrderBy: payload,
			};
		case CURRENT_MODULE:
			return {
				...state,
				module: payload,
			};
		case CURRENT_DATEFROM:
			return {
				...state,
				dateFrom: payload,
			};
		case CURRENT_DATETO:
			return {
				...state,
				dateTo: payload,
			};
		case CURRENT_MONTH:
			return {
				...state,
				dateMonth: payload,
			};
		case CURRENT_YEAR:
			return {
				...state,
				dateYear: payload,
			};
		case CURRENT_LOOK:
			return {
				...state,
				look: payload,
			};
		case CURRENT_PERIOD:
			return {
				...state,
				period: payload,
			};
		case CURRENT_ACCOUNT:
			return {
				...state,
				account: payload,
			};
		default:
			return state;
	}
};

export default a;
