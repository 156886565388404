import React, { Component } from "react";
import { connect } from "react-redux";
import { createCompany } from "../../actions/company";
import { setModule } from "../../actions/common";
import { showLoading, hideLoading } from "../../actions/loading";
import CompanyDataService from "../../services/company.service";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { format } from "date-fns";
import SaveIcon from "@mui/icons-material/Save";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

class AddCompany extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			id: null,
			name: "",
			address: "",
			phone: "",
			fax: "",
			email: "",
			company_no: "",
			vat_no: "",
			financial_start: format(new Date(), "yyyy-01-01"),
			financial_end: format(new Date(), "yyyy-12-31"),
			last_financial_start: "",
			last_financial_end: "",
			active: true,

			error: false,
			message: false,
		};
	}

	componentDidMount() {
		this._isMounted = true;
		if (this._isMounted) {
			this.props.setModule("Company");
			if (typeof this.props.match.params.id !== "undefined") {
				this.loadCurrentRow();
			} /*else {
				let name = new URLSearchParams(this.props.location.search).get("name");
				let note = new URLSearchParams(this.props.location.search).get("note");

				this.setState({
					name: name,
					company_no: note,
				});
			}*/
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	componentDidUpdate() {
		if (typeof this.props.match.params.id === "undefined") {
			if (this.state.id !== null) {
				this.newData();
			}
		}
	}

	loadCurrentRow() {
		this.props.showLoading();

		CompanyDataService.get(this.props.match.params.id)
			.then((response) => {
				this.setState(
					{
						id: response.data.Row.id,
						name: response.data.Row.name,
						address: response.data.Row.address,
						phone: response.data.Row.phone,
						fax: response.data.Row.fax,
						email: response.data.Row.email,
						company_no: response.data.Row.company_no,
						vat_no: response.data.Row.vat_no,
						financial_start: response.data.Row.financial_start,
						financial_end: response.data.Row.financial_end,
						last_financial_start: response.data.Row.financial_start,
						last_financial_end: response.data.Row.financial_end,
						active: response.data.Row.active,
					},
					function () {
						this.props.hideLoading();
					}
				);
			})
			.catch((e) => {
				this.setState({ message: e.response.data.error });
			});
	}

	newData() {
		this.setState({
			id: null,
			name: "",
			address: "",
			phone: "",
			fax: "",
			email: "",
			company_no: "",
			vat_no: "",
			financial_start: format(new Date(), "yyyy-01-01"),
			financial_end: format(new Date(), "yyyy-12-31"),
			last_financial_start: "",
			last_financial_end: "",
			active: true,

			message: false,
		});
	}

	onChangeName(e) {
		this.setState({ name: e.target.value });
	}
	onChangeAddress(e) {
		this.setState({ address: e.target.value });
	}
	onChangePhone(e) {
		this.setState({ phone: e.target.value });
	}
	onChangeFax(e) {
		this.setState({ fax: e.target.value });
	}
	onChangeEmail(e) {
		this.setState({ email: e.target.value });
	}
	onChangeCompanyNo(e) {
		this.setState({ company_no: e.target.value });
	}
	onChangeVatNo(e) {
		this.setState({ vat_no: e.target.value });
	}
	onChangeFinancialStart(e) {
		this.setState({ financial_start: e.target.value });
	}
	onChangeFinancialEnd(e) {
		this.setState({ financial_end: e.target.value });
	}
	onChangeActive(e) {
		this.setState({ active: e.target.value });
	}

	alertClose = () => {
		this.setState({ error: false });
	};

	saveCompany(e) {
		e.preventDefault();
		this.props.showLoading();

		this.setState({
			message: false,
		});

		this.props
			.createCompany(
				this.state.id,
				this.state.name,
				this.state.address,
				this.state.phone,
				this.state.fax,
				this.state.email,
				this.state.company_no,
				this.state.vat_no,
				this.state.financial_start,
				this.state.financial_end,
				this.state.last_financial_start,
				this.state.last_financial_end,
				this.state.active
			)
			.then((data) => {
				this.props.history.push("/company/form");
			})
			.catch((e) => {
				this.setState({
					message: e.response.data.error,
					error: true,
				});
				this.props.hideLoading();
			});
	}

	render() {
		return (
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
						<Box component="form" onSubmit={(e) => this.saveCompany(e)}>
							<Typography variant="h5">Company Form</Typography>

							<Grid container spacing={3}>
								<Grid item xs={12} sm={12}>
									<Dialog
										open={this.state.error}
										onClose={this.alertClose}
										aria-labelledby="error-dialog-title"
										aria-describedby="error-dialog-description"
									>
										<DialogTitle id="error-dialog-title">Failed</DialogTitle>
										<DialogContent>
											<DialogContentText id="error-dialog-description">
												{this.state.message}
											</DialogContentText>
										</DialogContent>
										<DialogActions>
											<Button onClick={this.alertClose} autoFocus>
												Close
											</Button>
										</DialogActions>
									</Dialog>
								</Grid>

								<Grid item xs={12} sm={6}>
									<TextField
										required
										label="Name"
										value={this.state.name}
										onChange={(e) => this.onChangeName(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={6}>
									<TextField
										label="Address"
										fullWidth
										multiline
										rows={2}
										value={this.state.address}
										onChange={(e) => this.onChangeAddress(e)}
									/>
								</Grid>

								<Grid item xs={12} sm={3}>
									<TextField
										label="Phone"
										value={this.state.phone}
										onChange={(e) => this.onChangePhone(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>
								<Grid item xs={12} sm={3}>
									<TextField
										label="Fax"
										value={this.state.fax}
										onChange={(e) => this.onChangeFax(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>
								<Grid item xs={12} sm={3}>
									<TextField
										label="Email"
										value={this.state.email}
										onChange={(e) => this.onChangeEmail(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>
								<Grid item xs={12} sm={3}>
									<TextField
										label="Company No"
										value={this.state.company_no}
										onChange={(e) => this.onChangeCompanyNo(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={3}>
									<TextField
										label="Financial Start Date"
										type="date"
										value={this.state.financial_start}
										onChange={(e) => this.onChangeFinancialStart(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={3}>
									<TextField
										label="Financial End Date"
										type="date"
										value={this.state.financial_end}
										onChange={(e) => this.onChangeFinancialEnd(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={3}>
									<TextField
										label="VAT No"
										value={this.state.vat_no}
										onChange={(e) => this.onChangeVatNo(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={3}>
									<FormControl>
										<FormLabel>Active</FormLabel>
										<RadioGroup row>
											<FormControlLabel
												control={<Radio />}
												label="Active"
												value={true}
												checked={
													this.state.active === "true" ||
													this.state.active === true
												}
												onChange={(e) => this.onChangeActive(e)}
											/>

											<FormControlLabel
												control={<Radio />}
												label="Inactive"
												value={false}
												checked={
													this.state.active === "false" ||
													this.state.active === false
												}
												onChange={(e) => this.onChangeActive(e)}
											/>
										</RadioGroup>
									</FormControl>
								</Grid>

								<Grid item xs={12} sm={12}>
									<Button
										variant="contained"
										component={Link}
										to="/company/form"
										startIcon={<ArrowBackIcon />}
										sx={{ mr: 1 }}
									>
										Cancel
									</Button>
									<Button
										type="submit"
										variant="contained"
										disabled={this.props.loading.loading}
										startIcon={<SaveIcon />}
									>
										{this.props.loading.loading && (
											<span>
												<span className="spinner-border spinner-border-sm"></span>
												&nbsp;
											</span>
										)}
										<span>
											{" "}
											{this.props.loading.loading ? "Please Wait" : "Save"}
										</span>
									</Button>
								</Grid>
							</Grid>
						</Box>
					</Paper>
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
		loading: state.loading,
	};
};

export default connect(mapStateToProps, {
	createCompany,
	showLoading,
	hideLoading,
	setModule,
})(AddCompany);
