import axios from "axios";

//import http from "../http-common";
import http_fas from "../http-fas";

const API_URL = "https://vwxyz.raywhite.co.id/";

const register = (username, email, password) => {
	return axios.post(API_URL + "signup", {
		username,
		email,
		password,
	});
};

/*
const login = (username, password) => {
  return axios
    .post(API_URL + "signin", {
      username,
      password,
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};
*/
const login = (Email, Password) => {
	return http_fas
		.post("/signin", {
			Email,
			Password,
		})
		.then((response) => {
			if (response.data.token) {
				localStorage.setItem("user", JSON.stringify(response.data));
			}

			return response.data;
		});
};

const logout = () => {
	localStorage.removeItem("user");
};

const aName = {
	register,
	login,
	logout,
};
export default aName;
