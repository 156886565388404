import {
	CREATE_RECEIVE_MONEY,
	RETRIEVE_RECEIVE_MONEY,
	DELETE_RECEIVE_MONEY,
} from "../actions/types";

const initialState = {};

const receiveMoneyReducer = function (receiveMoney = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case CREATE_RECEIVE_MONEY:
			return payload;

		case RETRIEVE_RECEIVE_MONEY:
			return payload;

		case DELETE_RECEIVE_MONEY:
			return payload;

		default:
			return receiveMoney;
	}
};

export default receiveMoneyReducer;
