import {
	CREATE_INVOICE,
	RETRIEVE_INVOICE,
	DELETE_INVOICE,
} from "../actions/types";

const initialState = {};

const invoiceReducer = function (invoice = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case CREATE_INVOICE:
			return payload;

		case RETRIEVE_INVOICE:
			return payload;

		case DELETE_INVOICE:
			return payload;

		default:
			return invoice;
	}
};

export default invoiceReducer;
