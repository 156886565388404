import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setModule } from "../actions/common";

import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";

import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";

import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";

import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";

import BalanceIcon from "@mui/icons-material/Balance";
import MoveDownIcon from "@mui/icons-material/MoveDown";

import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ListIcon from "@mui/icons-material/List";
import AddCardIcon from "@mui/icons-material/AddCard";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import SearchIcon from "@mui/icons-material/Search";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ListAltIcon from "@mui/icons-material/ListAlt";
import BarChartIcon from "@mui/icons-material/BarChart";
import PeopleIcon from "@mui/icons-material/People";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CalculateIcon from "@mui/icons-material/Calculate";
import PaymentIcon from "@mui/icons-material/Payment";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const Home = (props) => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(setModule("Dashboard"));
	}, [dispatch]);

	return (
		<Grid container spacing={3}>
			<Grid item xs={12} sm={4}>
				<Card>
					<CardContent sx={{ m: "0px", p: "0px", minHeight: 250 }}>
						<List
							dense={true}
							subheader={<ListSubheader>Accounts</ListSubheader>}
						>
							<ListItemButton
								selected={module === "Account"}
								component={Link}
								to="/account/main"
							>
								<ListItemIcon>
									<AccountBalanceIcon />
								</ListItemIcon>
								<ListItemText primary="Account List" />
							</ListItemButton>
							<ListItemButton
								selected={module === "Transfer Money"}
								component={Link}
								to="/addtransfermoney"
							>
								<ListItemIcon>
									<MoveDownIcon />
								</ListItemIcon>
								<ListItemText primary="Transfer Money" />
							</ListItemButton>

							<ListItemButton
								selected={module === "Journal"}
								component={Link}
								to="/addjournal"
							>
								<ListItemIcon>
									<BalanceIcon />
								</ListItemIcon>
								<ListItemText primary="Record Journal Entry" />
							</ListItemButton>
						</List>
					</CardContent>
				</Card>
			</Grid>

			<Grid item xs={12} sm={4}>
				<Card>
					<CardContent sx={{ m: "0px", p: "0px", minHeight: 250 }}>
						<List
							dense={true}
							subheader={<ListSubheader>Banking</ListSubheader>}
						>
							<ListItemButton
								selected={module === "Bank Register"}
								component={Link}
								to="/bankregister/main"
							>
								<ListItemIcon>
									<ListIcon />
								</ListItemIcon>
								<ListItemText primary="Bank Register" />
							</ListItemButton>

							<ListItemButton
								selected={module === "Spend Money"}
								component={Link}
								to="/addspendmoney"
							>
								<ListItemIcon>
									<ShoppingCartIcon />
								</ListItemIcon>
								<ListItemText primary="Spend Money" />
							</ListItemButton>

							<ListItemButton
								selected={module === "Receive Money"}
								component={Link}
								to="/addreceivemoney"
							>
								<ListItemIcon>
									<AddCardIcon />
								</ListItemIcon>
								<ListItemText primary="Receive Money" />
							</ListItemButton>
						</List>
					</CardContent>
				</Card>
			</Grid>

			<Grid item xs={12} sm={4}>
				<Card>
					<CardContent sx={{ m: "0px", p: "0px", minHeight: 250 }}>
						<List dense={true} subheader={<ListSubheader>Sales</ListSubheader>}>
							<ListItemButton
								selected={module === "Sales Register"}
								component={Link}
								to="/salesregister/main"
							>
								<ListItemIcon>
									<ListIcon />
								</ListItemIcon>
								<ListItemText primary="Sales Register" />
							</ListItemButton>

							<ListItemButton
								selected={module === "Order"}
								component={Link}
								to="/addorder"
							>
								<ListItemIcon>
									<ReceiptLongIcon />
								</ListItemIcon>
								<ListItemText primary="Order" />
							</ListItemButton>

							<ListItemButton
								selected={module === "Invoice"}
								component={Link}
								to="/addinvoice"
							>
								<ListItemIcon>
									<ReceiptIcon />
								</ListItemIcon>
								<ListItemText primary="Invoice" />
							</ListItemButton>

							<ListItemButton
								selected={module === "Receive Payment"}
								component={Link}
								to="/addreceivepayment"
							>
								<ListItemIcon>
									<AddCardIcon />
								</ListItemIcon>
								<ListItemText primary="Receive Payment" />
							</ListItemButton>
						</List>
					</CardContent>
				</Card>
			</Grid>

			<Grid item xs={12} sm={4}>
				<Card>
					<CardContent sx={{ m: "0px", p: "0px", minHeight: 250 }}>
						<List
							dense={true}
							subheader={<ListSubheader>Purchases</ListSubheader>}
						>
							<ListItemButton
								selected={module === "Purchases Register"}
								component={Link}
								to="/purchasesregister/main"
							>
								<ListItemIcon>
									<ListIcon />
								</ListItemIcon>
								<ListItemText primary="Purchases Register" />
							</ListItemButton>

							<ListItemButton
								selected={module === "Purchase Order"}
								component={Link}
								to="/addpurchaseorder"
							>
								<ListItemIcon>
									<ReceiptLongIcon />
								</ListItemIcon>
								<ListItemText primary="Purchase Order" />
							</ListItemButton>

							<ListItemButton
								selected={module === "Bill"}
								component={Link}
								to="/addbill"
							>
								<ListItemIcon>
									<ReceiptIcon />
								</ListItemIcon>
								<ListItemText primary="Bill" />
							</ListItemButton>

							<ListItemButton
								selected={module === "Pay Bill"}
								component={Link}
								to="/paybill/main"
							>
								<ListItemIcon>
									<AddCardIcon />
								</ListItemIcon>
								<ListItemText primary="Payed Bill" />
							</ListItemButton>
						</List>
					</CardContent>
				</Card>
			</Grid>

			<Grid item xs={12} sm={4}>
				<Card>
					<CardContent sx={{ m: "0px", p: "0px", minHeight: 20 }}>
						<List
							dense={true}
							subheader={<ListSubheader>Report</ListSubheader>}
						>
							<ListItemButton
								selected={module === "Transaction Journal"}
								component={Link}
								to="/transactionjournal"
							>
								<ListItemIcon>
									<BalanceIcon />
								</ListItemIcon>
								<ListItemText primary="Transaction Journal" />
							</ListItemButton>

							<ListItemButton
								selected={module === "Find Transactions"}
								component={Link}
								to="/findtransactions"
							>
								<ListItemIcon>
									<SearchIcon />
								</ListItemIcon>
								<ListItemText primary="Find Transactions" />
							</ListItemButton>
							<ListItemButton
								selected={module === "General Ledger Detail"}
								component={Link}
								to="/generalledgerdetail"
							>
								<ListItemIcon>
									<AccountTreeIcon />
								</ListItemIcon>
								<ListItemText primary="General Ledger Detail" />
							</ListItemButton>
							<ListItemButton
								selected={module === "GL Detail Consolidate"}
								component={Link}
								to="/gldetailconsolidate"
							>
								<ListItemIcon>
									<AccountTreeIcon />
								</ListItemIcon>
								<ListItemText primary="GL Detail Consolidate" />
							</ListItemButton>
							<ListItemButton
								selected={module === "Standard Balance Sheet"}
								component={Link}
								to="/standardbalancesheet"
							>
								<ListItemIcon>
									<ListAltIcon />
								</ListItemIcon>
								<ListItemText primary="Standard Balance Sheet" />
							</ListItemButton>

							<ListItemButton
								selected={module === "BS Consolidate"}
								component={Link}
								to="/standardbalancesheetconsolidate"
							>
								<ListItemIcon>
									<ListAltIcon />
								</ListItemIcon>
								<ListItemText primary="BS Consolidate" />
							</ListItemButton>

							
							<ListItemButton
								selected={module === "Profit Loss Year to Date"}
								component={Link}
								to="/profitlossytd"
							>
								<ListItemIcon>
									<BarChartIcon />
								</ListItemIcon>
								<ListItemText primary="Profit Loss YTD" />
							</ListItemButton>
							<ListItemButton
								selected={module === "Profit Loss Year to Date Consolidate"}
								component={Link}
								to="/profitlossytdconsolidate"
							>
								<ListItemIcon>
									<BarChartIcon />
								</ListItemIcon>
								<ListItemText primary="Profit Loss YTD (Consolidate)" />
							</ListItemButton>

							<ListItemButton
								selected={module === "Job Profit Loss"}
								component={Link}
								to="/jobprofitloss"
							>
								<ListItemIcon>
									<BarChartIcon />
								</ListItemIcon>
								<ListItemText primary="Job Profit Loss" />
							</ListItemButton>

							<ListItemButton
								selected={module === "Trial Balance"}
								component={Link}
								to="/trialbalance"
							>
								<ListItemIcon>
									<ListAltIcon />
								</ListItemIcon>
								<ListItemText primary="Trial Balance" />
							</ListItemButton>

							<ListItemButton
								selected={module === "Statement of Cash Flow"}
								component={Link}
								to="/statementofcashflow"
							>
								<ListItemIcon>
									<ListAltIcon />
								</ListItemIcon>
								<ListItemText primary="Statement of Cash Flow" />
							</ListItemButton>

							<ListItemButton
								selected={module === "Cash Flow Analysis"}
								component={Link}
								to="/cashflowanalysis"
							>
								<ListItemIcon>
									<ListAltIcon />
								</ListItemIcon>
								<ListItemText primary="Cash Flow Analysis" />
							</ListItemButton>
						</List>
					</CardContent>
				</Card>
			</Grid>

			<Grid item xs={12} sm={4}>
				<Card>
					<CardContent sx={{ m: "0px", p: "0px", minHeight: 250 }}>
						<List
							dense={true}
							subheader={<ListSubheader>Setting</ListSubheader>}
						>
							<ListItemButton
								selected={module === "Card"}
								component={Link}
								to="/card/main"
							>
								<ListItemIcon>
									<PeopleIcon />
								</ListItemIcon>
								<ListItemText primary="Card" />
							</ListItemButton>
							<ListItemButton
								selected={module === "Job"}
								component={Link}
								to="/job/main"
							>
								<ListItemIcon>
									<AssignmentIcon />
								</ListItemIcon>
								<ListItemText primary="Job" />
							</ListItemButton>
							<ListItemButton
								selected={module === "Tax"}
								component={Link}
								to="/tax/main"
							>
								<ListItemIcon>
									<CalculateIcon />
								</ListItemIcon>
								<ListItemText primary="Tax" />
							</ListItemButton>
							<ListItemButton
								selected={module === "Payment Method"}
								component={Link}
								to="/paymentmethod/main"
							>
								<ListItemIcon>
									<PaymentIcon />
								</ListItemIcon>
								<ListItemText primary="Payment Method" />
							</ListItemButton>

							<ListItemButton
								selected={module === "Linked Accounts"}
								component={Link}
								to="/linkedaccounts"
							>
								<ListItemIcon>
									<AccountCircleIcon />
								</ListItemIcon>
								<ListItemText primary="Linked Accounts" />
							</ListItemButton>
						</List>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};

export default Home;
