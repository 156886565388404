import axios from "axios";

const user = JSON.parse(localStorage.getItem("user"));
const token = user && user.token ? "Bearer " + user.token : "";

const http = axios.create({
	baseURL: "https://vwxyz.raywhite.co.id",
	headers: {
		"Content-type": "application/json",
		Authorization: token,
	},
});
/*
const httpMultipart = axios.create({
    baseURL: "https://vwxyz.raywhite.co.id",
    headers: {
        "Content-type": "multipart/form-data",
        "Authorization": token
    }
});

const req = {
    http,
    httpMultipart
};
*/
export default http;
