import axios from "axios";
import authHeader from "./auth-header";

const API_URL = "https://vwxyz.raywhite.co.id/test/";

const getPublicContent = () => {
  return axios.get(API_URL + "all");
};

const getUserBoard = () => {
  return axios.get(API_URL + "user", { headers: authHeader() });
};

const getModeratorBoard = () => {
  return axios.get(API_URL + "mod", { headers: authHeader() });
};

const getAdminBoard = () => {
  return axios.get(API_URL + "admin", { headers: authHeader() });
};



const aName = {
    getPublicContent,
  getUserBoard,
  getModeratorBoard,
  getAdminBoard
};
export default aName;