import React, { Component } from "react";
import { connect } from "react-redux";
import { retrieveJournal, deleteJournal } from "../../actions/journal";
import {
	setCurrentPage,
	setCurrentPageSize,
	setCurrentOrder,
	setCurrentOrderBy,
	setModule,
} from "../../actions/common";
import { showLoading, hideLoading } from "../../actions/loading";
import { Link } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { parseDate } from "../../helpers/date";
//import CheckIcon from "@mui/icons-material/Check";
//import CloseIcon from "@mui/icons-material/Close";

class JournalList extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			searchNote: "",
			searchCode: "",
			count: 0,
			alert: false,
			error: false,
			delID: 0,
			delTitle: "",
			message: false,
		};
	}

	pageSizes = [5, 10, 20, 30, 50];
	sorts = [
		{
			label: "Date",
			value: "date",
		},
		{
			label: "Note",
			value: "note",
		},
		{
			label: "Code",
			value: "code",
		},
		{
			label: "tax Inclusive",
			value: "tax_inclusive",
		},
	];

	orders = [
		{
			label: "Asc",
			value: "asc",
		},
		{
			label: "Desc",
			value: "desc",
		},
	];

	componentDidMount() {
		this._isMounted = true;
		if (this._isMounted) {
			this.props.setModule("Journal");
			if (this.props.match.params.source === "main") {
				this.props.setCurrentPage(1).then(() => {
					this.props.setCurrentPageSize(20).then(() => {
						this.props.setCurrentOrderBy("date").then(() => {
							this.props.setCurrentOrder("desc").then(() => {
								this.getRows();
							});
						});
					});
				});
			} else {
				this.getRows();
			}
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	getRows = () => {
		this.props.showLoading();
		var params = {
			note: this.state.searchNote,
			code: this.state.searchCode,
			s: this.props.common.currentOrderBy,
			d: this.props.common.currentOrder,
			page: this.props.common.currentPage,
			perpage: this.props.common.currentPageSize,
		};

		this.setState({ message: false }, function () {
			this.props
				.retrieveJournal(params)
				.then(() => {
					const totalPages = this.props.journal.total_pages;
					this.setState({
						count: totalPages,
					});
					this.props.hideLoading();
				})
				.catch((e) => {
					this.setState({
						error: true,
						message: e.response.data.error,
					});
					this.props.hideLoading();
				});
		});
	};

	handlePageSizeChange = (event) => {
		this.props.setCurrentPageSize(event.target.value).then(() => {
			this.props.setCurrentPage(1).then(() => {
				this.getRows();
			});
		});
	};

	handlePageChange = (event, value) => {
		this.props.setCurrentPage(value).then(() => {
			this.getRows();
		});
	};

	onChangeSearchNote(e) {
		this.setState({ searchNote: e.target.value });
	}
	onChangeSearchCode(e) {
		this.setState({ searchCode: e.target.value });
	}
	onChangeOrderBy(e) {
		this.props.setCurrentOrderBy(e.target.value);
	}
	onChangeOrder(e) {
		this.props.setCurrentOrder(e.target.value);
	}

	searchData = (e) => {
		e.preventDefault();
		this.props.setCurrentPage(1).then(() => {
			this.getRows();
		});
	};

	confirmDelete = (id, title) => {
		this.setState({
			alert: true,
			delID: id,
			delTitle: title,
		});
	};

	handleClose = (e) => {
		this.setState({
			alert: false,
			error: false,
		});
	};

	deleteRow = (e) => {
		this.props.showLoading();
		this.setState({ message: false }, function () {
			this.props
				.deleteJournal(this.state.delID)
				.then((response) => {
					this.setState({ alert: false }, function () {
						this.getRows();
					});
				})
				.catch((e) => {
					console.log(e.response.data.error);
					this.props.hideLoading();
					this.setState({
						alert: false,
						error: true,
						message: e.response.data.error,
					});
				});
		});
	};

	render() {
		return (
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
						<Box component="form" onSubmit={(e) => this.searchData(e)}>
							<Typography variant="h5">Search Form</Typography>

							<Grid container spacing={3}>
								<Grid item xs={12} sm={6}>
									<TextField
										label="Search by note"
										value={this.state.searchNote}
										onChange={(e) => this.onChangeSearchNote(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={6}>
									<TextField
										label="Search by code"
										value={this.state.searchCode}
										onChange={(e) => this.onChangeSearchCode(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={3}>
									<FormControl
										fullWidth={true}
										variant="standard"
										sx={{ minWidth: 100 }}
									>
										<InputLabel id="orderbylabel">Order by</InputLabel>
										<Select
											labelId="orderbylabel"
											label="Order By"
											value={this.props.common.currentOrderBy}
											onChange={(e) => this.onChangeOrderBy(e)}
										>
											{this.sorts.map((option, index) => (
												<MenuItem key={index} value={option.value}>
													{option.label}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>

								<Grid item xs={12} sm={3}>
									<FormControl
										fullWidth={true}
										variant="standard"
										sx={{ minWidth: 100 }}
									>
										<InputLabel id="orderlabel">Sort</InputLabel>
										<Select
											labelId="orderlabel"
											label="Sort"
											value={this.props.common.currentOrder}
											onChange={(e) => this.onChangeOrder(e)}
										>
											{this.orders.map((option, index) => (
												<MenuItem key={index} value={option.value}>
													{option.label}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12} sm={6}></Grid>

								<Grid item xs={6} sm={6}>
									<Button
										type="submit"
										variant="contained"
										startIcon={<SearchIcon />}
									>
										Search
									</Button>
								</Grid>
								<Grid item xs={6} sm={6} container justifyContent="flex-end">
									<Button
										variant="contained"
										component={Link}
										to="/addjournal"
										startIcon={<AddIcon />}
									>
										Create
									</Button>
								</Grid>
							</Grid>
						</Box>
					</Paper>
				</Grid>

				<Grid item xs={12}>
					<Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
						<Typography variant="h4" gutterBottom component="div">
							Journal
						</Typography>

						<TableContainer component={Paper}>
							<Table sx={{}} size="small">
								<TableHead>
									<TableRow>
										<TableCell width={5}>No.</TableCell>
										<TableCell align="center">Code</TableCell>
										<TableCell align="center">Date</TableCell>
										<TableCell align="center">Note</TableCell>
										<TableCell align="center">Actions</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{this.props.journal.Data &&
										this.props.journal.Data.map((row, index) => (
											<TableRow key={index}>
												<TableCell align="right">
													{this.props.journal.first_no + index}
												</TableCell>
												<TableCell>{row.code}</TableCell>
												<TableCell align="center">
													{parseDate(row.date)}
												</TableCell>
												<TableCell>{row.note}</TableCell>

												<TableCell align="center">
													<Link
														to={"/editjournal/" + row.id}
														className="badge badge-warning"
													>
														<EditIcon fontSize="small" />
													</Link>
													<DeleteIcon
														fontSize="small"
														onClick={() => this.confirmDelete(row.id, row.code)}
													/>
												</TableCell>
											</TableRow>
										))}
								</TableBody>

								<TableFooter>
									<TableRow>
										<TableCell colSpan={5}>
											<Grid container>
												<Grid item xs={12} sm={3}>
													<FormControl
														fullWidth={true}
														variant="standard"
														sx={{ minWidth: 100 }}
													>
														<InputLabel id="perpagelabel">
															Items per Page
														</InputLabel>
														<Select
															labelId="perpagelabel"
															label="Items per Page"
															value={this.props.common.currentPageSize}
															onChange={this.handlePageSizeChange}
														>
															{this.pageSizes.map((size, index) => (
																<MenuItem key={index} value={size}>
																	{size}
																</MenuItem>
															))}
														</Select>
													</FormControl>
												</Grid>
												<Grid item xs={12} sm={9} justifyContent="flex-end">
													<Grid container justifyContent="flex-end">
														<Stack spacing={2}>
															<Pagination
																count={this.state.count}
																page={this.props.common.currentPage}
																onChange={this.handlePageChange}
																color="primary"
																siblingCount={1}
																boundaryCount={1}
															/>
														</Stack>
													</Grid>
												</Grid>
											</Grid>
										</TableCell>
									</TableRow>
								</TableFooter>
							</Table>
						</TableContainer>

						<Dialog
							open={this.state.alert}
							onClose={this.handleClose}
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description"
						>
							<DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
							<DialogContent>
								<DialogContentText id="alert-dialog-description">
									Delete "{this.state.delTitle}" ?
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={this.handleClose} autoFocus>
									Cancel
								</Button>
								<Button onClick={this.deleteRow}>Yes</Button>
							</DialogActions>
						</Dialog>

						<Dialog
							open={this.state.error}
							onClose={this.handleClose}
							aria-labelledby="error-dialog-title"
							aria-describedby="error-dialog-description"
						>
							<DialogTitle id="error-dialog-title">Failed</DialogTitle>
							<DialogContent>
								<DialogContentText id="error-dialog-description">
									{this.state.message}
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={this.handleClose} autoFocus>
									Close
								</Button>
							</DialogActions>
						</Dialog>
					</Paper>
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		journal: state.journal,
		common: state.common,
		loading: state.loading,
	};
};

export default connect(mapStateToProps, {
	retrieveJournal,
	deleteJournal,
	setCurrentPage,
	setCurrentPageSize,
	setCurrentOrder,
	setCurrentOrderBy,
	setModule,
	showLoading,
	hideLoading,
})(JournalList);
