import {
	CREATE_SPEND_MONEY,
	RETRIEVE_SPEND_MONEY,
	DELETE_SPEND_MONEY,
	RETRIEVE_BANK_REGISTER,
	RETRIEVE_SALES_REGISTER,
	RETRIEVE_PURCHASES_REGISTER,
} from "./types";

import SpendMoneyDataService from "../services/spendMoney.service";
import { parseNumber } from "../helpers/common";

export const createSpendMoney =
	(
		id,
		id_company,
		id_card,
		code,
		date,
		note,
		from,
		amount,
		tax_inclusive,
		detail,
		recurring,
		recurring_name
	) =>
	async (dispatch) => {
		try {
			if (tax_inclusive === "true") {
				tax_inclusive = true;
			}
			if (tax_inclusive === "false") {
				tax_inclusive = false;
			}

			amount = parseFloat(amount);
			code = code.toString();
			id_card = parseNumber(id_card);

			let formData = {
				id: id,
				id_company: id_company,
				id_card: id_card,
				code: code,
				date: date,
				note: note,
				from: from,
				amount: amount,
				tax_inclusive: tax_inclusive,
				detail: detail,
				recurring: recurring,
				recurring_name: recurring_name,
			};

			const res = await SpendMoneyDataService.create(formData);

			dispatch({
				type: CREATE_SPEND_MONEY,
				payload: res.data,
			});

			return Promise.resolve(res.data);
		} catch (err) {
			return Promise.reject(err);
		}
	};

export const retrieveSpendMoney = (params) => async (dispatch) => {
	try {
		const res = await SpendMoneyDataService.getAll(params);

		dispatch({
			type: RETRIEVE_SPEND_MONEY,
			payload: res.data,
		});
	} catch (err) {
		return Promise.reject(err);
	}
};

export const retrieveBankRegister = (params) => async (dispatch) => {
	try {
		const res = await SpendMoneyDataService.getAllBankRegister(params);

		dispatch({
			type: RETRIEVE_BANK_REGISTER,
			payload: res.data,
		});
	} catch (err) {
		return Promise.reject(err);
	}
};

export const retrieveSalesRegister = (params) => async (dispatch) => {
	try {
		const res = await SpendMoneyDataService.getAllSalesRegister(params);

		dispatch({
			type: RETRIEVE_SALES_REGISTER,
			payload: res.data,
		});
	} catch (err) {
		return Promise.reject(err);
	}
};

export const retrievePurchasesRegister = (params) => async (dispatch) => {
	try {
		const res = await SpendMoneyDataService.getAllPurchasesRegister(params);

		dispatch({
			type: RETRIEVE_PURCHASES_REGISTER,
			payload: res.data,
		});
	} catch (err) {
		return Promise.reject(err);
	}
};

export const deleteSpendMoney = (id) => async (dispatch) => {
	try {
		const res = await SpendMoneyDataService.delete(id);

		dispatch({
			type: DELETE_SPEND_MONEY,
			payload: res.data,
		});
	} catch (err) {
		return Promise.reject(err);
	}
};
