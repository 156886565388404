import {
	CREATE_BILL,
	RETRIEVE_BILL,
	DELETE_BILL,
} from "../actions/types";

const initialState = {};

const billReducer = function (bill = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case CREATE_BILL:
			return payload;

		case RETRIEVE_BILL:
			return payload;

		case DELETE_BILL:
			return payload;

		default:
			return bill;
	}
};

export default billReducer;
