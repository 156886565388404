import {
    CREATE_TUTORIAL,
    RETRIEVE_TUTORIALS,
    UPDATE_TUTORIAL,
    DELETE_TUTORIAL,
    DELETE_ALL_TUTORIALS,
    CURRENT_PAGE_TUTORIALS,
    CURRENT_PAGESIZE_TUTORIALS
} from "./types";

import TutorialDataService from "../services/tutorial.service";

export const createTutorial = (id, title, description, image,text) => async (dispatch) => {
    try {

        let formData = new FormData();

        formData.append("id", id);
        formData.append("title", title);
        formData.append("description", description);
        formData.append("image", image);
        formData.append("text", text);

        const res = await TutorialDataService.create(formData);
        //console.log(res);

        dispatch({
        type: CREATE_TUTORIAL,
        payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const retrieveTutorials = (params) => async (dispatch) => {
    try {
        const res = await TutorialDataService.getAll(params);

        dispatch({
            type: RETRIEVE_TUTORIALS,
            payload: res.data,
        });
    } catch (err) {
        console.log(err);
    }
};

export const updateTutorial = (id, data) => async (dispatch) => {
    try {
        const res = await TutorialDataService.update(id, data);

        dispatch({
        type: UPDATE_TUTORIAL,
        payload: data,
        });

        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const deleteTutorial = (id) => async (dispatch) => {
    try {
        const res = await TutorialDataService.delete(id);

        dispatch({
            type: DELETE_TUTORIAL,
            payload: res.data,
        });
    } catch (err) {
        return Promise.reject(err);
    }
};

export const deleteAllTutorials = () => async (dispatch) => {
    try {
        const res = await TutorialDataService.deleteAll();

        dispatch({
        type: DELETE_ALL_TUTORIALS,
        payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const findTutorialsByTitle = (title) => async (dispatch) => {
    try {
        const res = await TutorialDataService.findByTitle(title);

        dispatch({
        type: RETRIEVE_TUTORIALS,
        payload: res.data,
        });
    } catch (err) {
        console.log(err);
    }
};



export const setCurrentPage = (value) => dispatch => {
    dispatch({
        type: CURRENT_PAGE_TUTORIALS,
        payload: value,
    });
    return Promise.resolve();
};

export const setCurrentPageSize = (value) => dispatch => {
    dispatch({
        type: CURRENT_PAGESIZE_TUTORIALS,
        payload: value,
    });
    return Promise.resolve();
};

