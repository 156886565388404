import {
	CREATE_ACCOUNT,
	RETRIEVE_ACCOUNT,
	DELETE_ACCOUNT,
} from "../actions/types";

const initialState = {};

const accountReducer = function (account = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case CREATE_ACCOUNT:
			return payload;

		case RETRIEVE_ACCOUNT:
			return payload;

		case DELETE_ACCOUNT:
			return payload;

		default:
			return account;
	}
};

export default accountReducer;
