import {
	CREATE_ACCOUNT,
	RETRIEVE_ACCOUNT,
	DELETE_ACCOUNT,
	UPDATE_LINKED_ACCOUNTS,
} from "./types";

import AccountDataService from "../services/account.service";
import { parseNumber } from "../helpers/common";

export const createAccount =
	(
		id,
		id_company,
		id_parent,
		id_type,
		header,
		code,
		name,
		note,
		id_tax,
		id_cash_flow,
		bank_account_number,
		bank_account_name,
		opening_balance,
		id_period
	) =>
	async (dispatch) => {
		try {
			if (header === "true") {
				header = true;
			}
			if (header === "false") {
				header = false;
			}

			id_cash_flow = parseNumber(id_cash_flow);

			let formData = {
				id: id,
				id_company: id_company,
				id_parent: id_parent,
				id_type: id_type,
				header: header,
				code: code,
				name: name,
				note: note,
				id_tax: id_tax,
				id_cash_flow: id_cash_flow,
				bank_account_number: bank_account_number,
				bank_account_name: bank_account_name,
				opening_balance: opening_balance,
				id_period: id_period,
			};

			const res = await AccountDataService.create(formData);

			dispatch({
				type: CREATE_ACCOUNT,
				payload: res.data,
			});

			return Promise.resolve(res.data);
		} catch (err) {
			return Promise.reject(err);
		}
	};

export const retrieveAccount = (params) => async (dispatch) => {
	try {
		const res = await AccountDataService.getAll(params);

		dispatch({
			type: RETRIEVE_ACCOUNT,
			payload: res.data,
		});
	} catch (err) {
		return Promise.reject(err);
	}
};

export const deleteAccount = (id, params) => async (dispatch) => {
	try {
		const res = await AccountDataService.delete(id, params);

		dispatch({
			type: DELETE_ACCOUNT,
			payload: res.data,
		});
	} catch (err) {
		return Promise.reject(err);
	}
};

export const updateLinkedAccounts =
	(
		tracking_receivables,
		tracking_payables,
		bank_customer_receipts,
		bank_paying_bills,
		historical_balancing,
		undeposited_funds,
		current_earnings,
		retained_earnings,
		id_company
	) =>
	async (dispatch) => {
		try {
			let formData = {
				tracking_receivables: tracking_receivables,
				tracking_payables: tracking_payables,
				bank_customer_receipts: bank_customer_receipts,
				bank_paying_bills: bank_paying_bills,
				historical_balancing: historical_balancing,
				undeposited_funds: undeposited_funds,
				current_earnings: current_earnings,
				retained_earnings: retained_earnings,
				id_company: id_company,
			};

			const res = await AccountDataService.updateLinkedAccounts(formData);

			dispatch({
				type: UPDATE_LINKED_ACCOUNTS,
				payload: res.data,
			});

			return Promise.resolve(res.data);
		} catch (err) {
			return Promise.reject(err);
		}
	};
