import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import TextField from "@mui/material/TextField";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { createCard } from "../../actions/card";
import CardDataService from "../../services/card.service";

export default function FormCard(props) {
	const [idType, setIdType] = useState(props.cardType);
	const [code, setCode] = useState("");
	const [name, setName] = useState("");

	const [cardTypes_ready, setCardTypes_ready] = useState(false);
	const [cardTypes, setCardTypes] = useState([]);

	const dispatch = useDispatch();

	useEffect(() => {
		CardDataService.getType().then((response) => {
			setCardTypes_ready(true);
			setCardTypes(response.data.Row);
		});
	}, [dispatch]);

	const saveCard = (e) => {
		let forceType = idType;

		if (props.cardType !== "") {
			forceType = props.cardType;
		}

		dispatch(
			createCard(
				0,
				props.id_company,
				forceType,
				"",
				name,
				code,
				"",
				"",
				"",
				"",
				"",
				"",
				"",
				"",
				0,
				true
			)
		)
			.then((data) => {
				props.setNewCard(data.Row.id, data.Row.name);
			})
			.catch((e) => {
				props.showError(e.response.data.error);
			});
	};

	return (
		<Dialog open={props.show} onClose={(e) => props.formCardClose(e)}>
			<DialogTitle>Insert New Card</DialogTitle>
			<DialogContent>
				<Box>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={12}>
							{cardTypes_ready && (
								<FormControl
									fullWidth={true}
									variant="standard"
									sx={{ minWidth: 100 }}
								>
									<InputLabel id="idType">Type</InputLabel>
									<Select
										labelId="idType"
										label="Type"
										value={idType}
										onChange={(e) => setIdType(e.target.value)}
									>
										<MenuItem value="0">
											<em>None</em>
										</MenuItem>
										{cardTypes.map((option, index) => (
											<MenuItem key={index} value={option.id}>
												{option.name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							)}
						</Grid>
						<Grid item xs={12} sm={12}>
							<TextField
								label="Code"
								value={code}
								onChange={(e) => setCode(e.target.value)}
								fullWidth
								variant="standard"
							/>
						</Grid>
						<Grid item xs={12} sm={12}>
							<TextField
								required
								label="Name"
								value={name}
								onChange={(e) => setName(e.target.value)}
								fullWidth
								variant="standard"
							/>
						</Grid>
					</Grid>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button onClick={(e) => props.formCardClose(e)}>Cancel</Button>
				<Button onClick={(e) => saveCard(e)}>Save</Button>
			</DialogActions>
		</Dialog>
	);
}
