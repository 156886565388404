import React, { useState, useEffect, useMemo, useRef } from "react";
import TutorialDataService from "../../services/TutorialService";
import Pagination from '@mui/material/Pagination';
import { useTable } from "react-table";
import Form from "react-validation/build/form";

const TutorialsList = (props) => {
    const [tutorials, setTutorials] = useState([]);
    const [searchTitle, setSearchTitle] = useState("");
    const tutorialsRef = useRef();
    const p = useRef();
    const ps = useRef();

    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [pageSize, setPageSize] = useState(3);

    const pageSizes = [3, 6, 9];

    tutorialsRef.current = tutorials;
    p.current = page;
    ps.current = pageSize;

    const onChangeSearchTitle = (e) => {
        const searchTitle = e.target.value;
        setSearchTitle(searchTitle);
    };

    const getRequestParams = (searchTitle, page, pageSize) => {
        let params = {};

        if (searchTitle) {
        params["title"] = searchTitle;
        }

        if (page) {
        params["page"] = page;
        }

        if (pageSize) {
        params["size"] = pageSize;
        }

        return params;
    };

    const retrieveTutorials = () => {
        const params = getRequestParams(searchTitle, page, pageSize);

        TutorialDataService.getAll(params)
        .then((response) => {
            console.log(response.data);
            const { rows, totalPages } = response.data;

            setTutorials(rows);
            setCount(totalPages);
        })
        .catch((e) => {
            if (e.response.data === "forbidden") props.history.push("/login");
        });
    };

    useEffect(
        retrieveTutorials
        // eslint-disable-next-line react-hooks/exhaustive-deps
    , [page, pageSize]);

    const refreshList = () => {
        retrieveTutorials();
    };

    const removeAllTutorials = () => {
        TutorialDataService.removeAll()
        .then((response) => {
            console.log(response.data);
            refreshList();
        })
        .catch((e) => {
            console.log(e);
        });
    };

    const findByTitle = (e) => {
        e.preventDefault();
        setPage(1);
        retrieveTutorials();
    };

    const openTutorial = (rowIndex) => {

        const id = tutorialsRef.current[rowIndex].id;
        props.history.push("/tutorials/" + id + "?page=" + p.current + "&pagesize=" + ps.current);
    };

    const deleteTutorial = (rowIndex) => {
        const id = tutorialsRef.current[rowIndex].id;

        TutorialDataService.remove(id)
        .then((response) => {
            props.history.push("/tutorials");

            let newTutorials = [...tutorialsRef.current];
            newTutorials.splice(rowIndex, 1);

            setTutorials(newTutorials);
        })
        .catch((e) => {
            console.log(e);
        });
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handlePageSizeChange = (event) => {
        setPageSize(event.target.value);
        setPage(1);
    };

    const columns = useMemo(() => [
        {
            Header: "Title",
            accessor: "title",
        },
        {
            Header: "Description",
            accessor: "description",
        },
        {
            Header: "Status",
            accessor: "published",
            Cell: (props) => {
                return props.value ? "Published" : "Pending";
            },
        },
        {
            Header: "Actions",
            accessor: "actions",
            Cell: (props) => {
                const rowIdx = props.row.id;
                return (
                    <div>
                        <span onClick={() => openTutorial(rowIdx)}>
                        edit <i className="far fa-edit action mr-2"></i>
                        </span>

                        <span onClick={() => deleteTutorial(rowIdx)}>
                        delete <i className="fas fa-trash action"></i>
                        </span>
                    </div>
                );
            },
        },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data: tutorials,
    });

    return (
        <div className="list row">
            <div className="col-md-8">
                <Form onSubmit={findByTitle}>
                    <div className="input-group mb-3">
                        <input
                        type="text"
                        className="form-control"
                        placeholder="Search by title"
                        value={searchTitle}
                        onChange={onChangeSearchTitle}
                        />
                        <div className="input-group-append">
                            <button
                            className="btn btn-outline-secondary"
                            type="button"
                            onClick={findByTitle}
                            >
                            Search
                            </button>
                        </div>
                    </div>
                </Form>
            </div>

            <div className="col-md-12 list">
                <div className="mt-3">
                    {"Items per Page: "}
                    <select onChange={handlePageSizeChange} value={pageSize}>
                        {pageSizes.map((size) => (
                        <option key={size} value={size}>
                        {size}
                        </option>
                        ))}
                    </select>

                    <Pagination
                        className="my-3"
                        count={count}
                        page={page}
                        siblingCount={1}
                        boundaryCount={1}
                        variant="outlined"
                        shape="rounded"
                        onChange={handlePageChange}
                    />
                </div>

                <table
                className="table table-striped table-bordered"
                {...getTableProps()}
                >
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()}>
                                    {column.render("Header")}
                                </th>
                            ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

            <div className="col-md-8">
                <button className="btn btn-sm btn-danger" onClick={removeAllTutorials}>
                Remove All
                </button>
            </div>
        </div>
    );
};

export default TutorialsList;