import { CREATE_ORDER, RETRIEVE_ORDER, DELETE_ORDER } from "../actions/types";

const initialState = {};

const orderReducer = function (order = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case CREATE_ORDER:
			return payload;

		case RETRIEVE_ORDER:
			return payload;

		case DELETE_ORDER:
			return payload;

		default:
			return order;
	}
};

export default orderReducer;
