import React, { Component } from "react";
import { connect } from "react-redux";
import { retrieveSalesRegister } from "../../actions/spendMoney";
import {
	setCurrentPage,
	setCurrentPageSize,
	setCurrentOrder,
	setCurrentOrderBy,
	setModule,
} from "../../actions/common";
import { showLoading, hideLoading } from "../../actions/loading";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { parseDate } from "../../helpers/date";
import NumberFormat from "react-number-format";
import { format } from "date-fns";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";

class salesRegisterList extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			searchType: "",
			searchFrom: format(new Date(), "yyyy-MM-01"),
			searchTo: format(new Date(), "yyyy-MM-dd"),
			count: 0,
			alert: false,
			error: false,
			delID: 0,
			delTitle: "",
			message: false,
		};
	}

	types = [
		{
			label: "All",
			value: "",
		},
		{
			label: "Quote",
			value: "quote",
		},
		{
			label: "Order",
			value: "order",
		},
		{
			label: "Invoice",
			value: "invoice",
		},
		{
			label: "Closed Invoice",
			value: "closed_invoice",
		},
	];

	componentDidMount() {
		this._isMounted = true;
		if (this._isMounted) {
			this._isMounted = true;
			if (this._isMounted) {
				this.props.setModule("Sales Register");
				if (this.props.match.params.source === "main") {
					this.getRows();
				} else {
					this.getRows();
				}
			}
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	getRows = () => {
		this.props.showLoading();
		var params = {
			type: this.state.searchType,
			id_company: this.props.auth.idCompany,
			from: this.state.searchFrom,
			to: this.state.searchTo,
		};

		this.setState({ message: false }, function () {
			this.props
				.retrieveSalesRegister(params)
				.then(() => {
					this.props.hideLoading();
				})
				.catch((e) => {
					this.setState({
						error: true,
						message: e.response.data.error,
					});
					this.props.hideLoading();
				});
		});
	};

	onChangeSearchType(e) {
		this.setState({ searchType: e.target.value });
	}
	onChangeSearchFrom(e) {
		this.setState({ searchFrom: e.target.value });
	}
	onChangeSearchTo(e) {
		this.setState({ searchTo: e.target.value });
	}

	searchData = (e) => {
		e.preventDefault();
		this.getRows();
	};

	handleClose = (e) => {
		this.setState({
			alert: false,
			error: false,
		});
	};

	render() {
		return (
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
						<Box component="form" onSubmit={(e) => this.searchData(e)}>
							<Typography variant="h5">Search Form</Typography>

							<Grid container spacing={3}>
								<Grid item xs={12} sm={6}>
									<FormControl
										fullWidth={true}
										variant="standard"
										sx={{ minWidth: 100 }}
									>
										<InputLabel id="typeLabel">Type</InputLabel>
										<Select
											labelId="typeLabel"
											label="Type"
											value={this.state.searchType}
											onChange={(e) => this.onChangeSearchType(e)}
										>
											{this.types.map((option, index) => (
												<MenuItem key={index} value={option.value}>
													{option.label}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>

								<Grid item xs={12} sm={3}>
									<TextField
										label="From"
										type="date"
										value={this.state.searchFrom}
										onChange={(e) => this.onChangeSearchFrom(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={3}>
									<TextField
										label="To"
										type="date"
										value={this.state.searchTo}
										onChange={(e) => this.onChangeSearchTo(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={12}>
									<Button
										type="submit"
										variant="contained"
										startIcon={<SearchIcon />}
									>
										Search
									</Button>
								</Grid>
							</Grid>
						</Box>
					</Paper>
				</Grid>

				<Grid item xs={12}>
					<Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
						<Typography variant="h4" gutterBottom component="div">
							Sales Register
						</Typography>

						<TableContainer component={Paper}>
							<Table sx={{}} size="small">
								<TableHead>
									<TableRow>
										<TableCell width={5}>No.</TableCell>
										<TableCell align="center">Date</TableCell>
										<TableCell align="center">Code</TableCell>
										<TableCell align="center">PO#</TableCell>
										<TableCell align="center">Card</TableCell>
										<TableCell align="center">Amount</TableCell>
										<TableCell align="center">Amt Due</TableCell>
										<TableCell align="center">Status</TableCell>
										<TableCell align="center">Edit</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{this.props.salesRegister.Data &&
										this.props.salesRegister.Data.map((row, index) => (
											<TableRow key={index}>
												<TableCell align="right">{1 + index}</TableCell>
												<TableCell align="center">
													{parseDate(row.date)}
												</TableCell>
												<TableCell>{row.code}</TableCell>
												<TableCell>{row.code_po}</TableCell>
												<TableCell>{row.card}</TableCell>
												<TableCell align="right">
													<NumberFormat
														value={row.amount}
														displayType={"text"}
														thousandSeparator="."
														decimalSeparator=","
														prefix={""}
													/>
												</TableCell>
												<TableCell align="right">
													<NumberFormat
														value={row.outstanding}
														displayType={"text"}
														thousandSeparator="."
														decimalSeparator=","
														prefix={""}
													/>
												</TableCell>
												<TableCell align="center">{row.status}</TableCell>
												<TableCell align="center">
													<Link
														to={"/edit" + row.tipe + "/" + row.id}
														className="badge badge-warning"
													>
														<EditIcon fontSize="small" />
													</Link>
												</TableCell>
											</TableRow>
										))}
								</TableBody>
							</Table>
						</TableContainer>

						<Dialog
							open={this.state.error}
							onClose={this.handleClose}
							aria-labelledby="error-dialog-title"
							aria-describedby="error-dialog-description"
						>
							<DialogTitle id="error-dialog-title">Failed</DialogTitle>
							<DialogContent>
								<DialogContentText id="error-dialog-description">
									{this.state.message}
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={this.handleClose} autoFocus>
									Close
								</Button>
							</DialogActions>
						</Dialog>
					</Paper>
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		salesRegister: state.spendMoney,
		common: state.common,
		loading: state.loading,
		auth: state.auth,
	};
};

export default connect(mapStateToProps, {
	retrieveSalesRegister,
	setCurrentPage,
	setCurrentPageSize,
	setCurrentOrder,
	setCurrentOrderBy,
	setModule,
	showLoading,
	hideLoading,
})(salesRegisterList);
