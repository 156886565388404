import React from "react";
import { history } from '../helpers/history';

const AuthVerify = (props) => {
  history.listen(() => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user) {

      
      //const decodedJwt = parseJwt(user.accessToken);

      //if (decodedJwt.exp * 1000 < Date.now()) {
      //  props.logOut();
     // }
    }
    else{
      props.logOut();
     // history.push('/login');
    }
  });

  return <div></div>;
};

export default AuthVerify;