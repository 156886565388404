import React, { Component } from "react";
import { connect } from "react-redux";
import { createJob } from "../../actions/job";
import { setModule } from "../../actions/common";
import { showLoading, hideLoading } from "../../actions/loading";
import JobDataService from "../../services/job.service";
import CardDataService from "../../services/card.service";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import SaveIcon from "@mui/icons-material/Save";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { format } from "date-fns";

class AddJob extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			id: null,
			id_company: this.props.auth.idCompany,
			code: "",
			name: "",
			note: "",
			contact: "",
			percent_complete: 0,
			start_date: format(new Date(), "yyyy-MM-dd"),
			finish_date: format(new Date(), "yyyy-MM-dd"),
			manager: "",
			active: true,

			error: false,
			message: false,

			companies_ready: false,

			companies: [],
		};
	}

	componentDidMount() {
		this._isMounted = true;
		if (this._isMounted) {
			this.props.setModule("Job");
			if (typeof this.props.match.params.id !== "undefined") {
				this.loadCurrentRow();
				this.loadSelect();
			} else {
				this.loadSelect();
			}
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	componentDidUpdate() {
		if (typeof this.props.match.params.id === "undefined") {
			if (this.state.id !== null) {
				this.newData();
			}
		}
	}

	loadSelect() {
		CardDataService.getCompanies().then((response) => {
			this.setState({
				companies_ready: true,
				companies: response.data.Row,
			});
		});
	}

	loadCurrentRow() {
		this.props.showLoading();

		JobDataService.get(this.props.match.params.id)
			.then((response) => {
				this.setState(
					{
						id: response.data.Row.id,
						id_company: response.data.Row.id_company,
						code: response.data.Row.code,
						name: response.data.Row.name,
						note: response.data.Row.note,
						contact: response.data.Row.contact,
						percent_complete: response.data.Row.percent_complete,
						start_date: response.data.Row.start_date,
						finish_date: response.data.Row.finish_date,
						manager: response.data.Row.manager,
						active: response.data.Row.active,
					},
					function () {
						this.props.hideLoading();
					}
				);
			})
			.catch((e) => {
				console.log(e.response.data.error);
				this.setState({ message: e.response.data.error });
			});
	}

	newData() {
		this.setState({
			id: null,
			id_company: this.props.auth.idCompany,
			code: "",
			name: "",
			note: "",
			contact: "",
			percent_complete: 0,
			start_date: format(new Date(), "yyyy-MM-dd"),
			finish_date: format(new Date(), "yyyy-MM-dd"),
			manager: "",
			active: true,

			message: false,
		});
	}

	onChangeIdCompany(e) {
		this.setState({ id_company: e.target.value });
	}
	onChangeCode(e) {
		this.setState({ code: e.target.value });
	}
	onChangeName(e) {
		this.setState({ name: e.target.value });
	}
	onChangeNote(e) {
		this.setState({ note: e.target.value });
	}
	onChangeContact(e) {
		this.setState({ contact: e.target.value });
	}
	onChangePercentComplete(e) {
		this.setState({ percent_complete: e.target.value });
	}
	onChangeStartDate(e) {
		this.setState({ start_date: e.target.value });
	}
	onChangeFinishDate(e) {
		this.setState({ finish_date: e.target.value });
	}
	onChangeManager(e) {
		this.setState({ manager: e.target.value });
	}
	onChangeActive(e) {
		this.setState({ active: e.target.value });
	}

	alertClose = () => {
		this.setState({ error: false });
	};

	saveJob(e) {
		e.preventDefault();
		this.props.showLoading();

		this.setState({
			message: false,
		});

		this.props
			.createJob(
				this.state.id,
				this.state.id_company,
				this.state.code,
				this.state.name,
				this.state.note,
				this.state.contact,
				parseFloat(this.state.percent_complete),
				this.state.start_date,
				this.state.finish_date,
				this.state.manager,
				this.state.active
			)
			.then((data) => {
				this.props.history.push("/job/form");
			})
			.catch((e) => {
				this.setState({
					message: e.response.data.error,
					error: true,
				});
				this.props.hideLoading();
			});
	}

	//tambahan

	render() {
		return (
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
						<Box component="form" onSubmit={(e) => this.saveJob(e)}>
							<Typography variant="h5">Job Form</Typography>

							<Grid container spacing={3}>
								<Grid item xs={12} sm={12}>
									<Dialog
										open={this.state.error}
										onClose={this.alertClose}
										aria-labelledby="error-dialog-title"
										aria-describedby="error-dialog-description"
									>
										<DialogTitle id="error-dialog-title">Failed</DialogTitle>
										<DialogContent>
											<DialogContentText id="error-dialog-description">
												{this.state.message}
											</DialogContentText>
										</DialogContent>
										<DialogActions>
											<Button onClick={this.alertClose} autoFocus>
												Close
											</Button>
										</DialogActions>
									</Dialog>
								</Grid>

								{this.props.auth.user.usergroup === "administrator" && (
									<Grid item xs={12} sm={12}>
										{this.state.companies_ready && (
											<FormControl
												fullWidth={true}
												variant="standard"
												sx={{ minWidth: 100 }}
											>
												<InputLabel id="idCompany">Company</InputLabel>
												<Select
													labelId="idCompany"
													label="Company"
													value={this.state.id_company}
													onChange={(e) => this.onChangeIdCompany(e)}
												>
													{this.state.companies.map((option, index) => (
														<MenuItem key={index} value={option.id}>
															{option.name}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										)}
									</Grid>
								)}

								<Grid item xs={12} sm={6}>
									<TextField
										label="Code"
										value={this.state.code}
										onChange={(e) => this.onChangeCode(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={6}>
									<TextField
										required
										label="Name"
										value={this.state.name}
										onChange={(e) => this.onChangeName(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={3}>
									<TextField
										label="Contact"
										value={this.state.contact}
										onChange={(e) => this.onChangeContact(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>
								<Grid item xs={12} sm={3}>
									<TextField
										label="Whatsapp"
										value={this.state.whatsapp}
										onChange={(e) => this.onChangeWhatsapp(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>
								<Grid item xs={12} sm={3}>
									<TextField
										label="Manager"
										value={this.state.manager}
										onChange={(e) => this.onChangeManager(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>
								<Grid item xs={12} sm={3}>
									<TextField
										label="Percent Complete"
										value={this.state.percent_complete}
										onChange={(e) => this.onChangePercentComplete(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>
								<Grid item xs={12} sm={3}>
									<TextField
										label="Start Date"
										type="date"
										value={this.state.start_date}
										onChange={(e) => this.onChangeStartDate(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={3}>
									<TextField
										label="Finish Date"
										type="date"
										value={this.state.finish_date}
										onChange={(e) => this.onChangeFinishDate(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={6}>
									<FormControl>
										<FormLabel>Active</FormLabel>
										<RadioGroup row>
											<FormControlLabel
												control={<Radio />}
												label="Active"
												value={true}
												checked={
													this.state.active === "true" ||
													this.state.active === true
												}
												onChange={(e) => this.onChangeActive(e)}
											/>

											<FormControlLabel
												control={<Radio />}
												label="Inactive"
												value={false}
												checked={
													this.state.active === "false" ||
													this.state.active === false
												}
												onChange={(e) => this.onChangeActive(e)}
											/>
										</RadioGroup>
									</FormControl>
								</Grid>

								<Grid item xs={12} sm={12}>
									<Button
										variant="contained"
										component={Link}
										to="/job/form"
										startIcon={<ArrowBackIcon />}
										sx={{ mr: 1 }}
									>
										Cancel
									</Button>
									<Button
										type="submit"
										variant="contained"
										disabled={this.props.loading.loading}
										startIcon={<SaveIcon />}
									>
										{this.props.loading.loading && (
											<span>
												<span className="spinner-border spinner-border-sm"></span>
												&nbsp;
											</span>
										)}
										<span>
											{" "}
											{this.props.loading.loading ? "Please Wait" : "Save"}
										</span>
									</Button>
								</Grid>
							</Grid>
						</Box>
					</Paper>
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
		loading: state.loading,
	};
};

export default connect(mapStateToProps, {
	createJob,
	showLoading,
	hideLoading,
	setModule,
})(AddJob);
