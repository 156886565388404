import React, { Component } from "react";
import { connect } from "react-redux";
import { updateLinkedAccounts } from "../../actions/account";
import { setModule } from "../../actions/common";
import { showLoading, hideLoading } from "../../actions/loading";
import AccountDataService from "../../services/account.service";
import TextField from "@mui/material/TextField";
import SaveIcon from "@mui/icons-material/Save";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Alert from "@mui/material/Alert";

import Autocomplete from "@mui/material/Autocomplete";
import { AcIndexByValue } from "../../helpers/common";
import CardDataService from "../../services/card.service";
import TransferMoneyDataService from "../../services/transfer_money.service";
import JournalDataService from "../../services/journal.service";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Divider from "@mui/material/Divider";

class UpdateLinkedAccounts extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			trackingReceivables: 0,
			trackingPayables: 0,
			bankCustomerReceipts: 0,
			bankPayingBills: 0,
			historicalBalancing: 0,
			undepositedFunds: 0,
			currentEarnings: 0,
			retainedEarnings: 0,
			idCompany: this.props.auth.idCompany,

			error: false,
			message: false,
			success: false,

			companies_ready: false,
			accounts_ready: false,
			accounts2_ready: false,

			companies: [],
			accounts: [],
			accounts2: [],
		};
	}

	componentDidMount() {
		this._isMounted = true;
		if (this._isMounted) {
			this.props.setModule("Linked Accounts");
			this.loadCurrentRow();
			this.loadSelect();
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	componentDidUpdate() {}

	loadSelect() {
		CardDataService.getCompanies().then((response) => {
			this.setState({
				companies_ready: true,
				companies: response.data.Row,
			});
		});
		TransferMoneyDataService.getAccounts().then((response) => {
			this.setState({
				accounts_ready: true,
				accounts: response.data.Row,
			});
		});
		JournalDataService.getAccounts().then((response) => {
			this.setState({
				accounts2_ready: true,
				accounts2: response.data.Row,
			});
		});
	}

	loadCurrentRow() {
		this.props.showLoading();

		AccountDataService.getLinkedAccounts(this.state.idCompany)
			.then((response) => {
				this.setState(
					{
						trackingReceivables: response.data.tracking_receivables,
						trackingPayables: response.data.tracking_payables,
						bankCustomerReceipts: response.data.bank_customer_receipts,
						bankPayingBills: response.data.bank_paying_bills,
						historicalBalancing: response.data.historical_balancing,
						undepositedFunds: response.data.undeposited_funds,
						currentEarnings: response.data.current_earnings,
						retainedEarnings: response.data.retained_earnings,
					},
					function () {
						this.props.hideLoading();
					}
				);
			})
			.catch((e) => {
				console.log(e.response.data.error);
				this.setState({ message: e.response.data.error });
			});
	}

	onChangeTrackingReceivables(e, val) {
		if (val !== null) {
			this.setState({ trackingReceivables: val.id });
		}
	}
	onChangeTrackingPayables(e, val) {
		if (val !== null) {
			this.setState({ trackingPayables: val.id });
		}
	}
	onChangeBankCustomerReceipts(e, val) {
		if (val !== null) {
			this.setState({ bankCustomerReceipts: val.id });
		}
	}
	onChangeBankPayingBills(e, val) {
		if (val !== null) {
			this.setState({ bankPayingBills: val.id });
		}
	}
	onChangeHistoricalBalancing(e, val) {
		if (val !== null) {
			this.setState({ historicalBalancing: val.id });
		}
	}
	onChangeUndepositedFunds(e, val) {
		if (val !== null) {
			this.setState({ undepositedFunds: val.id });
		}
	}
	onChangeCurrentEarnings(e, val) {
		if (val !== null) {
			this.setState({ currentEarnings: val.id });
		}
	}
	onChangeRetainedEarnings(e, val) {
		if (val !== null) {
			this.setState({ retainedEarnings: val.id });
		}
	}

	alertClose = () => {
		this.setState({ error: false });
	};

	saveLinkedAccounts(e) {
		e.preventDefault();
		this.props.showLoading();

		this.setState({
			message: false,
			success: false,
		});

		this.props
			.updateLinkedAccounts(
				this.state.trackingReceivables,
				this.state.trackingPayables,
				this.state.bankCustomerReceipts,
				this.state.bankPayingBills,
				this.state.historicalBalancing,
				this.state.undepositedFunds,
				this.state.currentEarnings,
				this.state.retainedEarnings,
				this.state.idCompany
			)
			.then((data) => {
				this.setState({
					success: data.message,
				});
				this.props.hideLoading();
			})
			.catch((e) => {
				this.setState({
					message: e.response.data.error,
					error: true,
				});
				this.props.hideLoading();
			});
	}

	render() {
		return (
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
						<Box component="form" onSubmit={(e) => this.saveLinkedAccounts(e)}>
							<Typography variant="h5">Linked Accounts</Typography>

							<Grid container spacing={3}>
								{this.state.success && (
									<Grid item xs={12} sm={12}>
										<Alert severity="success">{this.state.success}</Alert>
									</Grid>
								)}

								<Grid item xs={12} sm={12}>
									<Dialog
										open={this.state.error}
										onClose={this.alertClose}
										aria-labelledby="error-dialog-title"
										aria-describedby="error-dialog-description"
									>
										<DialogTitle id="error-dialog-title">Failed</DialogTitle>
										<DialogContent>
											<DialogContentText id="error-dialog-description">
												{this.state.message}
											</DialogContentText>
										</DialogContent>
										<DialogActions>
											<Button onClick={this.alertClose} autoFocus>
												Close
											</Button>
										</DialogActions>
									</Dialog>
								</Grid>

								{this.props.auth.user.usergroup === "administrator" && (
									<Grid item xs={12} sm={12}>
										{this.state.companies_ready && (
											<FormControl
												fullWidth={true}
												variant="standard"
												sx={{ minWidth: 100 }}
											>
												<InputLabel id="idCompany">Company</InputLabel>
												<Select
													labelId="idCompany"
													label="Company"
													value={this.state.id_company}
													onChange={(e) => this.onChangeIdCompany(e)}
												>
													{this.state.companies.map((option, index) => (
														<MenuItem key={index} value={option.id}>
															{option.name}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										)}
									</Grid>
								)}

								<Grid item xs={12} sm={6}>
									{this.state.accounts2_ready && (
										<Autocomplete
											options={this.state.accounts2}
											getOptionLabel={(option) => option.name}
											fullWidth={true}
											onChange={(e, val) =>
												this.onChangeTrackingReceivables(e, val)
											}
											value={AcIndexByValue(
												this.state.accounts2,
												this.state.trackingReceivables
											)}
											sx={{ minWidth: 100 }}
											renderInput={(params) => (
												<TextField
													variant="standard"
													{...params}
													label="Asset Account for Tracking Receivables"
												/>
											)}
										/>
									)}
								</Grid>

								<Grid item xs={12} sm={6}>
									{this.state.accounts_ready && (
										<Autocomplete
											options={this.state.accounts}
											getOptionLabel={(option) => option.name}
											fullWidth={true}
											onChange={(e, val) =>
												this.onChangeBankCustomerReceipts(e, val)
											}
											value={AcIndexByValue(
												this.state.accounts,
												this.state.bankCustomerReceipts
											)}
											sx={{ minWidth: 100 }}
											renderInput={(params) => (
												<TextField
													variant="standard"
													{...params}
													label="Bank Account for Customer Receipts"
												/>
											)}
										/>
									)}
								</Grid>

								<Grid item xs={12} sm={12}>
									<Divider />
								</Grid>

								<Grid item xs={12} sm={6}>
									{this.state.accounts2_ready && (
										<Autocomplete
											options={this.state.accounts2}
											getOptionLabel={(option) => option.name}
											fullWidth={true}
											onChange={(e, val) =>
												this.onChangeTrackingPayables(e, val)
											}
											value={AcIndexByValue(
												this.state.accounts2,
												this.state.trackingPayables
											)}
											sx={{ minWidth: 100 }}
											renderInput={(params) => (
												<TextField
													variant="standard"
													{...params}
													label="Liability Account for Tracking Payables"
												/>
											)}
										/>
									)}
								</Grid>

								<Grid item xs={12} sm={6}>
									{this.state.accounts_ready && (
										<Autocomplete
											options={this.state.accounts}
											getOptionLabel={(option) => option.name}
											fullWidth={true}
											onChange={(e, val) =>
												this.onChangeBankPayingBills(e, val)
											}
											value={AcIndexByValue(
												this.state.accounts,
												this.state.bankPayingBills
											)}
											sx={{ minWidth: 100 }}
											renderInput={(params) => (
												<TextField
													variant="standard"
													{...params}
													label="Bank Account for Paying Bills"
												/>
											)}
										/>
									)}
								</Grid>

								<Grid item xs={12} sm={12}>
									<Divider />
								</Grid>

								<Grid item xs={12} sm={6}>
									{this.state.accounts2_ready && (
										<Autocomplete
											options={this.state.accounts2}
											getOptionLabel={(option) => option.name}
											fullWidth={true}
											onChange={(e, val) =>
												this.onChangeHistoricalBalancing(e, val)
											}
											value={AcIndexByValue(
												this.state.accounts2,
												this.state.historicalBalancing
											)}
											sx={{ minWidth: 100 }}
											renderInput={(params) => (
												<TextField
													variant="standard"
													{...params}
													label="Equity Account for Historical Balancing"
												/>
											)}
										/>
									)}
								</Grid>

								<Grid item xs={12} sm={6}>
									{this.state.accounts_ready && (
										<Autocomplete
											options={this.state.accounts}
											getOptionLabel={(option) => option.name}
											fullWidth={true}
											onChange={(e, val) =>
												this.onChangeUndepositedFunds(e, val)
											}
											value={AcIndexByValue(
												this.state.accounts,
												this.state.undepositedFunds
											)}
											sx={{ minWidth: 100 }}
											renderInput={(params) => (
												<TextField
													variant="standard"
													{...params}
													label="Bank Account for Undeposited Funds"
												/>
											)}
										/>
									)}
								</Grid>

								<Grid item xs={12} sm={12}>
									<Divider />
								</Grid>

								<Grid item xs={12} sm={6}>
									{this.state.accounts2_ready && (
										<Autocomplete
											options={this.state.accounts2}
											getOptionLabel={(option) => option.name}
											fullWidth={true}
											onChange={(e, val) =>
												this.onChangeCurrentEarnings(e, val)
											}
											value={AcIndexByValue(
												this.state.accounts2,
												this.state.currentEarnings
											)}
											sx={{ minWidth: 100 }}
											renderInput={(params) => (
												<TextField
													variant="standard"
													{...params}
													label="Equity Account for Current Earnings"
												/>
											)}
										/>
									)}
								</Grid>

								<Grid item xs={12} sm={6}>
									{this.state.accounts2_ready && (
										<Autocomplete
											options={this.state.accounts2}
											getOptionLabel={(option) => option.name}
											fullWidth={true}
											onChange={(e, val) =>
												this.onChangeRetainedEarnings(e, val)
											}
											value={AcIndexByValue(
												this.state.accounts2,
												this.state.retainedEarnings
											)}
											sx={{ minWidth: 100 }}
											renderInput={(params) => (
												<TextField
													variant="standard"
													{...params}
													label="Equity Account for Retained Earnings"
												/>
											)}
										/>
									)}
								</Grid>

								<Grid item xs={12} sm={12}>
									<Button
										type="submit"
										variant="contained"
										disabled={this.props.loading.loading}
										startIcon={<SaveIcon />}
									>
										{this.props.loading.loading && (
											<span>
												<span className="spinner-border spinner-border-sm"></span>
												&nbsp;
											</span>
										)}
										<span>
											{" "}
											{this.props.loading.loading ? "Please Wait" : "Save"}
										</span>
									</Button>
								</Grid>
							</Grid>
						</Box>
					</Paper>
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
		loading: state.loading,
	};
};

export default connect(mapStateToProps, {
	updateLinkedAccounts,
	showLoading,
	hideLoading,
	setModule,
})(UpdateLinkedAccounts);
