import React, { Component } from "react";
import { connect } from "react-redux";
import {
    retrieveTutorials,
    deleteTutorial,
    setCurrentPage,
    setCurrentPageSize
} from "../../actions/tutorials";
import { Link } from "react-router-dom";
import Pagination from '@mui/material/Pagination';
import Form from "react-validation/build/form";
import { Button,Modal } from 'react-bootstrap';


class TutorialsList2 extends Component {
    constructor(props) {

        super(props);
        this.onChangeSearchTitle = this.onChangeSearchTitle.bind(this);
        this.setActiveTutorial = this.setActiveTutorial.bind(this);
        this.searchData = this.searchData.bind(this);
        this.getRows = this.getRows.bind(this);

        this.state = {
            currentTutorial : null,
            currentIndex : -1,
            searchTitle : "",
            count : 0,
            alert : false,
            delTitle : "",
            delID : -1,
            loading:false,
            message:false
        };


    };

    
    pageSizes   = [5, 10, 20, 100];

    getRequestParams = (searchTitle, page, pageSize) => {
        let params = {};

        if (searchTitle) {
            params["title"] = searchTitle;
        }

        if (page) {
            params["page"] = page;
        }

        if (pageSize) {
            params["size"] = pageSize;
        }

        return params;
    };



    componentDidMount() {

        

        
        this.getRows();
    };

    getRows() {
        const params = this.getRequestParams(
            this.state.searchTitle, 
            this.props.common.currentPage, 
            this.props.common.currentPageSize
        );

        this.props.retrieveTutorials(params).then(() => {
            const totalPages = this.props.tutorials.totalPages;
            this.setState({
                count: totalPages,
                currentTutorial: null,
                currentIndex: -1
            });

            //console.log(this.props.common.currentPage);
        })
        .catch((e) => {
            console.log(e);
        });
    }

    handlePageSizeChange = (event) => {
        this.props.setCurrentPageSize(event.target.value).then(()=>{
            this.props.setCurrentPage(1).then(()=>{
                this.getRows();
            })
        })
    };

    handlePageChange = (event, value) => {
        this.props.setCurrentPage(value).then(()=>{
            this.getRows();
        })
    };

    setActiveTutorial(tutorial, index) {
        this.setState({
            currentTutorial: tutorial,
            currentIndex: index,
        });
    }

    onChangeSearchTitle(e) {
        const searchTitle = e.target.value;

        this.setState({
            searchTitle: searchTitle,
        });
    }

    searchData = (e) => {
        e.preventDefault();
        this.props.setCurrentPage(1).then(()=>{
            this.getRows();
        })
    }

    confirmDelete = (id, title) => {
        
        this.setState({
            alert : true,
            delID : id,
            delTitle : title
        });
    };

    handleClose = (e) =>  {
        this.setState({
            alert:false
        });
    }

    deleteRow = (e) => {

        this.setState({loading:true, message:false}, function(){
            
            this.props.deleteTutorial(this.state.delID).then((response) => {
                this.setState({
                    alert:false,
                    loading:false
                }, function(){
                    this.getRows();
                });
            })
            .catch((e) => {
                console.log(e.response.data);
                this.setState({
                    alert:false,
                    loading:false,
                    message:e.response.data
                }, function(){
                    alert(e.response.data)
                });
            });
        });
        

       
        /*
        this.props.deleteTutorial(id).then((response) => {
            this.getRows();
        })
        .catch((e) => {
            console.log(e);
        });
        */
    };

    render() {

        const { 
            searchTitle, 
            currentTutorial, 
            currentIndex,
            count,
            alert,
            delTitle,
            loading,
            message
        } = this.state;
        const { rows }  = this.props.tutorials;

        return (
            <div className="list row">
                <div className="col-md-12">
                    <Form onSubmit={this.searchData}>
                        <div className="input-group mb-3">
                            <input
                            type="text"
                            className="form-control"
                            placeholder="Search by title"
                            value={searchTitle}
                            onChange={this.onChangeSearchTitle}
                            />
                            <div className="input-group-append">
                                <button
                                    className="btn btn-outline-secondary"
                                    type="button"
                                    onClick={this.searchData}
                                >
                                Search
                                </button>
                            </div>
                        </div>
                    </Form>

                    <div className="mt-3">
                        {"Items per Page: "}
                        <select onChange={this.handlePageSizeChange} value={this.props.common.currentPageSize}>
                            {this.pageSizes.map((size) => (
                                <option key={size} value={size}>
                                {size}
                                </option>
                            ))}
                        </select>

                        <Pagination
                            className="my-3"
                            count={count}
                            page={this.props.common.currentPage}
                            siblingCount={1}
                            boundaryCount={1}
                            variant="outlined"
                            shape="rounded"
                            onChange={this.handlePageChange}
                        />
                    </div>
                </div>
                <div className="col-md-12">


                    {message && (
                        <div className="form-group">
                        <div className="alert alert-danger" role="alert">
                            {message}
                        </div>
                        </div>
                    )}



                    <h4>Tutorials List</h4>
                    <div>
                        showing {this.props.tutorials.firstItem} 
                        - {this.props.tutorials.lastItem}&nbsp;
                        from {this.props.tutorials.totalItems} 
                    </div>

                    <table className="table table-striped table-bordered" role="table">
                        <thead>
                            <tr role="row">
                                <th>Title</th>
                                <th>Title</th>
                                <th>Description</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>

                        <tbody>

                            {rows && rows.map((tutorial, index) => (
                            <tr  className={
                                "row-group-item " +
                                (index === currentIndex ? "active" : "")
                                }
                                key={index}
                            >
                            <td role="cell">{ this.props.tutorials.firstItem + index }</td>
                            <td role="cell">{tutorial.title}</td>
                            <td role="cell">{tutorial.description}</td>
                            <td role="cell">{tutorial.published ? "Published" : "Pending"}</td>
                            <td role="cell">
                                <div>
                                    <span onClick={() => this.setActiveTutorial(tutorial, index)}>
                                        View
                                    </span>

                                    <span>
                                        <Link
                                        to={"/edittutorials2/" + tutorial.id}
                                        className="badge badge-warning"
                                        >
                                        Edit
                                        </Link>
                                    </span>

                                    <span onClick={() => this.confirmDelete(tutorial.id, tutorial.title)}>
                                        delete
                                    </span>
                                </div>
                            </td>
                            </tr>
                            ))}

                        </tbody>
                    </table>    
                </div>

                <div className="col-md-12">
                    {currentTutorial ? (
                    <div>
                        <h4>Tutorial</h4>
                        <div>
                            <label>
                            <strong>Title:</strong>
                            </label>{" "}
                            {currentTutorial.title}
                        </div>
                        <div>
                            <label>
                            <strong>Description:</strong>
                            </label>{" "}
                            {currentTutorial.description}
                        </div>
                        <div>
                            <label>
                            <strong>Status:</strong>
                            </label>{" "}
                            {currentTutorial.published ? "Published" : "Pending"}
                        </div>

                        <Link
                        to={"/tutorials2/" + currentTutorial.id}
                        className="badge badge-warning"
                        >
                        Edit
                        </Link>

                        <span onClick={() => this.confirmDelete(currentTutorial.id, currentTutorial.title)}>
                            Hapus
                        </span>

                    </div>
                    ) : (
                    <div>
                        <br />
                        <p>Please click on a Tutorial...</p>
                    </div>
                    )}
                </div>

                <Modal show={alert} onHide={this.handleClose}>
                    <Modal.Header >
                    <Modal.Title>Alert!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Yakin akan mnghapus"{delTitle}" ?</Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" disabled={loading} onClick={this.handleClose}>
                    Cancel
                    </Button>
                    <Button variant="danger" disabled={loading} onClick={this.deleteRow}>

                    {loading && (
                    <span>
                    <span className="spinner-border spinner-border-sm"></span>
                    &nbsp;
                    </span>
                    )}
                    Delete
                    </Button>
                    </Modal.Footer>
                </Modal>

            </div>
        );
    }
}

const mapStateToProps = (state) => {

    return {
        tutorials: state.tutorials,
        common : state.common
    };
};


export default connect(mapStateToProps, {
    retrieveTutorials,
    deleteTutorial,
    setCurrentPage,
    setCurrentPageSize
})(TutorialsList2);