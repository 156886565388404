import {
	CREATE_RECEIVE_MONEY,
	RETRIEVE_RECEIVE_MONEY,
	DELETE_RECEIVE_MONEY,
} from "./types";

import ReceiveMoneyDataService from "../services/receiveMoney.service";
import { parseNumber } from "../helpers/common";

export const createReceiveMoney =
	(
		id,
		id_company,
		id_card,
		code,
		date,
		note,
		from,
		amount,
		tax_inclusive,
		id_payment_method,
		detail,
		recurring,
		recurring_name
	) =>
	async (dispatch) => {
		try {
			if (tax_inclusive === "true") {
				tax_inclusive = true;
			}
			if (tax_inclusive === "false") {
				tax_inclusive = false;
			}

			amount = parseFloat(amount);
			code = code.toString();
			id_card = parseNumber(id_card);
			id_payment_method = parseNumber(id_payment_method);

			let formData = {
				id: id,
				id_company: id_company,
				id_card: id_card,
				code: code,
				date: date,
				note: note,
				from: from,
				amount: amount,
				tax_inclusive: tax_inclusive,
				id_payment_method: id_payment_method,
				detail: detail,
				recurring: recurring,
				recurring_name: recurring_name,
			};

			const res = await ReceiveMoneyDataService.create(formData);

			dispatch({
				type: CREATE_RECEIVE_MONEY,
				payload: res.data,
			});

			return Promise.resolve(res.data);
		} catch (err) {
			return Promise.reject(err);
		}
	};

export const retrieveReceiveMoney = (params) => async (dispatch) => {
	try {
		const res = await ReceiveMoneyDataService.getAll(params);

		dispatch({
			type: RETRIEVE_RECEIVE_MONEY,
			payload: res.data,
		});
	} catch (err) {
		return Promise.reject(err);
	}
};

export const deleteReceiveMoney = (id) => async (dispatch) => {
	try {
		const res = await ReceiveMoneyDataService.delete(id);

		dispatch({
			type: DELETE_RECEIVE_MONEY,
			payload: res.data,
		});
	} catch (err) {
		return Promise.reject(err);
	}
};
