import {
	CREATE_RECEIVE_PAYMENT,
	RETRIEVE_RECEIVE_PAYMENT,
	DELETE_RECEIVE_PAYMENT,
} from "./types";

import ReceivePaymentDataService from "../services/receivePayment.service";
import { parseNumber } from "../helpers/common";

export const createReceivePayment =
	(
		id,
		id_company,
		id_source,
		id_card,
		code,
		date,
		id_payment_method,
		note,
		amount,
		from
	) =>
	async (dispatch) => {
		try {
			amount = parseFloat(amount);
			code = code.toString();
			id_card = parseNumber(id_card);
			id_payment_method = parseNumber(id_payment_method);

			let formData = {
				id: id,
				id_company: id_company,
				id_source: id_source,
				id_card: id_card,
				code: code,
				date: date,
				id_payment_method: id_payment_method,
				note: note,
				amount: amount,
				from: from,
			};

			const res = await ReceivePaymentDataService.create(formData);

			dispatch({
				type: CREATE_RECEIVE_PAYMENT,
				payload: res.data,
			});

			return Promise.resolve(res.data);
		} catch (err) {
			return Promise.reject(err);
		}
	};

export const retrieveReceivePayment = (params) => async (dispatch) => {
	try {
		const res = await ReceivePaymentDataService.getAll(params);

		dispatch({
			type: RETRIEVE_RECEIVE_PAYMENT,
			payload: res.data,
		});
	} catch (err) {
		return Promise.reject(err);
	}
};

export const deleteReceivePayment = (id) => async (dispatch) => {
	try {
		const res = await ReceivePaymentDataService.delete(id);

		dispatch({
			type: DELETE_RECEIVE_PAYMENT,
			payload: res.data,
		});
	} catch (err) {
		return Promise.reject(err);
	}
};
