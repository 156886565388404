import React, { Component } from "react";
import { connect } from "react-redux";
import { createCard } from "../../actions/card";
import { setModule } from "../../actions/common";
import { showLoading, hideLoading } from "../../actions/loading";
import CardDataService from "../../services/card.service";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import SaveIcon from "@mui/icons-material/Save";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

class AddCard extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			id: null,
			id_company: this.props.auth.idCompany,
			id_type: 1,
			designation: "Company",
			name: "",
			code: "",
			address: "",
			note: "",
			phone: "",
			mobile: "",
			whatsapp: "",
			fax: "",
			email: "",
			tax_number: "",
			id_tax: 0,
			active: true,

			error: false,
			message: false,

			companies_ready: false,
			types_ready: false,
			designations_ready: false,
			taxes_ready: false,

			companies: [],
			types: [],
			designations: [],
			taxes: [],
		};
	}

	componentDidMount() {
		this._isMounted = true;
		if (this._isMounted) {
			this.props.setModule("Card");
			if (typeof this.props.match.params.id !== "undefined") {
				this.loadCurrentRow();
				this.loadSelect();
			} else {
				this.loadSelect();
			}
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	componentDidUpdate() {
		if (typeof this.props.match.params.id === "undefined") {
			if (this.state.id !== null) {
				this.newData();
			}
		}
	}

	loadSelect() {
		CardDataService.getCompanies().then((response) => {
			this.setState({
				companies_ready: true,
				companies: response.data.Row,
			});
		});

		CardDataService.getType().then((response) => {
			this.setState({
				types_ready: true,
				types: response.data.Row,
			});
		});

		CardDataService.getDesignation().then((response) => {
			this.setState({
				designations_ready: true,
				designations: response.data.Row,
			});
		});

		CardDataService.getTaxes().then((response) => {
			this.setState({
				taxes_ready: true,
				taxes: [{ id: 0, name: " - " }, ...response.data.Row],
			});
		});
	}

	loadCurrentRow() {
		this.props.showLoading();

		CardDataService.get(this.props.match.params.id)
			.then((response) => {
				this.setState(
					{
						id: response.data.Row.id,
						id_company: response.data.Row.id_company,
						id_type: response.data.Row.id_type,
						designation: response.data.Row.designation,
						name: response.data.Row.name,
						code: response.data.Row.code,
						address: response.data.Row.address,
						note: response.data.Row.note,
						phone: response.data.Row.phone,
						mobile: response.data.Row.mobile,
						whatsapp: response.data.Row.whatsapp,
						fax: response.data.Row.fax,
						email: response.data.Row.email,
						tax_number: response.data.Row.tax_number,
						id_tax: response.data.Row.id_tax,
						active: response.data.Row.active,
					},
					function () {
						this.props.hideLoading();
					}
				);
			})
			.catch((e) => {
				this.setState({ message: e.response.data.error });
			});
	}

	newData() {
		this.setState({
			id: null,
			id_company: this.props.auth.idCompany,
			id_type: 1,
			designation: "Company",
			name: "",
			code: "",
			address: "",
			note: "",
			phone: "",
			mobile: "",
			whatsapp: "",
			fax: "",
			email: "",
			tax_number: "",
			id_tax: 0,
			active: true,

			message: false,
		});
	}

	onChangeIdCompany(e) {
		this.setState({ id_company: e.target.value });
	}

	onChangeIdType(e) {
		this.setState({ id_type: e.target.value });
	}

	onChangeDesignation(e) {
		this.setState({ designation: e.target.value });
	}

	onChangeIdTax(e) {
		this.setState({ id_tax: e.target.value });
	}

	onChangeName(e) {
		this.setState({ name: e.target.value });
	}
	onChangeCode(e) {
		this.setState({ code: e.target.value });
	}
	onChangePhone(e) {
		this.setState({ phone: e.target.value });
	}
	onChangeMobile(e) {
		this.setState({ mobile: e.target.value });
	}
	onChangeWhatsapp(e) {
		this.setState({ whatsapp: e.target.value });
	}
	onChangeFax(e) {
		this.setState({ fax: e.target.value });
	}
	onChangeEmail(e) {
		this.setState({ email: e.target.value });
	}
	onChangeTaxNumber(e) {
		this.setState({ tax_number: e.target.value });
	}
	onChangeAddress(e) {
		this.setState({ address: e.target.value });
	}
	onChangeNote(e) {
		this.setState({ note: e.target.value });
	}

	onChangeActive(e) {
		this.setState({ active: e.target.value });
	}

	alertClose = () => {
		this.setState({ error: false });
	};

	saveCard(e) {
		e.preventDefault();
		this.props.showLoading();

		this.setState({
			message: false,
		});

		this.props
			.createCard(
				this.state.id,
				this.state.id_company,
				this.state.id_type,
				this.state.designation,
				this.state.name,
				this.state.code,
				this.state.address,
				this.state.note,
				this.state.phone,
				this.state.mobile,
				this.state.whatsapp,
				this.state.fax,
				this.state.email,
				this.state.tax_number,
				this.state.id_tax,
				this.state.active
			)
			.then((data) => {
				this.props.history.push("/card/form");
			})
			.catch((e) => {
				this.setState({
					message: e.response.data.error,
					error: true,
				});
				this.props.hideLoading();
			});
	}

	//tambahan

	render() {
		return (
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
						<Box component="form" onSubmit={(e) => this.saveCard(e)}>
							<Typography variant="h5">Card Form</Typography>

							<Grid container spacing={3}>
								<Grid item xs={12} sm={12}>
									<Dialog
										open={this.state.error}
										onClose={this.alertClose}
										aria-labelledby="error-dialog-title"
										aria-describedby="error-dialog-description"
									>
										<DialogTitle id="error-dialog-title">Failed</DialogTitle>
										<DialogContent>
											<DialogContentText id="error-dialog-description">
												{this.state.message}
											</DialogContentText>
										</DialogContent>
										<DialogActions>
											<Button onClick={this.alertClose} autoFocus>
												Close
											</Button>
										</DialogActions>
									</Dialog>
								</Grid>

								{this.props.auth.user.usergroup === "administrator" && (
									<Grid item xs={12} sm={12}>
										{this.state.companies_ready && (
											<FormControl
												fullWidth={true}
												variant="standard"
												sx={{ minWidth: 100 }}
											>
												<InputLabel id="idCompany">Company</InputLabel>
												<Select
													labelId="idCompany"
													label="Company"
													value={this.state.id_company}
													onChange={(e) => this.onChangeIdCompany(e)}
												>
													{this.state.companies.map((option, index) => (
														<MenuItem key={index} value={option.id}>
															{option.name}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										)}
									</Grid>
								)}

								<Grid item xs={12} sm={6}>
									{this.state.types_ready && (
										<FormControl
											fullWidth={true}
											variant="standard"
											sx={{ minWidth: 100 }}
										>
											<InputLabel id="idType">Type</InputLabel>
											<Select
												labelId="idType"
												label="Type"
												value={this.state.id_type}
												onChange={(e) => this.onChangeIdType(e)}
											>
												<MenuItem value="0">
													<em>None</em>
												</MenuItem>
												{this.state.types.map((option, index) => (
													<MenuItem key={index} value={option.id}>
														{option.name}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									)}
								</Grid>

								<Grid item xs={12} sm={6}>
									{this.state.designations_ready && (
										<FormControl
											fullWidth={true}
											variant="standard"
											sx={{ minWidth: 100 }}
										>
											<InputLabel id="designation">Designation</InputLabel>
											<Select
												labelId="designation"
												label="Designation"
												value={this.state.designation}
												onChange={(e) => this.onChangeDesignation(e)}
											>
												<MenuItem value="0">
													<em>None</em>
												</MenuItem>
												{this.state.designations.map((option, index) => (
													<MenuItem key={index} value={option.id}>
														{option.name}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									)}
								</Grid>

								<Grid item xs={12} sm={6}>
									<TextField
										label="Code"
										value={this.state.code}
										onChange={(e) => this.onChangeCode(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={6}>
									<TextField
										required
										label="Name"
										value={this.state.name}
										onChange={(e) => this.onChangeName(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={3}>
									<TextField
										label="Phone"
										value={this.state.phone}
										onChange={(e) => this.onChangePhone(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>
								<Grid item xs={12} sm={3}>
									<TextField
										label="Mobile"
										value={this.state.mobile}
										onChange={(e) => this.onChangeMobile(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>
								<Grid item xs={12} sm={3}>
									<TextField
										label="Whatsapp"
										value={this.state.whatsapp}
										onChange={(e) => this.onChangeWhatsapp(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>
								<Grid item xs={12} sm={3}>
									<TextField
										label="Fax"
										value={this.state.fax}
										onChange={(e) => this.onChangeFax(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										label="Email"
										value={this.state.email}
										onChange={(e) => this.onChangeEmail(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={3}>
									{this.state.taxes_ready && (
										<FormControl
											fullWidth={true}
											variant="standard"
											sx={{ minWidth: 100 }}
										>
											<InputLabel id="id_tax">Tax</InputLabel>
											<Select
												labelId="id_tax"
												label="Tax"
												value={this.state.id_tax}
												onChange={(e) => this.onChangeIdTax(e)}
											>
												{this.state.taxes.map((option, index) => (
													<MenuItem key={index} value={option.id}>
														{option.name}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									)}
								</Grid>

								<Grid item xs={12} sm={3}>
									<TextField
										label="Tax Number"
										value={this.state.tax_number}
										onChange={(e) => this.onChangeTaxNumber(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={6}>
									<TextField
										label="Address"
										fullWidth
										multiline
										rows={2}
										value={this.state.address}
										onChange={(e) => this.onChangeAddress(e)}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										label="Note"
										fullWidth
										multiline
										rows={2}
										value={this.state.note}
										onChange={(e) => this.onChangeNote(e)}
									/>
								</Grid>

								<Grid item xs={12} sm={6}>
									<FormControl>
										<FormLabel>Active</FormLabel>
										<RadioGroup row>
											<FormControlLabel
												control={<Radio />}
												label="Active"
												value={true}
												checked={
													this.state.active === "true" ||
													this.state.active === true
												}
												onChange={(e) => this.onChangeActive(e)}
											/>

											<FormControlLabel
												control={<Radio />}
												label="Inactive"
												value={false}
												checked={
													this.state.active === "false" ||
													this.state.active === false
												}
												onChange={(e) => this.onChangeActive(e)}
											/>
										</RadioGroup>
									</FormControl>
								</Grid>

								<Grid item xs={12} sm={12}>
									<Button
										variant="contained"
										component={Link}
										to="/card/form"
										startIcon={<ArrowBackIcon />}
										sx={{ mr: 1 }}
									>
										Cancel
									</Button>
									<Button
										type="submit"
										variant="contained"
										disabled={this.props.loading.loading}
										startIcon={<SaveIcon />}
									>
										{this.props.loading.loading && (
											<span>
												<span className="spinner-border spinner-border-sm"></span>
												&nbsp;
											</span>
										)}
										<span>
											{" "}
											{this.props.loading.loading ? "Please Wait" : "Save"}
										</span>
									</Button>
								</Grid>
							</Grid>
						</Box>
					</Paper>
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
		loading: state.loading,
	};
};

export default connect(mapStateToProps, {
	createCard,
	showLoading,
	hideLoading,
	setModule,
})(AddCard);
