import {
	CREATE_SPEND_MONEY,
	RETRIEVE_SPEND_MONEY,
	RETRIEVE_BANK_REGISTER,
	RETRIEVE_SALES_REGISTER,
	RETRIEVE_PURCHASES_REGISTER,
	DELETE_SPEND_MONEY,
} from "../actions/types";

const initialState = {};

const spendMoneyReducer = function (spendMoney = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case CREATE_SPEND_MONEY:
			return payload;

		case RETRIEVE_SPEND_MONEY:
			return payload;

		case RETRIEVE_BANK_REGISTER:
			return payload;

		case RETRIEVE_SALES_REGISTER:
			return payload;

		case RETRIEVE_PURCHASES_REGISTER:
			return payload;

		case DELETE_SPEND_MONEY:
			return payload;

		default:
			return spendMoney;
	}
};

export default spendMoneyReducer;
