import React, { Component } from "react";
import { connect } from "react-redux";
import { setModule, setDateFrom, setDateTo } from "../../actions/common";
import { showLoading, hideLoading } from "../../actions/loading";

import ReportDataService from "../../services/reports.service";
import CardDataService from "../../services/card.service";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { format } from "date-fns";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";


import NumberFormat from "react-number-format";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
//import { parseDate } from "../../helpers/date";
//import { linkType } from "../../helpers/common";

class GLDetailConsolidateReport extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);
		let dateFrom, dateTo;

		if (this.props.common.dateFrom) {
			dateFrom = this.props.common.dateFrom;
		} else {
			dateFrom = format(new Date(), "yyyy-MM-01");
		}

		if (this.props.common.dateTo) {
			dateTo = this.props.common.dateTo;
		} else {
			dateTo = format(new Date(), "yyyy-MM-dd");
		}

		this.state = {
			searchFrom: dateFrom,
			searchTo: dateTo,
			searchCode: "",
			searchComp: "",
			searchSort: "code",
			searchIncludeNoActivity: "0",
			rows: [],
			error: false,
			message: false,

			companies_ready: false,
			accounts_ready: false,
			companies: [],
			accounts: [],
			xlsUrl: "https://fasreport.raywhite.co.id/gldetailconsolidate",
			grandTotalDebit: 0,
			grandTotalCredit: 0,
		};
	}

	orders = [
		{
			label: "Date",
			value: "date",
		},
		{
			label: "Code",
			value: "code",
		},
	];

	componentDidMount() {
		this._isMounted = true;
		if (this._isMounted) {
			this.props.setModule("GL Detail Consolidate");

		
			this.loadSelect();
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	getRows = () => {
		this.props.showLoading();
		this.props.setDateFrom(this.state.searchFrom);
		this.props.setDateTo(this.state.searchTo);
		var params = {
			code: this.state.searchCode,
			companies: this.state.searchComp,
			from: this.state.searchFrom,
			to: this.state.searchTo,
			sort: this.state.searchSort,
			includenoactivityaccount: this.state.searchIncludeNoActivity.toString(),
		};

		this.setState({ message: false }, function () {
			ReportDataService.getGLDetailConsolidate(params)
				.then((response) => {

					this.setState({
						rows: response.data.accounts,
					});
					this.props.hideLoading();
				})
				.catch((e) => {
					
					// this.setState({
					// 	error: true,
					// 	message: e.response.data.error,
					// });
					this.props.hideLoading();
				});
		});
	};

	loadSelect() {
		CardDataService.getCompanies().then((response) => {
			this.setState({
				companies_ready: true,
				companies: response.data.Row,
			});
		});
	}

	onChangeSearchFrom(e) {
		this.setState({ searchFrom: e.target.value });
	}
	onChangeSearchTo(e) {
		this.setState({ searchTo: e.target.value });
	}
	onChangeSearchSort(e) {
		this.setState({ searchSort: e.target.value });
	}
	onChangeSearchIncludeNoActivity(e) {
		let checked = "0";
		if (e.target.checked) {
			checked = "1";
		}
		this.setState({ searchIncludeNoActivity: checked });
	}

	onChangeSearchCode(e) {
		this.setState({ searchCode: e.target.value });
	}

	searchData = (e) => {
		e.preventDefault();
		this.getRows();
	};

	handleClose = (e) => {
		this.setState({
			error: false,
		});
	};

	tableChild(rows) {

		if (rows == null) {
			return;
		}

		let arr = [];

		for (let i = 0; i < rows.length; i++) {

			arr.push(
				<TableRow key={"a-" + i}>
					<TableCell sx={{ borderBottom: "none" }}></TableCell>
					<TableCell colSpan={9} sx={{ borderBottom: "none" }}>
						<Typography
							variant="subtitle2"
							gutterBottom
							component="div"
						>
							{rows[i].company}
							</Typography>
					</TableCell>
				</TableRow>
			);

			// if(this.state.searchCode !== ""){

			// 	for (let j = 0; j < rows[i].Detail.length; j++) {

			// 		arr.push(
			// 		<TableRow key={"b-" + i + "-" + j}>
			// 			<TableCell sx={{ borderBottom: "none" }}></TableCell>
			// 			<TableCell sx={{ borderBottom: "none" }}>{linkType(rows[i].Detail[j])}</TableCell>
			// 			<TableCell sx={{ borderBottom: "none" }}>{rows[i].Detail[j].code}</TableCell>
			// 			<TableCell sx={{ borderBottom: "none" }}>
			// 				{parseDate(rows[i].Detail[j].date)}
			// 			</TableCell>
			// 			<TableCell sx={{ borderBottom: "none" }}>{rows[i].Detail[j].note}</TableCell>
			// 			<TableCell align="right" sx={{ borderBottom: "none" }}>
			// 				{rows[i].Detail[j].debit !== 0 && (
			// 					<NumberFormat
			// 						value={rows[i].Detail[j].debit}
			// 						displayType={"text"}
			// 						thousandSeparator="."
			// 						decimalSeparator=","
			// 						decimalScale={2}
			// 						fixedDecimalScale={true}
			// 						prefix={""}
			// 					/>
			// 				)}
			// 			</TableCell>
			// 			<TableCell align="right" sx={{ borderBottom: "none" }}>
			// 				{rows[i].Detail[j].credit !== 0 && (
			// 					<NumberFormat
			// 						value={rows[i].Detail[j].credit}
			// 						displayType={"text"}
			// 						thousandSeparator="."
			// 						decimalSeparator=","
			// 						decimalScale={2}
			// 						fixedDecimalScale={true}
			// 						prefix={""}
			// 					/>
			// 				)}
			// 			</TableCell>
			// 			<TableCell sx={{ borderBottom: "none" }}>{rows[i].Detail[j].job}</TableCell>
			// 			<TableCell sx={{ borderBottom: "none" }}></TableCell>
			// 			<TableCell align="right" sx={{ borderBottom: "none" }}>
			// 				{rows[i].Detail[j].ending_balance !== 0 && (
			// 					<NumberFormat
			// 						value={rows[i].Detail[j].ending_balance}
			// 						displayType={"text"}
			// 						thousandSeparator="."
			// 						decimalSeparator=","
			// 						decimalScale={2}
			// 						fixedDecimalScale={true}
			// 						prefix={""}
			// 					/>
			// 				)}
			// 			</TableCell>
			// 		</TableRow>
			// 		)
			// 	}
			// }
		}

		return arr;
	}

	tableGrandTotal(rows) {


		let totalDebit = 0;
		let totalCredit = 0;

		

		if (rows != null) {
			let i = 0;

			for (i = 0; i < rows.length; i++) {

				totalDebit += rows[i].data.total_debit;
				totalCredit += rows[i].data.total_credit;	
			}
		}

		return (
			<TableRow>
				<TableCell
					sx={{
						borderBottom: "none",
						borderTop: 1,
						borderColor: "grey.300",
					}}
					align="right"
					colSpan={5}
				>
					<Typography variant="subtitle2" gutterBottom component="div">
						Grand Total
					</Typography>
				</TableCell>
				<TableCell
					align="right"
					sx={{
						borderBottom: "none",
						borderTop: 1,
						borderColor: "grey.300",
					}}
				>
					<Typography variant="subtitle2" gutterBottom component="div">
						<NumberFormat
							value={totalDebit}
							displayType={"text"}
							thousandSeparator="."
							decimalSeparator=","
							decimalScale={2}
							fixedDecimalScale={true}
							prefix={""}
						/>
					</Typography>
				</TableCell>
				<TableCell
					align="right"
					sx={{
						borderBottom: "none",
						borderTop: 1,
						borderColor: "grey.300",
					}}
				>
					<Typography variant="subtitle2" gutterBottom component="div">
						<NumberFormat
							value={totalCredit}
							displayType={"text"}
							thousandSeparator="."
							decimalSeparator=","
							decimalScale={2}
							fixedDecimalScale={true}
							prefix={""}
						/>
					</Typography>
				</TableCell>
				<TableCell
					colSpan={3}
					sx={{
						borderBottom: "none",
						borderTop: 1,
						borderColor: "grey.300",
					}}
				></TableCell>
			</TableRow>
		);
	}

	tableSubSum(totalDebit, totalCredit, endBalance) {

		let netActivity = 0;
		netActivity = totalDebit - totalCredit;

		return (
			<TableRow>
				<TableCell sx={{ borderBottom: "none" }} align="right" colSpan={5}>
					Total
				</TableCell>
				<TableCell
					align="right"
					sx={{
						borderBottom: "none",
						borderTop: 1,
						borderColor: "grey.300",
					}}
				>
					<NumberFormat
						value={totalDebit}
						displayType={"text"}
						thousandSeparator="."
						decimalSeparator=","
						decimalScale={2}
						fixedDecimalScale={true}
						prefix={""}
					/>
				</TableCell>
				<TableCell
					align="right"
					sx={{
						borderBottom: "none",
						borderTop: 1,
						borderColor: "grey.300",
					}}
				>
					<NumberFormat
						value={totalCredit}
						displayType={"text"}
						thousandSeparator="."
						decimalSeparator=","
						decimalScale={2}
						fixedDecimalScale={true}
						prefix={""}
					/>
				</TableCell>
				<TableCell sx={{ borderBottom: "none" }}></TableCell>
				<TableCell align="right" sx={{ borderBottom: "none" }}>
					<NumberFormat
						value={netActivity}
						displayType={"text"}
						thousandSeparator="."
						decimalSeparator=","
						decimalScale={2}
						fixedDecimalScale={true}
						prefix={""}
					/>
				</TableCell>
				<TableCell align="right" sx={{ borderBottom: "none" }}>
					<NumberFormat
						value={endBalance}
						displayType={"text"}
						thousandSeparator="."
						decimalSeparator=","
						decimalScale={2}
						fixedDecimalScale={true}
						prefix={""}
					/>
				</TableCell>
			</TableRow>
		);
	}

	tableBeginingBalance(beginingBalance) {
		return (
			<TableRow>
				<TableCell sx={{ borderBottom: "none" }}></TableCell>
				<TableCell sx={{ borderBottom: "none" }} colSpan={9}>
					<Typography variant="subtitle2" gutterBottom component="div">
						begining Balance :&nbsp;
						<NumberFormat
							value={beginingBalance}
							displayType={"text"}
							thousandSeparator="."
							decimalSeparator=","
							decimalScale={2}
							fixedDecimalScale={true}
							prefix={""}
						/>
					</Typography>
				</TableCell>
			</TableRow>
		);
	}

	CheckedCompanies(id) {
		const cs = this.state.searchComp.split(",");
		return cs.includes(id.toString());
	}

	onChangeCompanies(e) {
		let cs = this.state.searchComp.split(",");
		let val = e.target.value.toString();

		if (e.target.checked) {
			if (!cs.includes(val)) {
				cs.push(val);
			}
		} else {
			let i = cs.indexOf(val);
			if (i !== -1) {
				cs.splice(i, 1);
			}
		}

		this.setState({ searchComp: cs.join(",").replace(/^,/, "") });
	}

	render() {
		return (
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
						<Box component="form" onSubmit={(e) => this.searchData(e)}>
							<Typography variant="h5">Search Form</Typography>

							<Grid container spacing={3}>
								

								<Grid item xs={12} sm={2}>
									<TextField
										label="From"
										type="date"
										value={this.state.searchFrom}
										onChange={(e) => this.onChangeSearchFrom(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={2}>
									<TextField
										label="To"
										type="date"
										value={this.state.searchTo}
										onChange={(e) => this.onChangeSearchTo(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								{/* <Grid item xs={12} sm={3}>
									<FormControl
										fullWidth={true}
										variant="standard"
										sx={{ minWidth: 100 }}
									>
										<InputLabel id="orderlabel">Sort</InputLabel>
										<Select
											labelId="orderlabel"
											label="Sort"
											value={this.state.searchSort}
											onChange={(e) => this.onChangeSearchSort(e)}
										>
											{this.orders.map((option, index) => (
												<MenuItem key={index} value={option.value}>
													{option.label}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid> */}

								<Grid item xs={12} sm={5}>
                                    <TextField
										label="Search by code"
										value={this.state.searchCode}
										onChange={(e) => this.onChangeSearchCode(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={3}>
									<FormControlLabel
										control={
											<Checkbox
												checked={
													this.state.searchIncludeNoActivity === "1" ||
													this.state.searchIncludeNoActivity === 1
												}
												onChange={(e) =>
													this.onChangeSearchIncludeNoActivity(e)
												}
											/>
										}
										label="Include Accounts with No Activity"
									/>
								</Grid>
								{this.state.companies_ready &&
									this.state.companies.length > 1 && (
										<Grid item xs={12} sm={12}>
											<Grid item xs={12} sm={6}>
												<div className="MuiFormLabel-root MuiFormLabel-colorPrimary css-u4tvz2-MuiFormLabel-root">
													Consolidate :
												</div>
											</Grid>
											<Grid container spacing={0}>
												{this.state.companies.map((option, index) => (
													<Grid item xs={12} sm={6}>
														<FormControlLabel
															control={
																<Checkbox
																	checked={this.CheckedCompanies(option.id)}
																	onChange={(e) => this.onChangeCompanies(e)}
																	style={{ padding: 3 }}
																/>
															}
															value={option.id}
															label={
																<Box component="div" fontSize={14}>
																	{option.name}
																</Box>
															}
														/>
													</Grid>
												))}
											</Grid>
										</Grid>
									)}

								<Grid item xs={6} sm={6}>
									<Button
										type="submit"
										variant="contained"
										startIcon={<SearchIcon />}
									>
										Search
									</Button>
								</Grid>
								<Grid item xs={6} sm={6} container justifyContent="flex-end">
									<Link
										href={
											this.state.xlsUrl +
											"?code=" +
											this.state.searchCode +
											"&companies=" +
											this.state.searchComp +
											"&from=" +
											this.state.searchFrom +
											"&to=" +
											this.state.searchTo +
											"&sort=" +
											this.state.searchSort +
											"&includenoactivityaccount=" +
											this.state.searchIncludeNoActivity +
											"&token=" +
											this.props.auth.user.token
										}
										underline="always"
									>
										Export
									</Link>
								</Grid>
							</Grid>
						</Box>
					</Paper>
				</Grid>

				<Grid item xs={12}>
					<Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
						<Typography variant="h4" gutterBottom component="div">
							General Ledger Detail Consolidate
						</Typography>

						<TableContainer component={Paper}>
							<Table sx={{}} size="small">
								<TableHead>
									<TableRow>
										<TableCell width={5}>No.</TableCell>
										<TableCell align="center">Src</TableCell>
										<TableCell align="center">Code</TableCell>
										<TableCell align="center">Date</TableCell>
										<TableCell align="center">Note</TableCell>
										<TableCell align="center">Debit</TableCell>
										<TableCell align="center">Credit</TableCell>
										<TableCell align="center">Job</TableCell>
										<TableCell align="center">Net Activity</TableCell>
										<TableCell align="center">Ending Balance</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{this.state.rows &&
										this.state.rows.map((row, index) => (

                                           
											<>
												<TableRow key={index}>
													<TableCell
														sx={{
															borderBottom: "none",
															borderTop: 1,
															borderColor: "grey.300",
														}}
														align="right"
													>
														{1 + index}
													</TableCell>
													<TableCell
														colSpan={9}
														sx={{
															borderBottom: "none",
															borderTop: 1,
															borderColor: "grey.300",
														}}
													>
														<Typography
															variant="subtitle2"
															gutterBottom
															component="div"
														>
															{row.data.account_code} {row.data.account_name}
														</Typography>
													</TableCell>
												</TableRow>

											 	{this.tableBeginingBalance(row.data.opening_balance)}
												{this.tableChild(row.data.detail_consolidate)}
												{this.tableSubSum(
													row.data.total_debit,
													row.data.total_credit,
													row.data.ending_balance
												)}
											</>
										))}

									{this.state.rows && this.tableGrandTotal(this.state.rows)}
								</TableBody>
							</Table>
						</TableContainer>

						<Dialog
							open={this.state.error}
							onClose={this.handleClose}
							aria-labelledby="error-dialog-title"
							aria-describedby="error-dialog-description"
						>
							<DialogTitle id="error-dialog-title">Failed</DialogTitle>
							<DialogContent>
								<DialogContentText id="error-dialog-description">
									{this.state.message}
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={this.handleClose} autoFocus>
									Close
								</Button>
							</DialogActions>
						</Dialog>
					</Paper>
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
		common: state.common,
		loading: state.loading,
	};
};

export default connect(mapStateToProps, {
	setModule,
	setDateFrom,
	setDateTo,
	showLoading,
	hideLoading,
})(GLDetailConsolidateReport);
