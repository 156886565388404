import {
	CREATE,
	RETRIEVE,
	DELETE,
} from "../actions/types";

const initialState = [];

function postReducer(posts = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case CREATE:
			return payload;

		case RETRIEVE:
		 return payload;

		case DELETE:
			return payload;

		default:
			return posts;
	}
};

export default postReducer;