import {
	CREATE_TRANSFER_MONEY,
	RETRIEVE_TRANSFER_MONEY,
	DELETE_TRANSFER_MONEY,
} from "../actions/types";

const initialState = {};

const transferMoneyReducer = function (journal = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case CREATE_TRANSFER_MONEY:
			return payload;

		case RETRIEVE_TRANSFER_MONEY:
			return payload;

		case DELETE_TRANSFER_MONEY:
			return payload;

		default:
			return journal;
	}
};

export default transferMoneyReducer;
