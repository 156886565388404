import {
	CREATE_TRANSFER_MONEY,
	RETRIEVE_TRANSFER_MONEY,
	DELETE_TRANSFER_MONEY,
} from "./types";

import TransferMoneyDataService from "../services/transfer_money.service";
import { parseNumber } from "../helpers/common";

export const createTransferMoney =
	(
		id,
		id_company,
		code,
		date,
		note,
		from,
		to,
		amount,
		recurring,
		recurring_name
	) =>
	async (dispatch) => {
		try {
			amount = parseNumber(amount);

			let formData = {
				id: id,
				id_company: id_company,
				code: code,
				date: date,
				note: note,
				from: from,
				to: to,
				amount: amount,
				recurring: recurring,
				recurring_name: recurring_name,
			};

			const res = await TransferMoneyDataService.create(formData);

			dispatch({
				type: CREATE_TRANSFER_MONEY,
				payload: res.data,
			});

			return Promise.resolve(res.data);
		} catch (err) {
			return Promise.reject(err);
		}
	};

export const retrieveTransferMoney = (params) => async (dispatch) => {
	try {
		const res = await TransferMoneyDataService.getAll(params);

		dispatch({
			type: RETRIEVE_TRANSFER_MONEY,
			payload: res.data,
		});
	} catch (err) {
		return Promise.reject(err);
	}
};

export const deleteTransferMoney = (id) => async (dispatch) => {
	try {
		const res = await TransferMoneyDataService.delete(id);

		dispatch({
			type: DELETE_TRANSFER_MONEY,
			payload: res.data,
		});
	} catch (err) {
		return Promise.reject(err);
	}
};
