import http_fas from "../http-fas";

class ReportDataService {
	getTransactionJournal(params) {
		return http_fas.get("/transactionjournal", { params });
	}
	getFindTransactions(params) {
		return http_fas.get("/findtransaction", { params });
	}
	getGeneralLedgerDetail(params) {
		return http_fas.get("/generalledgerdetail", { params });
	}
	getGLDetailConsolidate(params) {
		return http_fas.get("/generalledgerdetailconsolidate", { params });
	}
	getStandardBalanceSheet(params) {
		return http_fas.get("/standardbalancesheet", { params });
	}
	getStandardBalanceSheetConsolidate(params) {
		return http_fas.get("/standardbalancesheetconsolidate", { params });
	}
	getProfitLossYTD(params) {
		return http_fas.get("/profitlossytd", { params });
	}
	getProfitLossYTDConsolidate(params) {
		return http_fas.get("/profitlossconsolidate", { params });
	}
	getJobProfitLoss(params) {
		return http_fas.get("/jobprofitloss", { params });
	}
	getTrialBalance(params) {
		return http_fas.get("/trialbalance", { params });
	}
	getStatementOfCashFlow(params) {
		return http_fas.get("/statementofcashflow", { params });
	}
	getCashFlowAnalysis(params) {
		return http_fas.get("/cashflowanalysis", { params });
	}

	getMonths(type) {
		if (type === 1) {
			return [
				{ id: "01", name: "January" },
				{ id: "02", name: "February" },
				{ id: "03", name: "March" },
				{ id: "04", name: "April" },
				{ id: "05", name: "May" },
				{ id: "06", name: "June" },
				{ id: "07", name: "July" },
				{ id: "08", name: "August" },
				{ id: "09", name: "September" },
				{ id: "10", name: "October" },
				{ id: "11", name: "November" },
				{ id: "12", name: "December" },
			];
		} else {
			return [
				{ id: 1, name: "January" },
				{ id: 2, name: "February" },
				{ id: 3, name: "March" },
				{ id: 4, name: "April" },
				{ id: 5, name: "May" },
				{ id: 6, name: "June" },
				{ id: 7, name: "July" },
				{ id: 8, name: "August" },
				{ id: 9, name: "September" },
				{ id: 10, name: "October" },
				{ id: 11, name: "November" },
				{ id: 12, name: "December" },
			];
		}
	}

	getYears() {
		return [
			{ id: "2021", name: "2021" },
			{ id: "2022", name: "2022" },
			{ id: "2023", name: "2023" },
			{ id: "2024", name: "2024" },
		];
	}
}

const dataService = new ReportDataService();
export default dataService;
