import { CREATE_JOURNAL, RETRIEVE_JOURNAL, DELETE_JOURNAL } from "./types";

import JournalDataService from "../services/journal.service";

export const createJournal =
	(
		id,
		id_company,
		code,
		date,
		note,
		tax_inclusive,
		detail,
		recurring,
		recurring_name
	) =>
	async (dispatch) => {
		try {
			if (tax_inclusive === "true") {
				tax_inclusive = true;
			}
			if (tax_inclusive === "false") {
				tax_inclusive = false;
			}

			let formData = {
				id: id,
				id_company: id_company,
				code: code,
				date: date,
				note: note,
				tax_inclusive: tax_inclusive,
				detail: detail,
				recurring: recurring,
				recurring_name: recurring_name,
			};

			const res = await JournalDataService.create(formData);

			dispatch({
				type: CREATE_JOURNAL,
				payload: res.data,
			});

			return Promise.resolve(res.data);
		} catch (err) {
			return Promise.reject(err);
		}
	};

export const retrieveJournal = (params) => async (dispatch) => {
	try {
		const res = await JournalDataService.getAll(params);

		dispatch({
			type: RETRIEVE_JOURNAL,
			payload: res.data,
		});
	} catch (err) {
		return Promise.reject(err);
	}
};

export const deleteJournal = (id) => async (dispatch) => {
	try {
		const res = await JournalDataService.delete(id);

		dispatch({
			type: DELETE_JOURNAL,
			payload: res.data,
		});
	} catch (err) {
		return Promise.reject(err);
	}
};
