import { parseISO, format, lastDayOfMonth, addMonths } from "date-fns";

export function parseDate(s) {
	if (s === "" || s === "0000-00-00" || s === "0000-00-00 00:00:00") {
		return "";
	} else {
		return format(parseISO(s), "dd MMM yyyy");
	}
}

export function getDueDate() {
	return format(lastDayOfMonth(addMonths(new Date(), 1)), "yyyy-MM-dd");
}
