import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ToDoListDataService from "../../services/todolist.service";
import { useState, useEffect } from "react";

import { showLoading, hideLoading } from "../../actions/loading";
import { setModule } from "../../actions/common";
import { useDispatch } from "react-redux";
import NumberFormat from "react-number-format";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { parseDate } from "../../helpers/date";

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

function buttonLink(row) {
	let title = "";
	let uri = "";

	switch (row.type) {
		case "journal":
			title = "Journal";
			uri = "/editjournal/0/" + row.id_source;
			break;
		case "transfer_money":
			title = "Transfer Money";
			uri = "/edittransfermoney/0/" + row.id_source;
			break;
		case "spend_money":
			title = "Spend Money";
			uri = "/editspendmoney/0/" + row.id_source;
			break;
		case "receive_money":
			title = "Receive Money";
			uri = "/editreceivemoney/0/" + row.id_source;
			break;
		case "bill":
			title = "Bill";
			uri = "/editbill/0/0/" + row.id_source;
			break;
		case "invoice":
			title = "Invoice";
			uri = "/editinvoice/0/0/" + row.id_source;
			break;
		case "order":
			if (row.source === "sales") {
				title = "Order";
				uri = "/editorder/0/" + row.id_source;
			} else {
				title = "Purchases Order";
				uri = "/editpurchaseorder/0/" + row.id_source;
			}
			break;
		default:
			title = "-";
	}

	return (
		<Button variant="outlined" component={Link} to={uri} size="small">
			Create {title}
		</Button>
	);
}

export default function ToDoList() {
	const dispatch = useDispatch();
	const [value, setValue] = React.useState(0);
	const [openinvoice, setOpeninvoice] = useState([]);
	const [openbill, setOpenbill] = useState([]);
	const [openorder, setOpenorder] = useState([]);
	const [recurringtransaction, setRecurringtransaction] = useState([]);
	const [recurringsales, setRecurringsales] = useState([]);
	const [recurringpurchases, setRecurringpurchases] = useState([]);

	const [alert, setAlert] = useState(false);
	const [currindex, setCurrindex] = useState(0);
	const [delid, setDelid] = useState(0);
	const [delsource, setDelsource] = useState("");
	const [deltitle, setDeltitle] = useState("");
	const [deltype, setDeltype] = useState("");

	useEffect(() => {
		ToDoListDataService.getAll()
			.then((res) => {
				setOpeninvoice(res.data.open_invoice.detail);
				setOpenbill(res.data.open_bill.detail);
				setOpenorder(res.data.open_order.detail);
				setRecurringtransaction(res.data.recurring_transaction.detail);
				setRecurringsales(res.data.recurring_sales.detail);
				setRecurringpurchases(res.data.recurring_purchases.detail);

				dispatch(hideLoading());
			})
			.catch((e) => {
				dispatch(hideLoading());
			});

		dispatch(setModule("To Do List"));
	}, [dispatch]);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const confirmDelete = (type, index, row) => {
		setCurrindex(index);
		setAlert(true);
		setDelid(row.id);
		setDelsource(row.source);
		setDeltitle(row.name);
		setDeltype(type);
	};

	const deleteRow = (type, i, id, delsource) => {
		setAlert(false);
		dispatch(showLoading());
		ToDoListDataService.delete(delsource, id)
			.then((res) => {
				if (type === "transaction") {
					recurringtransaction.splice(i, 1);
				} else if (type === "sales") {
					recurringsales.splice(i, 1);
				} else if (type === "purchases") {
					recurringpurchases.splice(i, 1);
				}
				dispatch(hideLoading());
			})
			.catch((e) => {
				dispatch(hideLoading());
			});
	};

	return (
		<Box sx={{ width: "100%" }}>
			<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
				<Tabs
					value={value}
					onChange={handleChange}
					aria-label="basic tabs example"
					variant="scrollable"
					scrollButtons="auto"
				>
					<Tab label="A/R" {...a11yProps(0)} wrapped />
					<Tab label="A/P" {...a11yProps(1)} wrapped />
					<Tab label="Recurring Transaction" {...a11yProps(2)} wrapped />
					<Tab label="Recurring Sales" {...a11yProps(3)} wrapped />
					<Tab label="Recurring Purchase" {...a11yProps(4)} wrapped />
					<Tab label="Orders" {...a11yProps(5)} wrapped />
				</Tabs>
			</Box>
			<TabPanel value={value} index={0}>
				<TableContainer component={Paper}>
					<Table sx={{}} size="small">
						<TableHead>
							<TableRow>
								<TableCell></TableCell>
								<TableCell>Name</TableCell>
								<TableCell>Overdue</TableCell>
								<TableCell>Inv. #</TableCell>
								<TableCell>Due Date</TableCell>
								<TableCell>Balance Due</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{openinvoice &&
								openinvoice.map((row, index) => (
									<TableRow
										key={index}
										sx={{
											"&:last-child td, &:last-child th": { border: 0 },
										}}
									>
										<TableCell align="center">
											<Button
												variant="outlined"
												component={Link}
												to={"/editreceivepayment/0/" + +row.id}
												size="small"
											>
												Receive Payment
											</Button>
										</TableCell>
										<TableCell>{row.card}</TableCell>
										<TableCell align="center">{row.overdue}</TableCell>
										<TableCell>{row.code}</TableCell>
										<TableCell>{parseDate(row.due_date)}</TableCell>
										<TableCell align="right">
											<NumberFormat
												value={row.outstanding}
												displayType={"text"}
												thousandSeparator="."
												decimalSeparator=","
												decimalScale={2}
												fixedDecimalScale={true}
												prefix={""}
											/>
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				</TableContainer>
			</TabPanel>
			<TabPanel value={value} index={1}>
				<TableContainer component={Paper}>
					<Table sx={{}} size="small">
						<TableHead>
							<TableRow>
								<TableCell></TableCell>
								<TableCell>Name</TableCell>
								<TableCell>Overdue</TableCell>
								<TableCell>P.O. #</TableCell>
								<TableCell>Due Date</TableCell>
								<TableCell>Balance Due</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{openbill &&
								openbill.map((row, index) => (
									<TableRow
										key={index}
										sx={{
											"&:last-child td, &:last-child th": { border: 0 },
										}}
									>
										<TableCell align="center">
											<Button
												variant="outlined"
												component={Link}
												to={"/editpaybill/0/" + +row.id}
												size="small"
											>
												Pay Bill
											</Button>
										</TableCell>
										<TableCell>{row.card}</TableCell>
										<TableCell align="center">{row.overdue}</TableCell>
										<TableCell>{row.code}</TableCell>
										<TableCell>{parseDate(row.due_date)}</TableCell>
										<TableCell align="right">
											<NumberFormat
												value={row.outstanding}
												displayType={"text"}
												thousandSeparator="."
												decimalSeparator=","
												decimalScale={2}
												fixedDecimalScale={true}
												prefix={""}
											/>
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				</TableContainer>
			</TabPanel>
			<TabPanel value={value} index={2}>
				<TableContainer component={Paper}>
					<Table sx={{}} size="small">
						<TableHead>
							<TableRow>
								<TableCell sx={{ width: 300 }}></TableCell>
								<TableCell>Name</TableCell>
								<TableCell></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{recurringtransaction &&
								recurringtransaction.map((row, index) => (
									<TableRow
										key={index}
										sx={{
											"&:last-child td, &:last-child th": { border: 0 },
										}}
									>
										<TableCell align="right">{buttonLink(row)}</TableCell>
										<TableCell>{row.name}</TableCell>
										<TableCell align="center">
											<DeleteIcon
												fontSize="small"
												onClick={() => confirmDelete("transaction", index, row)}
											/>
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				</TableContainer>
			</TabPanel>
			<TabPanel value={value} index={3}>
				<TableContainer component={Paper}>
					<Table sx={{}} size="small">
						<TableHead>
							<TableRow>
								<TableCell sx={{ width: 300 }}></TableCell>
								<TableCell>Name</TableCell>
								<TableCell></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{recurringsales &&
								recurringsales.map((row, index) => (
									<TableRow
										key={index}
										sx={{
											"&:last-child td, &:last-child th": { border: 0 },
										}}
									>
										<TableCell align="right">{buttonLink(row)}</TableCell>
										<TableCell>{row.name}</TableCell>
										<TableCell align="center">
											<DeleteIcon
												fontSize="small"
												onClick={() => confirmDelete("sales", index, row)}
											/>
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				</TableContainer>
			</TabPanel>
			<TabPanel value={value} index={4}>
				<TableContainer component={Paper}>
					<Table sx={{}} size="small">
						<TableHead>
							<TableRow>
								<TableCell sx={{ width: 300 }}></TableCell>
								<TableCell>Name</TableCell>
								<TableCell></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{recurringpurchases &&
								recurringpurchases.map((row, index) => (
									<TableRow
										key={index}
										sx={{
											"&:last-child td, &:last-child th": { border: 0 },
										}}
									>
										<TableCell align="right">{buttonLink(row)}</TableCell>
										<TableCell>{row.name}</TableCell>
										<TableCell align="center">
											<DeleteIcon
												fontSize="small"
												onClick={() => confirmDelete("purchases", index, row)}
											/>
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				</TableContainer>
			</TabPanel>
			<TabPanel value={value} index={5}>
				<TableContainer component={Paper}>
					<Table sx={{}} size="small">
						<TableHead>
							<TableRow>
								<TableCell></TableCell>
								<TableCell>Name</TableCell>
								<TableCell>Inv/P.O. #</TableCell>
								<TableCell>Balance Due</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{openorder &&
								openorder.map((row, index) => (
									<TableRow
										key={index}
										sx={{
											"&:last-child td, &:last-child th": { border: 0 },
										}}
									>
										<TableCell align="center">
											{row.source === "sales" ? (
												<Button
													variant="outlined"
													component={Link}
													to={"/editinvoice/0/" + +row.id}
													size="small"
												>
													Change to invoice
												</Button>
											) : (
												<Button
													variant="outlined"
													component={Link}
													to={"/editbill/0/" + +row.id}
													size="small"
												>
													Change to Bill
												</Button>
											)}
										</TableCell>
										<TableCell>{row.card}</TableCell>
										<TableCell>{row.code}</TableCell>
										<TableCell align="right">
											<NumberFormat
												value={row.total}
												displayType={"text"}
												thousandSeparator="."
												decimalSeparator=","
												decimalScale={2}
												fixedDecimalScale={true}
												prefix={""}
											/>
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				</TableContainer>
			</TabPanel>

			<Dialog
				open={alert}
				onClose={() => setAlert(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Delete "{deltitle}" ?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setAlert(false)} autoFocus>
						Cancel
					</Button>
					<Button
						onClick={() => deleteRow(deltype, currindex, delid, delsource)}
					>
						Yes
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
}
