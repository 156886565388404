import {
	CREATE_PAYMENT_METHOD,
	RETRIEVE_PAYMENT_METHOD,
	DELETE_PAYMENT_METHOD,
} from "./types";

import PaymentMethodDataService from "../services/paymentMethod.service";

export const createPaymentMethod =
	(id, id_company, name, active) => async (dispatch) => {
		try {
			if (active === "true") {
				active = true;
			}
			if (active === "false") {
				active = false;
			}

			let formData = {
				id: id,
				id_company: id_company,
				name: name,
				active: active,
			};

			const res = await PaymentMethodDataService.create(formData);

			dispatch({
				type: CREATE_PAYMENT_METHOD,
				payload: res.data,
			});

			return Promise.resolve(res.data);
		} catch (err) {
			return Promise.reject(err);
		}
	};

export const retrievePaymentMethod = (params) => async (dispatch) => {
	try {
		const res = await PaymentMethodDataService.getAll(params);

		dispatch({
			type: RETRIEVE_PAYMENT_METHOD,
			payload: res.data,
		});
	} catch (err) {
		return Promise.reject(err);
	}
};

export const deletePaymentMethod = (id) => async (dispatch) => {
	try {
		const res = await PaymentMethodDataService.delete(id);

		dispatch({
			type: DELETE_PAYMENT_METHOD,
			payload: res.data,
		});
	} catch (err) {
		return Promise.reject(err);
	}
};
