import { CREATE_INVOICE, RETRIEVE_INVOICE, DELETE_INVOICE } from "./types";

import InvoiceDataService from "../services/invoice.service";
import { parseNumber } from "../helpers/common";

export const createInvoice =
	(
		id,
		id_company,
		id_sales,
		id_card,
		code,
		code_po,
		date,
		due_date,
		note,
		ship_to,
		id_payment_method,
		tax_inclusive,
		detail,
		recurring,
		recurring_name
	) =>
	async (dispatch) => {
		try {
			if (tax_inclusive === "true") {
				tax_inclusive = true;
			}
			if (tax_inclusive === "false") {
				tax_inclusive = false;
			}

			code = code.toString();
			id_sales = parseNumber(id_sales);
			id_card = parseNumber(id_card);
			id_payment_method = parseNumber(id_payment_method);

			let formData = {
				id: id,
				id_company: id_company,
				id_sales: id_sales,
				id_card: id_card,
				code: code,
				code_po: code_po,
				date: date,
				due_date: due_date,
				note: note,
				ship_to: ship_to,
				id_payment_method: id_payment_method,
				tax_inclusive: tax_inclusive,
				detail: detail,
				recurring: recurring,
				recurring_name: recurring_name,
			};

			const res = await InvoiceDataService.create(formData);

			dispatch({
				type: CREATE_INVOICE,
				payload: res.data,
			});

			return Promise.resolve(res.data);
		} catch (err) {
			return Promise.reject(err);
		}
	};

export const retrieveInvoice = (params) => async (dispatch) => {
	try {
		const res = await InvoiceDataService.getAll(params);

		dispatch({
			type: RETRIEVE_INVOICE,
			payload: res.data,
		});
	} catch (err) {
		return Promise.reject(err);
	}
};

export const deleteInvoice = (id) => async (dispatch) => {
	try {
		const res = await InvoiceDataService.delete(id);

		dispatch({
			type: DELETE_INVOICE,
			payload: res.data,
		});
	} catch (err) {
		return Promise.reject(err);
	}
};
