import { CREATE_TAX, RETRIEVE_TAX, DELETE_TAX } from "./types";

import TaxDataService from "../services/tax.service";

export const createTax =
	(
		id,
		id_company,
		id_type,
		id_account_collected,
		id_account_paid,
		tax_code,
		name,
		rate,
		note
	) =>
	async (dispatch) => {
		try {
			let formData = {
				id: id,
				id_company: id_company,
				id_type: id_type,
				id_account_collected: id_account_collected,
				id_account_paid: id_account_paid,
				tax_code: tax_code,
				name: name,
				rate: rate,
				note: note,
			};

			const res = await TaxDataService.create(formData);

			dispatch({
				type: CREATE_TAX,
				payload: res.data,
			});

			return Promise.resolve(res.data);
		} catch (err) {
			return Promise.reject(err);
		}
	};

export const retrieveTax = (params) => async (dispatch) => {
	try {
		const res = await TaxDataService.getAll(params);

		dispatch({
			type: RETRIEVE_TAX,
			payload: res.data,
		});
	} catch (err) {
		return Promise.reject(err);
	}
};

export const deleteTax = (id) => async (dispatch) => {
	try {
		const res = await TaxDataService.delete(id);

		dispatch({
			type: DELETE_TAX,
			payload: res.data,
		});
	} catch (err) {
		return Promise.reject(err);
	}
};
